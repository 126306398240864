import React, { useEffect, useState } from "react";
import { Dropdown, NavLink } from "react-bootstrap";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import systemStore, {
  useActiveRole,
  useLastPageFilters,
  useLoginStatus,
  useSignalRMessage,
} from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";
import packageInfo from "../../package.json";
import menuActions from "./MenuActions";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import SwitchButton from "../components/SwitchButton";

function NavBar(props: { toggle: () => void }) {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const loc = useLocation();
  const ma = menuActions;
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();

  const notifs = useFetchHelper(() => {
    return SysServices.http.signalRPersistence.list(1, 10);
  }, "Notifications");

  useEffect(() => {
    if (loginStatus.loggedIn) {
      const ax = ma.actions.filter(
        (a) =>
          a.roles.find((r) => loginStatus.hasRole(r)) &&
          !!matchPath(a.url, loc.pathname)
      );

      if (ax && ax.length > 0) {
        const myRoles = ax[0].roles.filter((r) => loginStatus.hasRole(r));
        if (!myRoles.includes(activeRole as any)) {
          const role = myRoles[0];
          if (!activeRole) {
            setTimeout(() => {
              systemStore.setActiveRole(role);
            }, 100);
          } else {
            systemStore.setActiveRole(role);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.pathname, loginStatus]);

  useEffect(() => {
    if (
      loginStatus.loggedIn &&
      activeRole &&
      !commonService.isNullOrWhitespace(activeRole)
    ) {
      SysServices.http.userSetting.saveLastDashboard(activeRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole]);

  const signaRMessage = useSignalRMessage();
  const [hasNotif, setHasNotif] = useState(false);

  useEffect(() => {
    let tmo: any;
    if (loginStatus.isParentCompany) {
      setHasNotif(false);
      return;
    }
    if (signaRMessage) {
      // console.log(
      //   `${signaRMessage.signalRMessageTypeEnum}: ${signaRMessage.message}`
      // );q
      if (
        signaRMessage.signalRMessageTypeEnum ===
        SysModels.SignalRMessageTypeEnum.SignalRNotificationsUpdated
      ) {
        setHasNotif(true);
        notifs.getData();
        tmo = setTimeout(() => {
          setHasNotif(false);
        }, 5000);
      }
    }

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signaRMessage, loginStatus.isParentCompany]);

  useEffect(() => {
    if (notifs.status === FetchStatus.Complete) {
      setHasNotif(!!notifs.data?.totalRecords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifs.status]);

  const openNotif = (item: SysModels.SignalRMessageDto) => {
    if (
      item.signalRMessageTypeEnum ===
      SysModels.SignalRMessageTypeEnum.FullTemplateCardUpdated
    ) {
      navigate(`cards/${item.recordId}`);
    }
  };

  const getNotifIcon = (item: SysModels.SignalRMessageDto) => {
    if (
      item.signalRMessageTypeEnum ===
      SysModels.SignalRMessageTypeEnum.FullTemplateCardUpdated
    ) {
      return "fa-file-text";
    }
    if (
      item.signalRMessageTypeEnum ===
      SysModels.SignalRMessageTypeEnum.UserRolesUpdated
    ) {
      return "fa-user";
    }
    if (
      item.signalRMessageTypeEnum ===
      SysModels.SignalRMessageTypeEnum.FullTemplateCardDeleted
    ) {
      return "fa-trash";
    }
    return "fa-info";
  };

  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = (turnOn: boolean) => {
    setDarkMode(turnOn);
    localStorage.setItem("darkmode", turnOn ? "1" : "");
    pageFilters.save({
      pageSize: 0,
      search: turnOn ? "1" : "",
    });
  };

  useEffect(() => {
    const val = localStorage.getItem("darkmode");
    if (val === "1") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
    const onKeydown = (e: KeyboardEvent) => {
      if (e.altKey && e.key.toLowerCase() === "t") {
        var val = localStorage.getItem("darkmode");
        toggleDarkMode(val !== "1");
      }
    };
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute(
      "data-bs-theme",
      darkMode ? "dark" : "light"
    );
    systemStore.setTheme(darkMode ? "dark" : "light");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 0,
      search: "",
    },
    (filters) => {
      setDarkMode(filters?.search === "1");
      localStorage.setItem("darkmode", filters?.search);
    },
    "darkmode"
  );

  return (
    <>
      {inProgress && <CommonSpinner overlay={true}></CommonSpinner>}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="sideNavToggle btn btn-sm me-1"
            onClick={props.toggle}
          >
            <i className="fa fa-bars"></i>
          </button>
          <span className="navbar-brand">
            <NavLink className="navbar-brand" style={{ padding: 0 }}>
              <img
                src="/globus.png"
                alt="WebF1"
                style={{ width: "214px" }}
              ></img>
            </NavLink>
          </span>
          <div className="collapse navbar-collapse" id="navbarNav">
            <button
              className="sideNavToggle btn btn-sm me-1"
              onClick={props.toggle}
            >
              <i className="fa fa-bars"></i>
            </button>
            <div className="navbar-center flex flex-center py-2"></div>
            <div className="navbar-end flex gap-10">
              {!loginStatus.isParentCompany && (
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-notification"
                    className={notifs.data?.totalRecords ? "hasnotif" : ""}
                  >
                    <i className={`fa fa-bell ${hasNotif ? "shake" : ""}`}></i>
                    {!!notifs.data?.totalRecords && (
                      <span className="notif-count">
                        {notifs.data?.totalRecords}
                      </span>
                    )}
                    {hasNotif && (
                      <div className="pulse-container">
                        <div className="pulse-circle pulse pulse-rose"></div>
                      </div>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {notifs.status === FetchStatus.Complete && (
                      <>
                        {notifs.data?.totalRecords ? (
                          notifs.data?.signalRNotificationDetailDtos?.map(
                            (item) => (
                              <Dropdown.Item
                                key={item.id}
                                onClick={(e) => {
                                  item.signalRMessage &&
                                    openNotif(item.signalRMessage);
                                }}
                              >
                                {item.signalRMessage && (
                                  <div className="flex flex-row gap-10 flex-center">
                                    <small>
                                      <i
                                        style={{ color: "#999" }}
                                        className={`fa ${getNotifIcon(
                                          item.signalRMessage
                                        )}`}
                                      ></i>
                                    </small>
                                    <small
                                      className="flex-1"
                                      style={{
                                        maxWidth: "300px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {item.signalRMessage.message}
                                    </small>
                                  </div>
                                )}
                              </Dropdown.Item>
                            )
                          )
                        ) : (
                          <Dropdown.Item disabled={true}>
                            <small>No New Notification(s)</small>
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                    {notifs.status === FetchStatus.InProgress && (
                      <div className="px-2">
                        <small>Loading...</small>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Dropdown>
                <Dropdown.Toggle id="dropdown-profile">
                  <i className="fa fa-user-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item disabled={true}>My Account</Dropdown.Item>
                  <Dropdown.Divider />
                  {!loginStatus.companySwitched && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/change-password", { replace: true });
                        }}
                      >
                        <i className="fa fa-lock me-3"></i>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/2fa", { replace: true });
                        }}
                      >
                        <i className="fa fa-shield me-3"></i>
                        Two-Factor Authentication
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setInProgress(true);
                      SysServices.http.myAccount
                        .logout(SysServices.http.fetcher.getToken().sessionId)
                        .then((data) => {
                          //do nothing...
                        })
                        .catch((err) => {
                          toastStore.showError("Logout Error", err);
                        })
                        .finally(() => {
                          SysServices.http.fetcher.clearToken();
                          systemStore.clearAuthData();
                          setInProgress(false);
                          navigate("/", { replace: true });
                        });
                    }}
                  >
                    <i className="fa fa-sign-out me-3"></i>
                    <span>Log Out</span>
                  </Dropdown.Item>
                  <Dropdown.Divider></Dropdown.Divider>
                  <Dropdown.Item
                    className="py-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div className="flex flex-row flex-center gap-10">
                      <div className="flex-1">
                        <SwitchButton
                          uncheckedLabel="Dark Mode"
                          checked={darkMode}
                          onChange={(val) => {
                            toggleDarkMode(val);
                          }}
                        ></SwitchButton>
                      </div>
                      <div className="alert alert-light p-0 px-1 m-0">
                        <small className="text-secondary">Alt+T</small>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Header className="text-center">
                    <small>
                      v<span>{packageInfo.version}</span>
                    </small>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
