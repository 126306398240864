import React, { useEffect } from "react";
import { useCompanySettings } from "../stores/SystemStore";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import CommonSpinner from "../components/CommonSpinner";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";

function RecentlyUpdatedCardsWidget(props: any) {
  const nav = useNavigate();
  const companySettings = useCompanySettings();
  const list = useFetchHelper(
    async () =>
      SysServices.http.fullTemplateCard.list(1, 5, {
        search: "",
        archiveStatus: SysModels.ArchiveStatusEnum.Open,
      }),
    companySettings?.entityLabelPlural || "Cards"
  );

  useEffect(() => {
    list.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="widget-container">
      <div className="flex flex-row mb-2">
        <h5 className="flex-1">
          Recently Updated {companySettings?.entityLabelPlural}
        </h5>
        <div>
          <i
            className="fa fa-refresh pointer text-primary"
            onClick={(e) => list.getData()}
          ></i>
        </div>
      </div>
      <div>
        {list.status === FetchStatus.Complete ? (
          <>
            <table className="table table-sm table-bordered table-hover mb-2">
              <tbody>
                {list.data?.fullTemplateCardGridItemOutputDtos?.map((item) => (
                  <tr
                    key={item.id}
                    className="pointer"
                    onClick={(e) => {
                      nav(`/cards/${item.id}`);
                    }}
                  >
                    <td>
                      <strong>{item.cardTemplateName}</strong>
                    </td>
                    <td>
                      <div></div>
                      {item.fields
                        ?.sort(commonService.sortByNumericProperty("sequence"))
                        ?.map((f) => {
                          if (f.fieldType === SysModels.WF1FieldType.Date) {
                            return `${f.name}: ${
                              f.value ? commonService.toLocalDate(f.value) : "-"
                            }`;
                          }
                          return `${f.name}: ${f.value}`;
                        })
                        ?.join(", ")}
                    </td>
                    <td>
                      <i className="fa fa-eye pointer"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <CommonSpinner></CommonSpinner>
          </>
        )}
        <div className="text-right">
          <strong
            className="text-primary pointer"
            onClick={(e) => {
              nav(`/cards`);
            }}
          >
            View All
          </strong>
        </div>
      </div>
    </div>
  );
}

export default RecentlyUpdatedCardsWidget;
