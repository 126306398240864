import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../components/FormModal";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import SysServices from "../services";
import commonService from "../services/CommonService";
import SysModels from "../models";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings } from "../stores/SystemStore";

export interface IReminderSetting
  extends SysModels.ReminderSettingDetailIOutputDto {
  _userTypeId?: boolean;
  _otherUser?: boolean;
  _field?: boolean;
  _daysBefore?: boolean;
  _daysAfter?: boolean;
}

function SetupReminderDialog(props: {
  onClose: () => void;
  done: (data: IReminderSetting) => void;
  data?: IReminderSetting;
  template?: {
    isUserTemplate?: boolean;
    templateId?: string;
  };
}) {
  const [userType, setUserType] = useState<{ id: number; name: string }>();
  const [userTypes, setUserTypes] = useState([] as any[]);
  const [isLoadingUserTypes, setIsLoadingUserTypes] = useState(false);
  const refUserType = React.createRef<any>();
  const handleUserTypes = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refUserType.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setUserTypes([]);
      setIsLoadingUserTypes(false);
      return;
    }
    setIsLoadingUserTypes(true);
    await SysServices.http.userType
      .typeAhead({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setUserTypes(options);
        setIsLoadingUserTypes(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchGroupInput");
        }
      })
      .catch((err) => {
        setUserTypes([]);
        setIsLoadingUserTypes(false);
      });
  };

  const [field, setField] = useState<{ id: number; name: string }>();
  const [fields, setFields] = useState([] as any[]);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const refField = React.createRef<any>();
  const handleFieldSearch = async (query: string) => {
    let focus = false;
    if ((query || "").trim() === "*") {
      focus = true;
      (refField.current as any)?.clear();
    } else if ((query || "").trim().length < 3) {
      setFields([]);
      setIsLoadingFields(false);
      return;
    }
    setIsLoadingFields(true);
    await SysServices.http.cardField
      .typeAheadDateOnly({
        search: query,
        templateId: props.template?.templateId,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.label}`,
          description: i.description,
          model: { ...i },
        }));
        setFields(options);
        setIsLoadingFields(false);
        if (focus) {
          commonService.focusInput("typeaheadSearchFieldInput");
        }
      })
      .catch((err) => {
        setFields([]);
        setIsLoadingFields(false);
      });
  };

  const [ready, setReady] = useState(false);
  const [model, setModel] = useState<IReminderSetting>({});

  useEffect(() => {
    if (props.data?.reminderSettingDetailId) {
      const data = { ...props.data };
      if (data.userTypeId) {
        data._userTypeId = true;
        setUserType({
          id: data.userTypeId || 0,
          name: data.userTypeName || "",
        });
      }
      if (data.otherUserEmailAddresses) data._otherUser = true;
      if (data.fieldId) {
        data._field = true;
        setField({
          id: data.fieldId || 0,
          name: data.fieldName || "",
        });
      }
      if (data.daysBefore) data._daysBefore = true;
      if (data.daysAfter) data._daysAfter = true;
      setModel(data);
      setTimeout(() => setReady(true), 200);
    } else {
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSave = useMemo(() => {
    const hasUserType = model._userTypeId && !!model.userTypeId;
    const hasOtherEmail =
      model._otherUser &&
      !commonService.isNullOrWhitespace(model.otherUserEmailAddresses || "") &&
      commonService.validateEmailCSV(model.otherUserEmailAddresses || "") ===
        true;
    const dayBefore =
      model._daysBefore &&
      !commonService.isNullOrWhitespace(model.daysBefore || "") &&
      commonService.validateNumbersOnlyCSV(
        model.daysBefore || "",
        false,
        true,
        false,
        true
      ) === true;
    const dayAfter =
      model._daysAfter &&
      !commonService.isNullOrWhitespace(model.daysAfter || "") &&
      commonService.validateNumbersOnlyCSV(
        model.daysAfter || "",
        false,
        true,
        true,
        true
      ) === true;

    if (model.notifyEntityUser || hasUserType || hasOtherEmail) {
      if (
        model.atTimeOfAssignment ||
        (model._field && !!model.fieldId && (dayBefore || dayAfter))
      ) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const companySettings = useCompanySettings();

  return (
    <FormModal
      isOpen={true}
      title={`${props.template ? "Template" : "Category"} Reminder`}
      size="xl"
      close={() => {
        setModel({});
        props.onClose();
      }}
      submit={() => {
        if (canSave) {
          const data = { ...model };
          if (model.notifyEntityUser) {
            data.userTypeId = undefined;
            data.otherUserEmailAddresses = undefined;
          }
          if (model._userTypeId) {
            data.otherUserEmailAddresses = undefined;
          }
          if (model._otherUser) {
            data.userTypeId = undefined;
          }
          if (model.atTimeOfAssignment) {
            data.fieldId = undefined;
            data.daysAfter = undefined;
            data.daysBefore = undefined;
          }
          if (!model.reminderSettingDetailId) {
            data.reminderSettingDetailId = "new" + commonService.getUniqueId();
          }

          if (!model._daysBefore) {
            data.daysBefore = undefined;
          }
          if (!model._daysAfter) {
            data.daysAfter = undefined;
          }

          //console.log(data);
          props.done(data);
          setModel({});
          props.onClose();
        }
      }}
      disableSubmit={!canSave}
      submitButtonLabel="Continue"
    >
      <div className="pb-2">
        {!ready && <CommonSpinner></CommonSpinner>}
        {ready && (
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="mb-2 text-primary">
                <strong>Who will receive the reminder?</strong>
              </div>
              <div className="mt-2">
                {!props?.template?.isUserTemplate && (
                  <div className="flex flex-row flex-center mb-1">
                    <input
                      type="radio"
                      className="me-2"
                      checked={model.notifyEntityUser || false}
                      id="rdnotifyEntityUser"
                      onChange={(e) => {
                        const val = e.target.checked || false;
                        setModel((prev) => {
                          return {
                            ...prev,
                            notifyEntityUser: val,
                            _userTypeId: false,
                            _otherUser: false,
                          };
                        });
                      }}
                    />
                    <div>
                      <label
                        htmlFor="rdnotifyEntityUser"
                        className="pointer mb-0"
                      >
                        Notify {companySettings?.entityLabelSingular} User
                      </label>
                    </div>
                  </div>
                )}
                <div className="flex flex-row flex-center mb-1">
                  <input
                    type="radio"
                    className="me-2"
                    checked={model._userTypeId || false}
                    id="rd_userTypeId"
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          notifyEntityUser: false,
                          _userTypeId: val,
                          _otherUser: false,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rd_userTypeId" className="pointer mb-0">
                      Group{" "}
                      {model._userTypeId && !model.userTypeId && (
                        <small className="text-danger">* Required</small>
                      )}
                    </label>
                  </div>
                </div>
                <div className="mb-2 mx-3 me-0">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <AsyncTypeahead
                        id="typeahead-search-user-type"
                        labelKey="name"
                        renderMenuItemChildren={(option, props, index) => (
                          <TyeaheadItem {...userTypes[index]}></TyeaheadItem>
                        )}
                        onSearch={handleUserTypes}
                        onChange={(data) => {
                          if (data.length > 0) {
                            setUserType({
                              id: (data[0] as any).id,
                              name: (data[0] as any).name,
                            });
                            setModel((prev) => {
                              return {
                                ...prev,
                                userTypeId: (data[0] as any).id,
                                userTypeName: (data[0] as any).name,
                              };
                            });
                            (refUserType.current as any)?.clear();
                          }
                        }}
                        searchText={"Searching..."}
                        isLoading={isLoadingUserTypes}
                        options={userTypes}
                        placeholder="Search and Pick Group"
                        minLength={0}
                        delay={500}
                        useCache={false}
                        filterBy={() => true}
                        ref={refUserType}
                        disabled={!model._userTypeId}
                      />
                      <HiddenInputRequiredRef
                        id="typeaheadSearchGroupInput"
                        value={""}
                        placeholder="Search"
                        onFocus={() => {
                          refUserType.current?.focus();
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={(e) => {
                          handleUserTypes("*");
                        }}
                        title="Show All"
                        disabled={!model._userTypeId}
                      >
                        <i className="fa fa-list-ul"></i>
                      </button>
                    </div>
                  </div>
                  {userType && (
                    <div
                      className={`alert alert-sm alert-${
                        model._userTypeId ? "primary" : "secondary"
                      } mt-2 p-2 px-3 mb-0 flex flex-row flex-center`}
                    >
                      <strong
                        className="flex-1"
                        style={model._userTypeId ? {} : { opacity: 0.3 }}
                      >
                        {userType.name}
                      </strong>
                      <i
                        className="fa fa-times text-danger pointer"
                        onClick={(e) => {
                          setUserType(undefined);
                          setModel((prev) => {
                            return {
                              ...prev,
                              userTypeId: undefined,
                            };
                          });
                        }}
                      ></i>
                    </div>
                  )}
                </div>
                <div className="flex flex-row flex-center mb-1">
                  <input
                    type="radio"
                    className="me-2"
                    checked={model._otherUser || false}
                    id="rd_otherUser"
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          notifyEntityUser: false,
                          _userTypeId: false,
                          _otherUser: val,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rd_otherUser" className="pointer mb-0">
                      Other{" "}
                      {model._otherUser && (
                        <span className="text-danger">
                          {commonService.isNullOrWhitespace(
                            model.otherUserEmailAddresses || ""
                          ) ? (
                            <small>* Required</small>
                          ) : (
                            <small>
                              {commonService.validateEmailCSV(
                                model.otherUserEmailAddresses,
                                true,
                                true
                              )}
                            </small>
                          )}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
                <div className="mx-3 me-0">
                  <textarea
                    className="form-control show-placeholder"
                    value={model.otherUserEmailAddresses || ""}
                    onChange={(e) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          otherUserEmailAddresses: e.target.value,
                        };
                      });
                    }}
                    disabled={!model._otherUser}
                    placeholder="Email Addresses Separated by Comma"
                    rows={3}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="mb-2 text-primary">
                <strong>When will the reminder be sent?</strong>
              </div>
              <div className="mt-2">
                <div className="flex flex-row flex-center mb-1">
                  <input
                    type="radio"
                    className="me-2"
                    name="whenToNotify_main"
                    checked={model.atTimeOfAssignment || false}
                    id="rdatTimeOfAssignment"
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          atTimeOfAssignment: val,
                          _field: false,
                          _daysAfter: false,
                          _daysBefore: false,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rdatTimeOfAssignment" className="mb-0">
                      At time of assignment
                    </label>
                  </div>
                </div>
                <div className="flex flex-row flex-center mb-1">
                  <input
                    type="radio"
                    className="me-2"
                    name="whenToNotify_main"
                    checked={model._field || false}
                    id="rd_field"
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          atTimeOfAssignment: false,
                          _field: val,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rd_field" className="mb-0">
                      Specified Date{" "}
                      {model._field && !model.fieldId && (
                        <small className="text-danger">* Required</small>
                      )}
                    </label>
                  </div>
                </div>
                <div className="mx-3 me-0">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <AsyncTypeahead
                        id="typeahead-search-field"
                        labelKey="name"
                        renderMenuItemChildren={(option, props, index) => (
                          <TyeaheadItem {...fields[index]}></TyeaheadItem>
                        )}
                        onSearch={handleFieldSearch}
                        onChange={(data) => {
                          if (data.length > 0) {
                            setField({
                              id: (data[0] as any).id,
                              name: (data[0] as any).name,
                            });
                            setModel((prev) => {
                              return {
                                ...prev,
                                fieldId: (data[0] as any).id,
                                fieldName: (data[0] as any).name,
                              };
                            });
                            (refField.current as any)?.clear();
                          }
                        }}
                        searchText={"Searching..."}
                        isLoading={isLoadingFields}
                        options={fields}
                        placeholder="Search and Pick Field"
                        minLength={0}
                        delay={500}
                        useCache={false}
                        filterBy={() => true}
                        ref={refField}
                        disabled={!model._field}
                      />
                      <HiddenInputRequiredRef
                        id="typeaheadSearchFieldInput"
                        value={""}
                        placeholder="Search"
                        onFocus={() => {
                          refField.current?.focus();
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={(e) => {
                          handleFieldSearch("*");
                        }}
                        title="Show All"
                        disabled={!model._field}
                      >
                        <i className="fa fa-list-ul"></i>
                      </button>
                    </div>
                  </div>
                  {field && (
                    <div
                      className={`alert alert-sm alert-${
                        model._field ? "primary" : "secondary"
                      } mt-2 p-2 px-3 mb-0 flex flex-row flex-center`}
                    >
                      <strong
                        className="flex-1"
                        style={model._field ? {} : { opacity: 0.3 }}
                      >
                        {field.name}
                      </strong>
                      <i
                        className="fa fa-times text-danger pointer"
                        onClick={(e) => {
                          setField(undefined);
                          setModel((prev) => {
                            return {
                              ...prev,
                              fieldId: undefined,
                            };
                          });
                        }}
                      ></i>
                    </div>
                  )}
                </div>
                {model._field &&
                  !!model.fieldId &&
                  !model._daysBefore &&
                  !model._daysAfter && (
                    <div className="px-3 pt-1 text-danger">
                      <small>* Choose Days Before and/or Days After</small>
                    </div>
                  )}
                <div className="mt-2 flex flex-row flex-center mb-1">
                  <input
                    type="checkbox"
                    className="mx-3 me-2"
                    name="whenToNotify_Days"
                    checked={model._daysBefore || false}
                    id="rd_daysBefore"
                    disabled={!model._field}
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          _daysBefore: val,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rd_daysBefore" className="mb-0">
                      Days Before{" "}
                      {model._daysBefore && (
                        <span className="text-danger">
                          {commonService.isNullOrWhitespace(
                            model.daysBefore || ""
                          ) ? (
                            <small>* Required</small>
                          ) : (
                            <small>
                              {commonService.validateNumbersOnlyCSV(
                                model.daysBefore || "",
                                true,
                                true,
                                false,
                                true
                              )}
                            </small>
                          )}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
                <div className="px-2 pe-0 mx-4 me-0 mb-2">
                  <input
                    type="text"
                    className="form-control show-placeholder"
                    placeholder="Number of Days (or Zero) Separated by Commas (#,#,#)"
                    disabled={!model._field || !model._daysBefore}
                    value={model.daysBefore || ""}
                    onChange={(e) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          daysBefore: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="flex flex-row flex-center mb-1">
                  <input
                    type="checkbox"
                    className="mx-3 me-2"
                    name="whenToNotify_Days"
                    checked={model._daysAfter || false}
                    id="rd_daysAfter"
                    disabled={!model._field}
                    onChange={(e) => {
                      const val = e.target.checked || false;
                      setModel((prev) => {
                        return {
                          ...prev,
                          _daysAfter: val,
                        };
                      });
                    }}
                  />
                  <div>
                    <label htmlFor="rd_daysAfter" className="mb-0">
                      Days After{" "}
                      {model._daysAfter && (
                        <span className="text-danger">
                          {commonService.isNullOrWhitespace(
                            model.daysAfter || ""
                          ) ? (
                            <small>* Required</small>
                          ) : (
                            <small>
                              {commonService.validateNumbersOnlyCSV(
                                model.daysAfter || "",
                                true,
                                true,
                                true,
                                true
                              )}
                            </small>
                          )}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
                <div className="px-2 pe-0 mx-4 me-0 mb-2">
                  <input
                    type="text"
                    className="form-control show-placeholder"
                    placeholder="Number of Days Separated by Commas (#,#,#)"
                    disabled={!model._field || !model._daysAfter}
                    value={model.daysAfter || ""}
                    onChange={(e) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          daysAfter: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </FormModal>
  );
}

export default SetupReminderDialog;
