import SysModels from "../../models";
import fetcher from "../Fetcher";

class AssociatedCardService {
  create = async (model: SysModels.AssociatedCardInputDto) => {
    const url = `/AssociatedCard`;
    return fetcher.post<any>(url, model);
  };

  delete = async (cardId: string, associatedCardId: string) => {
    const url = `/AssociatedCard/${cardId}/${associatedCardId}`;
    return fetcher.delete<any>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.AssociatedCardSearchInputDto
  ) => {
    const url = `/AssociatedCard/ListAssociatedCards/${page}/${pageSize}`;
    return fetcher.post<SysModels.AssociatedCardGridOutputDto>(url, data);
  };
}

const associatedCard = new AssociatedCardService();
export default associatedCard;
