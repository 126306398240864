import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../components/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useErrorDialog } from "../stores/SystemStore";

function FolderDialog(props: {
  close: (data?: any) => void;
  id?: string;
  name?: string;
  parentFolder?: string;
}) {
  const [model, setModel] = useState<SysModels.FolderInputDto>({
    name: props.name || "",
    parentFolderId: props.parentFolder,
  });
  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    await (props.id
      ? SysServices.http.fileSystem.updateFolder(props.id, {
          ...model,
        })
      : SysServices.http.fileSystem.createFolder({
          ...model,
        })
    )
      .then((data) => {
        toastStore.showToast("Folder Saved", "success");
        props.close({
          ...model,
          id: data.id,
        });
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Folder", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    commonService.focusInput("folderName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errDialog = useErrorDialog();
  const allowSave = useMemo(() => {
    return commonService.validations.folderName(model.name || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.name]);

  return (
    <FormModal
      title={`${props.id ? "Edit" : "Add"} Folder`}
      size="md"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        save();
      }}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      disableSubmit={
        commonService.isNullOrWhitespace(model.name || "") || !allowSave
      }
      moveBehind={errDialog.show}
    >
      <div>
        <div style={{ width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label>Name</label>
              </div>
              <input
                autoFocus={true}
                id="folderName"
                autoComplete="new-password"
                className="form-control"
                placeholder="Name"
                value={model.name}
                maxLength={64}
                onKeyDown={(e) => {
                  if (
                    commonService.isNullOrWhitespace(model.name || "") ||
                    !allowSave
                  ) {
                    return;
                  }
                  if (e.key === "Enter") {
                    save();
                  }
                }}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      name: e.target.value,
                    };
                  });
                }}
              />
            </div>
            {!allowSave && (
              <small className="text-danger">
                Please only use letters, numbers and underscores
              </small>
            )}
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default FolderDialog;
