import React, { useState } from "react";
import DashboardIcons from "../components/DashboardIcons";
import DashboardHowTo from "../components/DashboardHowTo";
import { useLastPageFilters } from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";

function AppAdminDashboard(props: any) {
  const [noHowTo, setNoHowTo] = useState(false);
  const pageFilters = useLastPageFilters({
    pageSize: 0,
    search: "",
    others: {
      hideHowTo: false,
    },
  });

  return (
    <div>
      <h4>App Admin Dashboard</h4>
      <div className="row pt-4">
        {pageFilters.ready ? (
          <>
            <div className="col-sm-12 col-md-6">
              <DashboardIcons
                showGettingStarted={pageFilters.filters?.others?.hideHowTo}
                onShowGettingStarted={() => {
                  pageFilters.save({
                    pageSize: 0,
                    search: "",
                    others: {
                      hideHowTo: false,
                    },
                  });
                }}
              />
            </div>
            {!noHowTo && !pageFilters.filters?.others?.hideHowTo && (
              <div className="col-sm-12 col-md-6">
                <DashboardHowTo
                  onHide={(noHowTo) => {
                    setNoHowTo(noHowTo || false);
                    if (!noHowTo) {
                      pageFilters.save({
                        pageSize: 0,
                        search: "",
                        others: {
                          hideHowTo: true,
                        },
                      });
                    }
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <CommonSpinner></CommonSpinner>
        )}
      </div>
    </div>
  );
}

export default AppAdminDashboard;
