export enum SignalRSecondaryMessageTypeEnum {
    CardGeneral = 0,
    CardComments = 1,
    CardParticipants = 2,
    CardFiles = 3,
    CardLinks = 4,
    CardOtherCards = 5
}
export enum SignalRMessageTypeEnum {
    None = 0,
    UserRolesUpdated = 1,
    FullTemplateCardUpdated = 2,
    SignalRNotificationsUpdated = 3,
    FullTemplateCardDeleted = 4
}
export interface SignalRMessageDto {
    recordId?: string;
    message?: string;
    secondRecordId?: string;
    signalRMessageTypeEnum?: SignalRMessageTypeEnum;
    signalRSecondaryMessageTypeEnum?: SignalRMessageTypeEnum;
}
export interface FileUploadDto {
    name?: string;
    fileName?: string;
    description?: string;
    parentFolderId?: string;
}
export interface HttpErrorOutputDto {
    errors?: ErrorOutputDto[];
    statusCode?: number;
    errorSeverityEnum?: ErrorSeverityEnum;
}
export interface ErrorOutputDto {
    fieldName?: string;
    message?: string;
}
export enum ErrorSeverityEnum {
    Information = 0,
    Warning = 1,
    Error = 2,
}
export interface AssociatedCardInputDto {
    cardId?: string;
    associatedCardId?: string;
}
export interface GridOutputBaseDto {
    totalRecords?: number;
}
export interface AssociatedCardGridOutputDto extends GridOutputBaseDto {
    associatedCardOutputDtos?: AssociatedCardOutputDto[];
}
export interface FullTemplateCardBaseOutputDto {
    id?: string;
    cardTemplateName?: string;
    numberOfParticipants?: number;
    currentUserAccess?: FullTemplateCardAccess;
}
export interface AssociatedCardOutputDto extends FullTemplateCardBaseOutputDto {
    fields?: CardFieldInGridDto[];
    cardId?: string;
    associatedCardId?: string;
    archived?: boolean;
}
export interface CardFieldInGridDto {
    name?: string;
    fieldType?: WF1FieldType;
    sequence?: number;
    value?: string;
}
export enum WF1FieldType {
    Text = 0,
    TextArea = 1,
    Number = 2,
    Date = 3,
}
export enum FullTemplateCardAccess {
    Admin = 1,
    Contributor = 2,
    Reader = 3,
    NoAccess = 4,
}
export interface SearchInputBaseDto {
    search?: string;
}
export interface AssociatedCardSearchInputDto extends SearchInputBaseDto {
    cardId?: string;
}
export interface CardCommentCreateDto {
    text?: string;
    cardId?: string;
    parentCommentId?: string;
}
export interface CardCommentUpdateDto {
    text?: string;
}
export interface CardCommentGridOutputDto extends GridOutputBaseDto {
    cardCommentGridItemOutputDtos?: CardCommentGridItemOutputDto[];
}
export interface CardCommentGridItemOutputDto {
    commentId?: string;
    cardId?: string;
    parentCommentId?: string;
    hasChildren?: boolean;
    isNew?: boolean;
    dateCreated?: Date;
    text?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
}
export interface CardCommentBaseSearchDto {
    cardId?: string;
}
export interface CardCommentParentListDto extends CardCommentBaseSearchDto {
}
export interface CardCommentChildrenListDto extends CardCommentBaseSearchDto {
    parentCommentId?: string;
}
export interface CardCommentSearchInputDto extends SearchInputBaseDto {
    cardId?: string;
}
export interface CardCommentFindSearchResultOutputDto {
    parents?: CardCommentGridOutputDto;
    parentPage?: number;
    parentCommentIndex?: number;
    children?: CardCommentGridOutputDto;
    childPage?: number;
    childCommentIndex?: number;
}
export interface CardBaseDto {
    cardTemplateId?: string;
    userId?: string;
}
export interface CardOutputBaseDto extends CardBaseDto {
    id?: string;
    cardTemplateName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    fields?: CardFieldInGridDto[];
}
export interface CardOutputDto extends CardOutputBaseDto {
    fields?: CardFieldFilledOutputDto[];
    notificationOutputDetailDtos?: NotificationOutputDetailDto[];
}
export interface CardFieldFilledInDto {
    cardFieldId?: number;
    value?: string;
}
export interface CardFieldFilledOutputDto extends CardFieldFilledInDto {
    name?: string;
    fieldType?: WF1FieldType;
    description?: string;
    sequence?: number;
    isFieldRequired?: boolean;
}
export interface NotificationOutputDetailDto {
    id?: string;
    userTypeName?: string;
    emailAddress?: string;
    dateToSend?: Date;
    dateSent?: Date;
    canBeSent?: boolean;
    errorDate?: Date;
    errorMessage?: string;
    emailSentTo?: string;
    atTimeOfAssignment?: boolean;
    fieldName?: string;
    dayBefore?: number;
    dayAfter?: number;
}
export interface CardGridOutputDto extends GridOutputBaseDto {
    cards?: CardOutputBaseDto[];
}
export interface CardSearchInputDto extends SearchInputBaseDto {
    archiveStatus?: ArchiveStatusEnum;
}
export enum ArchiveStatusEnum {
    All = 1,
    Open = 2,
    Archived = 3,
}
export interface CardInputDto extends CardBaseDto {
    fields?: CardFieldFilledInDto[];
}
export interface CardUpdateDto {
    fields?: CardFieldFilledInDto[];
}
export interface CardFieldBaseDto {
    name?: string;
    description?: string;
    fieldType?: WF1FieldType;
    isFieldRequired?: boolean;
}
export interface CardFieldOutputBaseDto extends CardFieldBaseDto {
    id?: number;
}
export interface CardFieldOutputDto extends CardFieldOutputBaseDto {
    canBeUpdateOrDeleted?: boolean;
}
export interface CardFieldGridOutputDto extends GridOutputBaseDto {
    fields?: CardFieldOutputBaseDto[];
}
export interface CardFieldSearchInputDto extends SearchInputBaseDto {
}
export interface TypeAheadOutputDto {
    id?: string;
    label?: string;
    description?: string;
}
export interface TypeAheadInputDto {
    search?: string;
}
export interface CardFieldTypeAheadDateOnly extends TypeAheadInputDto {
    templateId?: string;
}
export interface CardFieldInputDto extends CardFieldBaseDto {
}
export interface CardFileGridOutputDto extends GridOutputBaseDto {
    cardFileOutputDtos?: CardFileOutputDto[];
}
export interface CardFileOutputDto {
    id?: string;
    userFileName?: string;
    fileName?: string;
    description?: string;
    cardId?: string;
    userId?: string;
}
export interface CardFileSearchInputDto extends SearchInputBaseDto {
    cardId?: string;
}
export interface CardLinkOutputDto {
    id?: string;
    cardId?: string;
    url?: string;
    urlText?: string;
    description?: string;
}
export interface CardLinkGridOutputDto extends GridOutputBaseDto {
    cardLinkOutputDtos?: CardLinkOutputDto[];
}
export interface CardLinkSearchDto extends SearchInputBaseDto {
    cardId?: string;
}
export interface CardLinkInputDto {
    cardId?: string;
    url?: string;
    urlText?: string;
    description?: string;
}
export interface CardLinkUpdateDto {
    url?: string;
    urlText?: string;
    description?: string;
}
export interface CardTemplateBaseDto {
    name?: string;
    description?: string;
    templateCategoryId?: string;
    userTemplate?: boolean;
    active?: boolean;
    fullTemplate?: boolean;
    showFiles?: boolean;
    showLinks?: boolean;
    showAssociatedCards?: boolean;
    allowReminders?: boolean;
}
export interface CardTemplateOutputBaseDto extends CardTemplateBaseDto {
    id?: string;
    templateCategoryName?: string;
}
export interface CardTemplateOutputDto extends CardTemplateOutputBaseDto {
    canBeUpdatedOrDeleted?: boolean;
    uniqueFieldName?: string;
    fields?: CardTemplateFieldOutputDto[];
}
export interface CardTemplateFieldOutputDto extends CardFieldOutputBaseDto {
    sequence?: number;
    uniqueFieldName?: string;
    isFieldRequired?: boolean;
}
export interface CardTemplateGridOutputDto extends GridOutputBaseDto {
    templates?: CardTemplateOutputBaseDto[];
}
export interface CardTemplateSearchInputDto extends SearchInputBaseDto {
    templateCategoryId?: string;
}
export interface CardTemplateGridWithReminderOutputDto extends GridOutputBaseDto {
    templates?: CardTemplateOutputWithReminderDto[];
}
export interface CardTemplateOutputWithReminderDto extends CardTemplateBaseDto {
    id?: string;
    templateCategoryName?: string;
    reminderSetting?: ReminderSettingDto;
}
export interface ReminderSettingDto {
    reminderSettingDetailDtos?: ReminderSettingDetailDto[];
}
export interface ReminderSettingDetailDto {
    reminderSettingDetailId?: string;
    notifyEntityUser?: boolean;
    userTypeId?: number;
    otherUserEmailAddresses?: string;
    atTimeOfAssignment?: boolean;
    fieldId?: number;
    daysBefore?: string;
    daysAfter?: string;
}
export interface CardTemplateReminderSearchInputDto extends SearchInputBaseDto {
    templateCategoryId?: string;
    reminderListFilterEnum?: ReminderListFilterEnum;
}
export enum ReminderListFilterEnum {
    All = 0,
    HasReminders = 1,
    DoesNotHaveReminders = 2,
}
export interface CardTemplateInputDto extends CardTemplateBaseDto {
    fields?: CardFieldSequenceDto[];
}
export interface CardFieldSequenceDto {
    cardFieldId?: number;
    sequence?: number;
    isFieldRequired?: boolean;
}
export interface MultipleTemplatesForCategoryListOutputDto {
    multipleTemplatesForCategoryOutputDtos?: MultipleTemplatesForCategoryOutputDto[];
}
export interface MultipleTemplatesForCategoryOutputDto {
    id?: string;
    name?: string;
    wasSuccess?: boolean;
}
export interface MultipleTemplatesForCategoryInputDto {
    categoryId?: string;
    templateIds?: string[];
}
export interface TemplateCategorySearchOutputDto {
    id?: string;
    name?: string;
    existingCategoryName?: string;
    hasReminders?: boolean;
}
export interface CompanyBaseModelDto {
    companyName?: string;
    active?: boolean;
    timeZone?: string;
}
export interface CompanyOutputDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    numberOfFullTemplates?: number;
    fileSystemSize?: number;
    companyAddress?: AddressDto;
    companySettingsDto?: CompanySettingsDto;
}
export interface AddressDto {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    mainContactName?: string;
    mainContactPhone?: string;
    mainContactEmail?: string;
}
export interface CompanySettingsDto {
    entityLabelSingular?: string;
    entityLabelPlural?: string;
    userLabelSingular?: string;
    userLabelPlural?: string;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
    allowFileSystem?: boolean;
    fileSystemSize?: number;
}
export interface CompanyGridDto extends GridOutputBaseDto {
    companyDetailItemDtos?: CompanyDetailItemDto[];
}
export interface CompanyDetailItemDto extends CompanyBaseModelDto {
    id?: string;
    archived?: boolean;
    companyAddress?: AddressDto;
}
export interface CompanySearchDto extends SearchInputBaseDto {
    active?: boolean;
    archived?: boolean;
}
export interface CompanyCreateDto extends CompanyBaseModelDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    companyAddress?: AddressDto;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
    allowFileSystem?: boolean;
    fileSystemSize?: number;
}
export interface CompanyUpdateDto extends CompanyBaseModelDto {
    archived?: boolean;
    companyAddress?: AddressDto;
    allowFullTemplates?: boolean;
    allowCommentEditDeletes?: boolean;
    editTimeLimit?: number;
    allowFileSystem?: boolean;
    fileSystemSize?: number;
}
export interface CompanyAdminOutputDto extends CompanyBaseModelDto {
    companyAddress?: AddressDto;
    companySettingsDto?: CompanySettingsDto;
    companyLogo?: string;
    maxFileSize?: number;
}
export interface CompanyNameLogoSettingsDto {
    companyName?: string;
    companySettingsDto?: CompanySettingsDto;
    companyLogo?: string;
}
export interface CompanyUpdateAdminDto {
    companyAddress?: AddressDto;
}
export interface ConfigSettingDto {
    area?: string;
    section?: string;
    value?: string;
}
export interface FileSystemGridOutputDto extends GridOutputBaseDto {
    fileSystemOutputDtos?: FileSystemOutputDto[];
}
export interface FileSystemOutputDto {
    name?: string;
    id?: string;
    description?: string;
    originalFileName?: string;
    path?: string;
    isFolder?: boolean;
    fileSize?: number;
    lastUpdatedByUserId?: string;
    lastUpdatedByName?: string;
    dateUpdated?: Date;
}
export interface FileSystemSearchInputDto extends SearchInputBaseDto {
    parentFolderId?: string;
    fileSystemSortType?: FileSystemSortTypeEnum;
    orderBy?: OrderByEnum;
    fileFolderFilter?: FileFolderFilterEnum;
    fileSystemSearchLevel?: FileSystemSearchLevelEnum;
}
export enum FileSystemSortTypeEnum {
    LastUserUpdated = 1,
    FileName = 2,
    LastUpdatedDate = 3,
}
export enum OrderByEnum {
    Ascending = 0,
    Descending = 1,
}
export enum FileFolderFilterEnum {
    Files = 1,
    Folders = 2,
    All = 3,
}
export enum FileSystemSearchLevelEnum {
    Current = 1,
    CurrentAndBelow = 2,
    All = 3,
}
export interface FileSystemRecycledSearchInputDto extends SearchInputBaseDto {
    fileSystemRecycleSortType?: FileSystemRecycleSortTypeEnum;
    orderBy?: OrderByEnum;
}
export enum FileSystemRecycleSortTypeEnum {
    FileName = 1,
    RecycledDate = 2,
}
export interface FolderInputDto {
    name?: string;
    parentFolderId?: string;
}
export interface FolderUpdateDto {
    name?: string;
}
export interface FileRestoreDto {
    fileId?: string;
    parentFolderId?: string;
}
export interface FileSystemRecycleMultipleDto {
    itemDtos?: FileSystemRecycleItemDto[];
}
export interface FileSystemRecycleItemDto {
    id?: string;
    isFolder?: boolean;
}
export interface FullTemplateCardInsertDto {
    cardTemplateId?: string;
    fields?: CardFieldFilledInDto[];
    participants?: FullTemplateCardParticipantDto[];
}
export interface FullTemplateCardParticipantDto {
    userId?: string;
    access?: FullTemplateCardAccess;
}
export interface FullTemplateCardGridOutputDto extends GridOutputBaseDto {
    fullTemplateCardGridItemOutputDtos?: FullTemplateCardGridItemOutputDto[];
}
export interface FullTemplateCardGridItemOutputDto extends FullTemplateCardBaseOutputDto {
    fields?: CardFieldInGridDto[];
    numberOfParticipants?: number;
    newChanges?: boolean;
}
export interface FullTemplateCardSearchInputDto extends SearchInputBaseDto {
    archiveStatus?: ArchiveStatusEnum;
}
export interface FullTemplateCardOutputDto extends FullTemplateCardBaseOutputDto {
    fields?: CardFieldFilledOutputDto[];
    showFiles?: boolean;
    showLinks?: boolean;
    showAssociatedCards?: boolean;
}
export interface FullTemplateCardUpdateDto {
    fields?: CardFieldFilledInDto[];
}
export interface FullTemplateParticipantsUpdateDto {
    participants?: FullTemplateCardParticipantDto[];
}
export interface FullTemplateParticipantGridOutputDto extends GridOutputBaseDto {
    fullTemplateParticipantGridItemOutputDtos?: FullTemplateParticipantGridItemOutputDto[];
}
export interface FullTemplateParticipantGridItemOutputDto {
    userId?: string;
    firstName?: string;
    lastName?: string;
    access?: FullTemplateCardAccess;
}
export interface FulTemplateParticipantSearchDto extends SearchInputBaseDto {
    cardId?: string;
    participantStatus?: ParticipantStatusEnum;
}
export enum ParticipantStatusEnum {
    Active = 1,
    Inactive = 2,
    All = 3,
}
export interface LookupIntDto {
    label?: string;
    value?: number;
}
export interface GlobalSiteMessageDisplayDto {
    message?: string;
    severity?: ErrorSeverityEnum;
}
export interface GlobalSiteMessageDto {
    severityEnum?: ErrorSeverityEnum;
    from?: Date;
    to?: Date;
    message?: string;
}
export interface HowToOutputDraftDto {
    id?: string;
    roles?: RolesEnum[];
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
    publishedOn?: Date;
    title?: string;
    body?: string;
    displayOnHomePage?: boolean;
}
export enum RolesEnum {
    AppAdmin = 0,
    Admin = 1,
    User = 2,
    UserManager = 3,
    ReminderManager = 4,
    TemplateManager = 5,
    SaaSAdmin = 6,
    Guest = 7,
}
export interface HowToHomePageInputDto {
    role?: RolesEnum;
}
export interface HowToInputDto {
    roles?: RolesEnum[];
    sequence?: number;
    titleDraft?: string;
    bodyDraft?: string;
    displayOnHomePage?: boolean;
}
export interface HowToGridOutputDto extends GridOutputBaseDto {
    howToOutputDtos?: HowToOutputPublishedDto[];
}
export interface HowToOutputPublishedDto {
    id?: string;
    roles?: RolesEnum[];
    sequence?: number;
    title?: string;
    body?: string;
    titleDraft?: string;
    bodyDraft?: string;
    publishedOn?: Date;
    displayOnHomePage?: boolean;
}
export interface HowToSearchDto extends SearchInputBaseDto {
    role?: RolesEnum;
}
export interface ImportExportInputBaseDto {
    name?: string;
    description?: string;
    importExportColumDefinitionDtos?: ImportExportColumDefinitionDto[];
}
export interface ImportExportOutputDto extends ImportExportInputBaseDto {
    id?: string;
    cardTemplateName?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportColumDefinitionDto {
    columnName?: string;
    fieldId?: number;
    dateFormat?: string;
}
export interface ImportExportGridDto extends GridOutputBaseDto {
    importExportGridItemDtos?: ImportExportGridItemDto[];
}
export interface ImportExportGridItemDto {
    id?: string;
    name?: string;
    description?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportSearchInputDto extends SearchInputBaseDto {
    cardTemplateId?: string;
}
export interface ImportExportInputCreateDto extends ImportExportInputBaseDto {
    cardTemplateId?: string;
    isImportDefinition?: boolean;
}
export interface ImportExportInputUpdateDto extends ImportExportInputBaseDto {
}
export interface UserInitialInputDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    password?: string;
    userTimeZone?: string;
}
export interface LogGridDto extends GridOutputBaseDto {
    logOutputDtos?: LogOutputDto[];
}
export interface LogOutputDto {
    id?: string;
    objectType?: LogObjectTypeEnum;
    objectIdGuid?: string;
    objectIdLong?: number;
    changeType?: LogChangeTypeEnum;
    userId?: string;
    eventTime?: Date;
    changes?: string;
    userName?: string;
    companyId?: string;
    companyName?: string;
}
export enum LogObjectTypeEnum {
    User = 1,
    CardField = 2,
    CardTemplate = 3,
    TemplateCategory = 4,
    Card = 5,
    Company = 6,
    UserType = 7,
}
export enum LogChangeTypeEnum {
    Create = 1,
    Update = 2,
    Delete = 3,
}
export interface LogSearchDto {
    objectType?: LogObjectTypeEnum;
    objectIdGuid?: string;
    objectIdLong?: number;
    changeType?: LogChangeTypeEnum;
    userId?: string;
    orderBy?: OrderByEnum;
    companyId?: string;
}
export interface LogSearchCompanyWideDto extends LogSearchDto {
}
export interface SubordinatesListOutputDto {
    userTypeId?: number;
    userTypeName?: string;
    managerId?: string;
    managerName?: string;
    subordinateOutputDtos?: SubordinateOutputDto[];
}
export interface SubordinateOutputDto {
    subordinateId?: string;
    name?: string;
}
export interface SubordinateSuccessOutputDto {
    subordinateIdSuccessDtos?: SubordinateIdSuccessDto[];
}
export interface SubordinateIdSuccessDto {
    subordinateId?: string;
    wasSuccess?: boolean;
}
export interface SubordinateBaseDto {
    userTypeId?: number;
    managerId?: string;
}
export interface SubordinateInputDto extends SubordinateBaseDto {
    subordinateIdDtos?: SubordinateIdDto[];
}
export interface SubordinateIdDto {
    subordinateId?: string;
}
export interface TokenDto {
    token?: string;
    refreshToken?: string;
    refreshTokenExpiration?: Date;
    sessionId?: string;
}
export interface UserLoginDto {
    userName?: string;
    password?: string;
}
export interface RefreshTokenDto {
    refreshToken?: string;
    userName?: string;
    sessionId?: string;
}
export interface ChangePasswordRequestDto {
    currentPassword?: string;
    newPassword?: string;
}
export interface ForgotPasswordDto {
    userName?: string;
}
export interface ResetPasswordDto {
    userName?: string;
    resetPasswordToken?: string;
    password?: string;
}
export interface CustomerUserTwoFactorSecretOutputDto {
    secret?: string;
    qrCode?: string;
}
export interface PasswordDto {
    password?: string;
}
export interface UserTwoFactorLoginDto {
    userName?: string;
    code?: string;
    sessionId?: string;
    rememberMe?: boolean;
}
export interface ReleaseOutputDto {
    id?: string;
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseInputDto {
    releaseDate?: Date;
    versionNumber?: string;
    description?: string;
    public?: boolean;
}
export interface ReleaseGridOutputDto extends GridOutputBaseDto {
    releaseOutputDtos?: ReleaseOutputDto[];
}
export interface ReleaseSearchInputDto extends SearchInputBaseDto {
}
export interface ReminderSettingOutputDto {
    objectName?: string;
    userTemplate?: boolean;
    reminderSettingDetailOutputDtos?: ReminderSettingDetailIOutputDto[];
}
export interface ReminderSettingDetailIOutputDto extends ReminderSettingDetailDto {
    fieldName?: string;
    userTypeName?: string;
}
export interface ReminderSettingResultDto {
    reminderSettingResultErrorDtos?: ReminderSettingResultErrorDto[];
    reminderSettingDetailDtos?: ReminderSettingDetailDto[];
}
export interface ReminderSettingResultErrorDto {
    firstId?: string;
    secondId?: string;
    error?: string;
}
export interface ScheduledJobSettingDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    cronDescription?: string;
    nextExecutions?: Date[];
    canIRun?: boolean;
    methodName?: string;
}
export interface ScheduledJobSettingListDto {
    jobName?: string;
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    lastTimeJobRan?: Date;
    canIRun?: boolean;
}
export interface ScheduledJobSettingUpdateDto {
    cronExpression?: string;
    secondsToWaitBeforeStarting?: number;
    canIRun?: boolean;
    lastTimeJobRan?: Date;
    methodName?: string;
}
export interface SignalRNotificationGridDto extends GridOutputBaseDto {
    signalRNotificationDetailDtos?: SignalRNotificationDetailDto[];
}
export interface SignalRNotificationDetailDto {
    id?: string;
    primaryId?: string;
    signalRMessage?: SignalRMessageDto;
}
export interface SignalRMessageDto {
    recordId?: string;
    message?: string;
    signalRMessageTypeEnum?: SignalRMessageTypeEnum;
    secondRecordId?: string;
    fullTemplateGeneralChangedTabEnum?: FullTemplateGeneralChangedTabEnum;
}
export enum FullTemplateGeneralChangedTabEnum {
    MainCard = 1,
    CommentTab = 2,
    FileTab = 3,
    LinkTab = 4,
    AssociatedCardTab = 5,
}
export interface SiteDownMessageDto {
    message?: string;
    from?: Date;
    to?: Date;
}
export interface NewVersionDto {
    version?: string;
    forceUpdate?: boolean;
}
export interface ErrorMaintenanceGridOutputDto extends GridOutputBaseDto {
    errors?: ErrorMaintenanceDetailOutputDto[];
}
export interface ErrorMaintenanceDetailOutputDto {
    id?: number;
    severityLevel?: string;
    message?: string;
    exception?: string;
    properties?: JsonDocument;
    raiseDate?: Date;
}
export interface JsonDocument {
    isDisposable?: boolean;
    rootElement?: any;
}
export interface ErrorMaintenanceSearchInputDto extends SearchInputBaseDto {
    severityLevel?: ErrorMaintenanceSeverityLevel;
    fromDate?: Date;
    toDate?: Date;
}
export enum ErrorMaintenanceSeverityLevel {
    All = 0,
    Error = 1,
    Warning = 2,
    Information = 3,
    Debug = 4,
}
export interface ErrorMaintenanceDeleteInputDto {
    ids?: number[];
}
export interface TemplateCategoryBaseDto {
    name?: string;
    description?: string;
}
export interface TemplateCategoryOutputBaseDto extends TemplateCategoryBaseDto {
    id?: string;
}
export interface TemplateCategoryOutputDto extends TemplateCategoryOutputBaseDto {
    canBeDeleted?: boolean;
    uniqueFieldName?: string;
}
export interface TemplateCategoryGridOutputDto extends GridOutputBaseDto {
    templateCategories?: TemplateCategoryOutputBaseDto[];
}
export interface TemplateCategorySearchInputDto extends SearchInputBaseDto {
}
export interface TemplateCategoryGridWithReminderOutputDto extends GridOutputBaseDto {
    templateCategories?: TemplateCategoryOutputWithReminderDto[];
}
export interface TemplateCategoryOutputWithReminderDto extends TemplateCategoryBaseDto {
    id?: string;
    reminderSetting?: ReminderSettingDto;
}
export interface TemplateCategoryReminderSearchInputDto extends SearchInputBaseDto {
    reminderListFilterEnum?: ReminderListFilterEnum;
}
export interface TemplateCategoryAvailableDatesOutputDto {
    fieldId?: number;
    fieldName?: string;
    fieldDescription?: string;
}
export interface TemplateCategoryInputDto extends TemplateCategoryBaseDto {
}
export interface UserOutputDto {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
    entityTemplateId?: string;
    isTwoFactor?: boolean;
    roles?: UserRoleOutputDto[];
    userTypeForUserOutputDtos?: UserTypeForUserOutputDto[];
    fields?: CardFieldFilledOutputDto[];
    notificationOutputDetailDtos?: NotificationOutputDetailDto[];
}
export interface UserRoleOutputDto {
    rolesEnum?: RolesEnum;
    roleName?: string;
}
export interface UserTypeForUserOutputDto {
    id?: number;
    name?: string;
    canManageUsers?: boolean;
    numberOfUsers?: number;
}
export interface UserOutputForReminderManagerDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userName?: string;
    cardTemplateName?: string;
    cardTemplateId?: string;
}
export interface UserGridOutputDto extends GridOutputBaseDto {
    users?: UserOutputDto[];
}
export interface UserSearchInputDto extends SearchInputBaseDto {
    allowLogIn?: boolean;
    archived?: boolean;
    allowReminders?: boolean;
    rolesEnum?: RolesEnum;
    userTypeId?: number;
}
export interface UserGridReminderManagerOutputDto extends GridOutputBaseDto {
    users?: UserOutputReminderManagerDto[];
}
export interface UserOutputReminderManagerDto {
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    cardTemplateName?: string;
}
export interface UserSearchReminderManagerInputDto extends SearchInputBaseDto {
}
export interface UserInputBaseDto {
    firstName?: string;
    lastName?: string;
    email?: string;
    userTimeZone?: string;
    archived?: boolean;
    allowToLogin?: boolean;
    allowReminders?: boolean;
}
export interface UserCreateDto extends UserInputBaseDto {
    userName?: string;
    roles?: RolesEnum[];
    sendPasswordEmail?: boolean;
    userTypeIds?: number[];
}
export interface UserUpdateDto extends UserInputBaseDto {
    roles?: RolesEnum[];
    userTypeIds?: number[];
    fields?: CardFieldFilledInDto[];
}
export interface UserTemplateInputDto {
    templateId?: string;
}
export interface UserSettingBaseDto {
    setting?: string;
}
export interface UserSettingSettingOutputDto extends UserSettingBaseDto {
    settingId?: string;
}
export interface UserSettingSettingInputDto extends UserSettingBaseDto {
}
export interface UserTypeBaseDto {
    name?: string;
    description?: string;
    canManageUsers?: boolean;
    canReceiveAnyReminder?: boolean;
}
export interface UserTypeOutputBaseDto extends UserTypeBaseDto {
    id?: number;
}
export interface UserTypeOutputDto extends UserTypeOutputBaseDto {
    canBeDeleted?: boolean;
}
export interface UserTypeGridOutputDto extends GridOutputBaseDto {
    userTypes?: UserTypeOutputBaseDto[];
}
export interface UserTypeSearchInputDto extends SearchInputBaseDto {
}
export interface UserTypeInputDto extends UserTypeBaseDto {
}
export interface FileParameter {
    data: any;
    fileName: string;
}
export interface FileResponse {
    data: Blob;
    status: number;
    fileName?: string;
    headers?: { [name: string]: any };
}
