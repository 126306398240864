import React, { useEffect, useState } from "react";
import { useCompanySettings } from "../stores/SystemStore";
import { ChartData } from "chart.js";
import { Pie } from "react-chartjs-2";

function FileSystemWidget(props: any) {
  const companySettings = useCompanySettings();
  const [data, setData] = useState<ChartData<any>>({
    labels: ["Used Space", "Available"],
    datasets: [
      {
        label: "File System",
        data: [1, 2],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(117, 127, 135, 0.2)",
        ],
        borderColor: ["rgba(255, 99, 132, 1)", "rgb(78, 84, 90)"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        datasets: [
          ...prev.datasets.map((d) => {
            const val = Math.floor(
              Math.random() * (companySettings?.fileSystemSize || 0)
            );
            return {
              ...d,
              data: [val, (companySettings?.fileSystemSize || 0) - val],
            };
          }),
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="widget-container">
      <div>
        <h5>Files Insight</h5>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="mx-auto">
            <Pie data={data} />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="mx-auto">
            <table className="table-sm">
              <tbody>
                <tr>
                  <td>
                    <i className="fa fa-folder"></i>
                  </td>
                  <td>101 Folders</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-photo"></i>
                  </td>
                  <td>1,234 Images</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-film"></i>
                  </td>
                  <td>15 Videos</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-file-pdf-o"></i>
                  </td>
                  <td>678 PDFs</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-file-word-o"></i>
                  </td>
                  <td>1,789 Word Documents</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-file-excel-o"></i>
                  </td>
                  <td>543 Excel Files</td>
                </tr>
                <tr>
                  <td>
                    <i className="fa fa-file-o"></i>
                  </td>
                  <td>2,345 Others</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileSystemWidget;
