import SysModels from "../../models";
import fetcher from "../Fetcher";

class FullTemplateEntityService {
  get = async (id: string) => {
    const url = `/FullTemplateCard/${id}`;
    return fetcher.get<SysModels.FullTemplateCardOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.FullTemplateCardSearchInputDto
  ) => {
    const url = `/FullTemplateCard/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.FullTemplateCardGridOutputDto>(url, model);
  };

  listParticipants = async (
    page: number,
    pageSize: number,
    model: SysModels.FulTemplateParticipantSearchDto
  ) => {
    const url = `/FullTemplateCard/ListParticipants/${page}/${pageSize}`;
    return fetcher.post<SysModels.FullTemplateParticipantGridOutputDto>(
      url,
      model
    );
  };

  create = async (model: SysModels.FullTemplateCardInsertDto) => {
    const url = `/FullTemplateCard`;
    return fetcher.post<SysModels.FullTemplateCardOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.FullTemplateCardUpdateDto) => {
    const url = `/FullTemplateCard/${id}`;
    return fetcher.put<SysModels.FullTemplateCardOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/FullTemplateCard/${id}`;
    return fetcher.delete<any>(url);
  };

  updateParticipants = async (
    id: string,
    model: SysModels.FullTemplateParticipantsUpdateDto
  ) => {
    const url = `/FullTemplateCard/UpdateParticipants/${id}`;
    return fetcher.put<SysModels.FullTemplateCardOutputDto>(url, model);
  };
}

const fullTemplateEntity = new FullTemplateEntityService();
export default fullTemplateEntity;
