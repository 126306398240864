import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import CommonSpinner from "../components/CommonSpinner";
import Pagination, { usePaging } from "../components/Pagination";
import { Dropdown } from "react-bootstrap";
import FolderDialog from "./FolderDialog";
import FileSystemDialog from "./FileSystemDialog";
import FileSaver, { saveAs } from "file-saver";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import { useLastPageFilters } from "../stores/SystemStore";
import ConfirmDialog from "../components/ConfirmDialog";

function Files(props: any) {
  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [viewMode, setViewMode] = useState<"thumbnail" | "list">("thumbnail");
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [sortBy, setSortBy] = useState(
    SysModels.FileSystemSortTypeEnum.FileName
  );
  const [sortMode, setSortMode] = useState(SysModels.OrderByEnum.Ascending);
  const sortTypes = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FileSystemSortTypeEnum"),
    "Sort Types"
  );

  const [type, setType] = useState(SysModels.FileFolderFilterEnum.All);
  const types = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FileFolderFilterEnum"),
    "Types"
  );

  const [searchLevel, setSearchLevel] = useState(
    SysModels.FileSystemSearchLevelEnum.Current
  );
  const searchLevels = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FileSystemSearchLevelEnum"),
    "Search Levels"
  );

  useEffect(() => {
    sortTypes.getData();
    types.getData();
    searchLevels.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = useFetchHelper(() => {
    return SysServices.http.fileSystem.list(paging.page, paging.pageSize, {
      orderBy: sortMode,
      search: search.used,
      fileSystemSortType: sortBy,
      parentFolderId: paths[paths.length - 1]?.id || undefined,
      fileFolderFilter: type,
      fileSystemSearchLevel: searchLevel,
    });
  }, "Files and Folders");

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 0,
      search: "",
      others: {
        viewMode: "thumbnail",
        sortBy: SysModels.FileSystemSortTypeEnum.FileName,
        sortMode: SysModels.OrderByEnum.Ascending,
      },
    },
    (filters) => {
      setViewMode(filters.others?.viewMode);
      setSortBy(
        filters.others?.sortBy || SysModels.FileSystemSortTypeEnum.FileName
      );
      setSortMode(filters.others?.sortMode);
      setSearch({ used: filters.search || "", typed: filters.search || "" });
    },
    "FileSystem"
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: {
            viewMode: viewMode,
            sortBy: sortBy,
            sortMode: sortMode,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status, viewMode]);

  const [paths, setPaths] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    pageChange(1, paging.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready &&
          sortTypes.status === FetchStatus.Complete &&
          list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paging,
    paths,
    search.used,
    sortBy,
    sortMode,
    sortTypes.status,
    pageFilters.ready,
  ]);

  const [folderDialog, setFolderDialog] = useState<
    { show: boolean; id?: string; name?: string; parentId?: string } | undefined
  >();

  const [fileDialog, setFileDialog] = useState<
    | {
        show: boolean;
        id?: string;
        parentId?: string;
        forEdit?: SysModels.FileSystemOutputDto;
      }
    | undefined
  >();

  const onItemClick = (item: SysModels.FileSystemOutputDto) => {
    if (item.isFolder) {
      setPaths((prev) => {
        return [...prev, { id: item.id || "", name: item.name || "" }];
      });
    } else {
      setFileDialog({
        show: true,
        parentId: paths[paths.length - 1]?.id || undefined,
        id: item.id,
        forEdit: { ...item },
      });
    }
  };

  const onEditFolder = (item: SysModels.FileSystemOutputDto) => {
    setFolderDialog({
      show: true,
      parentId: paths[paths.length - 1]?.id || undefined,
      id: item.id,
      name: item.name,
    });
  };

  const onDownloadFile = (item: SysModels.FileSystemOutputDto) => {
    item.id &&
      SysServices.http.fileSystem
        .download(item.id)
        .then((data) => {
          const file = commonService.b64toBlob(data.fileContents);
          FileSaver.saveAs(
            file,
            commonService.getFileName(
              data.fileDownloadName,
              item.originalFileName || ""
            )
          );
        })
        .catch((err) => {
          toastStore.showError("Download Failed", err);
        });
  };

  const [deleteItem, setDeleteItem] = useState<SysModels.FileSystemOutputDto>();
  const [beingDeleted, setBeingDeleting] = useState<string[]>([]);
  const onDeleteItem = (item: SysModels.FileSystemOutputDto) => {
    if (item.id) {
      setBeingDeleting([...beingDeleted, item.id]);
      if (item.isFolder) {
        SysServices.http.fileSystem
          .recycleFolder(item.id)
          .then((data) => {
            toastStore.showToast("Folder Deleted", "success");
            list.getData();
          })
          .catch((err) => {
            toastStore.showError("Failed Deleting Folder", err);
          })
          .finally(() => {
            setBeingDeleting((prev) => prev.filter((p) => item.id !== p));
          });
      } else {
        SysServices.http.fileSystem
          .recycleFile(item.id)
          .then((data) => {
            toastStore.showToast("File Deleted", "success");
            list.getData();
          })
          .catch((err) => {
            toastStore.showError("Failed Deleting File", err);
          })
          .finally(() => {
            setBeingDeleting((prev) => prev.filter((p) => item.id !== p));
          });
      }
    }
  };

  return (
    <div className="h-100 flex flex-column">
      <div className="mb-2">
        <h4>Files</h4>
      </div>

      {folderDialog?.show && (
        <FolderDialog
          parentFolder={folderDialog.parentId}
          name={folderDialog.name}
          id={folderDialog.id}
          close={(e) => {
            if (e) {
              list.getData();
            }
            setFolderDialog(undefined);
          }}
        ></FolderDialog>
      )}

      {fileDialog?.show && (
        <FileSystemDialog
          parentFolder={fileDialog.parentId}
          id={fileDialog.id}
          forEdit={fileDialog.forEdit}
          close={(e) => {
            if (e) {
              list.getData();
            }
            setFileDialog(undefined);
          }}
        ></FileSystemDialog>
      )}

      {!!deleteItem && (
        <ConfirmDialog
          show={true}
          title={deleteItem.isFolder ? "Delete Folder" : "Delete File"}
          message={`Do you really want to delete this ${
            deleteItem.isFolder ? "folder" : "file"
          }?`}
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              onDeleteItem(deleteItem);
            }
            setDeleteItem(undefined);
          }}
        ></ConfirmDialog>
      )}

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="flex-0" style={{ maxWidth: "100%" }}>
          <div className="input-group search-box">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Search"
              autoComplete="new-password"
              value={search.typed}
              onChange={(e) => {
                setSearch((data) => {
                  return {
                    ...data,
                    typed: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }
              }}
            ></input>
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    return { typed: "", used: "" };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              disabled={list.status === FetchStatus.InProgress}
              className="no-wrap"
            >
              <span className="mx-2">
                {sortTypes?.data?.find((item) => item.value === sortBy)
                  ?.label || "Loading..."}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {sortTypes.data?.map((item) => (
                <Dropdown.Item
                  key={item.value}
                  active={sortBy === item.value}
                  onClick={() => {
                    setSortBy(item.value || 0);
                    pageChange(1, paging.pageSize);
                  }}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-1">
          <button
            className="btn btn-primary no-wrap me-2"
            type="button"
            onClick={(e) => {
              setFolderDialog({
                show: true,
                parentId: paths[paths.length - 1]?.id || undefined,
              });
            }}
          >
            <i className="fa fa-folder"></i>
          </button>
          <button
            className="btn btn-success no-wrap"
            type="button"
            onClick={(e) => {
              setFileDialog({
                show: true,
                parentId: paths[paths.length - 1]?.id || undefined,
              });
            }}
          >
            <i className="fa fa-upload"></i>
          </button>
        </div>
        <div className="flex-1"></div>
      </div>

      <div
        className="alert alert-light mb-0 py-2 flex flex-row flex-center"
        style={{
          //backgroundColor: "#fff",
          borderBottom: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <div className="flex-1 bread-crumb-container">
          <span
            className="text-primary pointer me-1 bread-crumb-path"
            onClick={(e) => {
              setPaths([]);
            }}
          >
            <i className="fa fa-home fa-1-5x me-2"></i>Home
          </span>
          {paths.map((p, i) => {
            if (i === paths.length - 1) {
              return (
                <span key={p.id} className="bread-crumb-path">
                  /<span className="mx-1">{p.name}</span>
                </span>
              );
            }
            return (
              <span
                key={p.id}
                className="bread-crumb-path"
                onClick={(e) => {
                  setPaths((prev) => {
                    return prev.slice(0, i + 1);
                  });
                }}
              >
                /<span className="mx-1 pointer text-primary">{p.name}</span>
              </span>
            );
          })}
        </div>
        <div className="flex-0">
          <div className="btn-group">
            <button
              className="btn btn-sm me-2"
              type="button"
              onClick={(e) => {
                if (sortMode === SysModels.OrderByEnum.Ascending) {
                  setSortMode(SysModels.OrderByEnum.Descending);
                } else {
                  setSortMode(SysModels.OrderByEnum.Ascending);
                }
              }}
            >
              {sortMode === SysModels.OrderByEnum.Ascending ? (
                <i className="fa fa-sort-alpha-asc"></i>
              ) : (
                <i className="fa fa-sort-alpha-desc"></i>
              )}
            </button>

            <button
              type="button"
              className="btn btn-sm btn-secondary"
              disabled={viewMode === "thumbnail"}
              onClick={(e) => {
                setViewMode("thumbnail");
              }}
            >
              <i className="fa fa-th-large"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              disabled={viewMode === "list"}
              onClick={(e) => {
                setViewMode("list");
              }}
            >
              <i className="fa fa-list"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="flex-1 alert alert-light mb-1"
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <div
          className="flex flex-row gap-10 flex-wrap py-2"
          style={{
            justifyContent: "space-evenly",
            alignContent: "flex-start",
          }}
        >
          {viewMode === "thumbnail" && (
            <>
              {list.status === FetchStatus.Complete &&
                list.data?.fileSystemOutputDtos?.map((item) => (
                  <div
                    key={item.path}
                    className="file-item text-center pointer alert alert-light mb-1"
                    onClick={(e) => {
                      if (beingDeleted.includes(item.id || "")) return;
                      onItemClick(item);
                    }}
                  >
                    {item.isFolder ? (
                      <i className="fa fa-folder fa-2x text-primary"></i>
                    ) : (
                      <i className="fa fa-file fa-2x text-success"></i>
                    )}
                    {item.isFolder ? (
                      <i
                        className="fa fa-pencil pointer me-2"
                        title="Edit"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (beingDeleted.includes(item.id || "")) return;
                          onEditFolder(item);
                        }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-download pointer me-2"
                        title="Download"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (beingDeleted.includes(item.id || "")) return;
                          onDownloadFile(item);
                        }}
                      ></i>
                    )}
                    <i
                      className="fa fa-trash pointer text-danger"
                      title="Delete"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (beingDeleted.includes(item.id || "")) return;
                        setDeleteItem(item);
                      }}
                    ></i>
                    {beingDeleted.includes(item.id || "") && (
                      <div className="item-overlay">
                        <span>
                          <i className="fa fa-spin fa-circle-o-notch"></i>
                        </span>
                      </div>
                    )}
                    <div title={item.name}>{item.name}</div>
                  </div>
                ))}
              {!!list.data?.fileSystemOutputDtos?.length &&
                [
                  ...Array.from(
                    { length: Math.round(window.innerWidth / 200) },
                    (_, i) => i + 1
                  ),
                ].map((e, i) => (
                  <div
                    key={i}
                    style={{ opacity: 0 }}
                    className="file-item"
                  ></div>
                ))}
            </>
          )}
          {viewMode === "list" && (
            <>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th className="w-60">Name</th>
                    <th className="w-40">Date Modified</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.status === FetchStatus.Complete &&
                    list.data?.fileSystemOutputDtos?.map((item) => (
                      <tr
                        key={item.id}
                        className="pointer"
                        onClick={(e) => {
                          if (beingDeleted.includes(item.id || "")) return;
                          onItemClick(item);
                        }}
                      >
                        <td>
                          {item.isFolder ? (
                            <i className="fa fa-folder text-primary"></i>
                          ) : (
                            <i className="fa fa-file text-success"></i>
                          )}
                        </td>
                        <td>
                          {item.name}
                          {!item.isFolder && (
                            <div>
                              <small>{item.description}</small>
                            </div>
                          )}
                        </td>
                        <td>
                          <small>
                            {commonService.toLocalDate(
                              item.dateUpdated,
                              "full"
                            )}
                          </small>
                        </td>
                        <td className="text-center">
                          {beingDeleted.includes(item.id || "") ? (
                            <span>
                              <i className="fa fa-spin fa-circle-o-notch"></i>
                            </span>
                          ) : (
                            <>
                              {item.isFolder ? (
                                <i
                                  className="fa fa-pencil pointer me-3"
                                  title="Edit"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (beingDeleted.includes(item.id || ""))
                                      return;
                                    onEditFolder(item);
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-download pointer me-3"
                                  title="Download"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (beingDeleted.includes(item.id || ""))
                                      return;
                                    onDownloadFile(item);
                                  }}
                                ></i>
                              )}
                              <i
                                className="fa fa-trash pointer text-danger"
                                title="Delete"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (beingDeleted.includes(item.id || ""))
                                    return;
                                  setDeleteItem(item);
                                }}
                              ></i>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
          {list.status === FetchStatus.InProgress && (
            <div className="text-center w-100">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {list.status === FetchStatus.Complete &&
            !list.data?.fileSystemOutputDtos?.length && (
              <div className="flex-1 text-secondary p-2 py-3 text-center">
                <i
                  className="fa fa-folder-open-o"
                  style={{
                    color: "#ddd",
                    fontSize: "40px",
                  }}
                ></i>
              </div>
            )}
        </div>
      </div>
      {(list.data?.totalRecords || 0) > paging.pageSize && (
        <div className="hide-on-print">
          <Pagination
            length={list.data?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
          ></Pagination>
        </div>
      )}
    </div>
  );
}

export default Files;
