import SysModels from "../../models";
import fetcher from "../Fetcher";
class EntityCommentService {
  create = async (model: SysModels.CardCommentCreateDto) => {
    const url = `/CardComment`;
    return fetcher.post<string>(url, model);
  };

  update = async (id: string, model: SysModels.CardCommentUpdateDto) => {
    const url = `/CardComment/${id}`;
    return fetcher.put<any>(url, model);
  };

  delete = async (id: string) => {
    const url = `/CardComment/${id}`;
    return fetcher.delete<any>(url);
  };

  listParents = async (
    page: number,
    pageSize: number,
    model: SysModels.CardCommentParentListDto
  ) => {
    const url = `/CardComment/ListParents/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardCommentGridOutputDto>(url, model);
  };

  listChildren = async (
    page: number,
    pageSize: number,
    model: SysModels.CardCommentChildrenListDto
  ) => {
    const url = `/CardComment/ListChildren/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardCommentGridOutputDto>(url, model);
  };

  search = async (
    page: number,
    pageSize: number,
    model: SysModels.CardCommentSearchInputDto
  ) => {
    const url = `/CardComment/Search/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardCommentGridOutputDto>(url, model);
  };

  findSearchResult = async (
    parentPageSize: number,
    childPageSize: number,
    commentId: string
  ) => {
    const url = `/CardComment/FindSearchResult/${parentPageSize}/${childPageSize}/${commentId}`;
    return fetcher.get<SysModels.CardCommentFindSearchResultOutputDto>(url);
  };
}

const entityComment = new EntityCommentService();
export default entityComment;
