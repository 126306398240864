import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityTemplateService {
  get = async (id: string) => {
    const url = `/CardTemplate/${id}`;
    return fetcher.get<SysModels.CardTemplateOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.CardTemplateSearchInputDto
  ) => {
    const url = `/CardTemplate/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardTemplateGridOutputDto>(url, model);
  };

  listWithReminders = async (
    page: number,
    pageSize: number,
    model: SysModels.CardTemplateReminderSearchInputDto
  ) => {
    const url = `/CardTemplate/ListWithReminders/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardTemplateGridWithReminderOutputDto>(
      url,
      model
    );
  };

  typeAhead = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/CardTemplate/TypeAhead`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  typeAheadForCard = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/CardTemplate/TypeAheadForCard`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  typeAheadForFullTemplate = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/CardTemplate/TypeAheadForFullTemplate`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  typeAheadForUser = async (model: SysModels.TypeAheadInputDto) => {
    const url = `/CardTemplate/TypeAheadForUser`;
    return fetcher.post<SysModels.TypeAheadOutputDto[]>(url, model);
  };

  create = async (model: SysModels.CardTemplateInputDto) => {
    const url = `/CardTemplate`;
    return fetcher.post<SysModels.CardTemplateOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.CardTemplateInputDto) => {
    const url = `/CardTemplate/${id}`;
    return fetcher.put<SysModels.CardTemplateOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/CardTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  updateSequence = async (
    id: string,
    model: SysModels.CardFieldSequenceDto[]
  ) => {
    const url = `/CardTemplate/UpdateSequence/${id}`;
    return fetcher.put<any>(url, model);
  };

  deleteField = async (entityId: string, fieldId: number) => {
    const url = `/CardTemplate/DeleteField/${entityId}/${fieldId}`;
    return fetcher.delete<any>(url);
  };

  getEntityTemplateForReminder = async (id: string) => {
    const url = `/CardTemplate/CardTemplateForReminder/${id}`;
    return fetcher.get<SysModels.CardTemplateOutputDto>(url);
  };

  getEntityTemplateForEntity = async (id: string) => {
    const url = `/CardTemplate/CardTemplateForCard/${id}`;
    return fetcher.get<SysModels.CardTemplateOutputDto>(url);
  };

  addMultipleTemplatesToCategory = async (
    model: SysModels.MultipleTemplatesForCategoryInputDto
  ) => {
    const url = `/CardTemplate/AddMultipleTemplatesToCategory`;
    return fetcher.post<SysModels.MultipleTemplatesForCategoryListOutputDto>(
      url,
      model
    );
  };

  searchTemplatesForCategory = async (
    model: SysModels.CardTemplateSearchInputDto
  ) => {
    const url = `/CardTemplate/SearchTemplatesForCategory`;
    return fetcher.post<SysModels.TemplateCategorySearchOutputDto[]>(
      url,
      model
    );
  };

  allowFullTemplate = async () => {
    const url = `/CardTemplate/AllowFullTemplate`;
    const data = fetcher.getCachedData(url);
    if (data) {
      return await new Promise<boolean>((resolve) => {
        setTimeout(() => resolve(data), 100);
      });
    }
    return fetcher.get<boolean>(url).then((data) => {
      fetcher.setCachedData(url, data);
      return data;
    });
  };
}

const entityTemplate = new EntityTemplateService();
export default entityTemplate;
