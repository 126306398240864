import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";
import FileDialog from "./FileDialog";
import SysModels from "../models";
import commonService from "../services/CommonService";
import FileSaver from "file-saver";
import { useLastPageFilters, useLoginStatus } from "../stores/SystemStore";

function FilesTab(props: {
  cardId: string;
  access: SysModels.FullTemplateCardAccess;
  dialogOpen: (open: boolean) => void;
  onSaved?: () => void;
}) {
  const loginStatus = useLoginStatus();
  const [showDialog, setShowDialog] = useState(false);
  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(async () => {
    return SysServices.http.cardFile.list(paging.page, paging.pageSize, {
      search: search.used,
      cardId: props.cardId || "",
    });
  }, "Files");

  const [showDelete, setShowDelete] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [showEdit, setShowEdit] = useState<SysModels.CardFileOutputDto>();

  const [downloading, setDownloading] = useState<string[]>([]);
  const startDownload = async (fileId: string, fileName: string) => {
    setDownloading((prev) => [...prev, fileId]);
    await SysServices.http.cardFile
      .download(fileId)
      .then((data) => {
        setDownloading((prev) => prev.filter((id) => id !== fileId));
        const file = commonService.b64toBlob(data.fileContents);
        FileSaver.saveAs(
          file,
          commonService.getFileName(data.fileDownloadName, fileName)
        );
      })
      .catch((err) => {
        setDownloading((prev) => prev.filter((id) => id !== fileId));
        toastStore.showError("Download Failed", err);
      });
  };

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 10,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
      }
    },
    "FilesTab"
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: "",
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && props.cardId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  return (
    <>
      {(showDialog || !!showEdit) && (
        <FileDialog
          cardId={props.cardId}
          forEdit={showEdit}
          close={(data) => {
            setShowDialog(false);
            setShowEdit(undefined);
            props.dialogOpen(false);
            if (data) {
              list.getData();
              props.onSaved?.();
            }
          }}
        ></FileDialog>
      )}
      <ConfirmDialog
        show={!!showDelete}
        title="Delete File"
        message="Do you really want to delete this file?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes" && showDelete) {
            setDeleting(true);
            SysServices.http.cardFile
              .delete(showDelete)
              .then((data) => {
                toastStore.showToast("File Deleted", "success");
                list.getData();
                props.onSaved?.();
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting File", err);
              })
              .finally(() => {
                setShowDelete(undefined);
                props.dialogOpen(false);
                setDeleting(false);
              });
          } else {
            setShowDelete(undefined);
            props.dialogOpen(false);
          }
        }}
      ></ConfirmDialog>

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="flex-0" style={{ maxWidth: "100%" }}>
          <div className="input-group search-box">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Search..."
              value={search.typed}
              onChange={(e) => {
                setSearch((data) => {
                  return {
                    ...data,
                    typed: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }
              }}
            ></input>
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    return { typed: "", used: "" };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        {(props.access === SysModels.FullTemplateCardAccess.Admin ||
          props.access === SysModels.FullTemplateCardAccess.Contributor) && (
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => {
                setShowDialog(true);
                props.dialogOpen(true);
              }}
            >
              Add File
            </button>
          </div>
        )}
        <div className="flex-1"></div>
      </div>

      <div className="p-0" style={{ position: "relative" }}>
        {list.status === FetchStatus.InProgress && (
          <CommonSpinner message="Loading..."></CommonSpinner>
        )}
        {deleting && (
          <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
        )}
        {list.status !== FetchStatus.InProgress && (
          <>
            <div>
              <table className="table table-bordered table-hover mt-2">
                <thead>
                  <tr>
                    <th className="w-40">File Name</th>
                    <th className="w-60">File Description</th>
                    <th className="w-10">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={3}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list.data?.cardFileOutputDtos?.map((row) => (
                    <tr
                      key={row.id}
                      className="pointer"
                      onClick={(e) => {
                        if (
                          props.access ===
                            SysModels.FullTemplateCardAccess.Admin ||
                          props.access ===
                            SysModels.FullTemplateCardAccess.Contributor
                        ) {
                          setShowEdit(row);
                          props.dialogOpen(true);
                        }
                      }}
                    >
                      <td>{row.userFileName}</td>
                      <td>{row.description}</td>
                      <td className="no-wrap">
                        {(props.access ===
                          SysModels.FullTemplateCardAccess.Admin ||
                          props.access ===
                            SysModels.FullTemplateCardAccess.Contributor) && (
                          <i
                            className="fa fa-pencil text-primary pointer mx-2"
                            title="Edit"
                          ></i>
                        )}
                        {downloading.find((id) => id === row.id) ? (
                          <span className="mx-2">
                            <i className="fa fa-spin fa-spinner"></i>
                          </span>
                        ) : (
                          <i
                            className="fa fa-download text-success pointer mx-2"
                            title="Download"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              row.id &&
                                startDownload(row.id, row.fileName || "");
                            }}
                          ></i>
                        )}
                        {(props.access ===
                          SysModels.FullTemplateCardAccess.Admin ||
                          row.userId === loginStatus.userId) && (
                          <i
                            className="fa fa-trash text-danger pointer mx-2"
                            title="Delete"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDelete(row.id);
                              props.dialogOpen(true);
                            }}
                          ></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="hide-on-print">
              <Pagination
                length={list.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel={"Links"}
                sizes={[10, 15, 25, 50, 100]}
              ></Pagination>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FilesTab;
