import React, { useEffect, useMemo, useState } from "react";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import { useNavigate } from "react-router-dom";
import SysModels from "../models";
import systemStore from "../stores/SystemStore";
import { reaction } from "mobx";
import CommonSpinner from "../components/CommonSpinner";

function TwoFactorAuth(props: { must?: boolean }) {
  const navigate = useNavigate();
  const [pwData, setPwData] = useState({
    currentPassword: "",
  });
  const [inProgress, setInProgress] = useState(false);
  const [data2fa, setData2fa] =
    useState<SysModels.CustomerUserTwoFactorSecretOutputDto>();
  const [code, setCode] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [loginStatus, setLoginStatus] = useState(systemStore.loginStatus);
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.loginStatus,
      (val, prevVal, r) => {
        setLoginStatus(val);
      }
    );
    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (
      loginStatus.is2faRequired &&
      loginStatus.mustDoSomething?.need2faAuthentication
    ) {
      commonService.focusInput("2fa_code");
    } else {
      commonService.focusInput("2fa_password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const allowSave = useMemo(() => {
    if (
      data2fa ||
      (loginStatus.is2faRequired &&
        loginStatus.mustDoSomething?.need2faAuthentication)
    ) {
      return !commonService.isNullOrEmpty(code);
    }
    return !commonService.isNullOrEmpty(pwData.currentPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2fa, pwData, loginStatus, code]);

  return (
    <>
      {inProgress && (
        <CommonSpinner overlay={true} message="Processing..."></CommonSpinner>
      )}

      <div className={props.must ? "two-factor-page row" : "row"}>
        {props.must && <div className="col-sm-12 col-md-6 col-lg-4"></div>}

        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="mb-3">
            {props.must && (
              <img
                alt="Splash"
                src="/globus-center.png"
                style={{
                  maxWidth: "400px",
                  margin: "0 auto",
                  marginBottom: "20px",
                }}
              />
            )}
            <h4>Two-Factor Authentication</h4>
          </div>

          <div className="default-page-layout">
            {props.must && (
              <div className="text-danger mb-4">
                <small>
                  <i className="fa fa-shield me-1"></i> Please enter code
                  generated from your Authenticator App
                </small>
              </div>
            )}
          </div>
          {!loginStatus.is2faRequired && !data2fa && (
            <div className=" mb-2">
              <div className="">
                <span>
                  <em className="fa fa-shield me-2"></em>Enter Password to
                  Enable 2FA
                </span>
              </div>
              <div className="py-3" style={{ minHeight: "230px" }}>
                <form
                  className="pt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (allowSave) {
                      setInProgress(true);
                      SysServices.http.myAccount
                        .twoFactorSecret({
                          password: pwData.currentPassword,
                        })
                        .then((data: any) => {
                          setData2fa(data);
                          setInProgress(false);
                          commonService.focusInput("2fa_code", 500);
                        })
                        .catch((err: any) => {
                          toastStore.showError("Failed Getting 2FA Codes", err);
                          setInProgress(false);
                        });
                    }
                  }}
                >
                  <div className="flex flex-row gap-10">
                    <div className="flex-1">
                      <input
                        id="2fa_password"
                        type="password"
                        name="current-password"
                        placeholder="Current Password"
                        autoComplete="new-password"
                        className="form-control m-0"
                        value={pwData.currentPassword}
                        onChange={(e) => {
                          setPwData((p) => {
                            return {
                              ...p,
                              currentPassword: e.target.value,
                            };
                          });
                        }}
                        disabled={inProgress}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary m-0"
                        type="submit"
                        disabled={inProgress || !allowSave}
                      >
                        {inProgress ? "Sending Request..." : "Enable 2FA"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {!loginStatus.is2faRequired && !!data2fa && (
            <div className=" mb-2">
              <div>
                <span>
                  <em className="fa fa-shield me-2"></em>Setup Authenticator App
                </span>
              </div>
              <div className="pb-3" style={{ minHeight: "230px" }}>
                <hr />
                <div className="text-center pb-2">
                  <img src={`${data2fa.qrCode}`} alt="QR Code" />
                  <div style={{ fontSize: "18px" }}>{data2fa.secret}</div>
                </div>

                <form
                  className="pt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (allowSave) {
                      setInProgress(true);
                      SysServices.http.myAccount
                        .acceptTwoFactor(code)
                        .then((data: any) => {
                          SysServices.http.fetcher.storeToken(data);
                          setInProgress(false);
                          setCode("");
                          setPwData({ currentPassword: "" });
                          setData2fa(undefined);
                          toastStore.showToast(
                            "Two-Factor Authentication has been Enabled",
                            "success"
                          );
                        })
                        .catch((err: any) => {
                          toastStore.showError("Failed Validating Code", err);
                          setInProgress(false);
                        });
                    }
                  }}
                >
                  <div className="flex flex-row gap-10">
                    <div className="flex-1">
                      <input
                        id="2fa_code"
                        type="text"
                        name="current-password"
                        autoComplete="new-password"
                        placeholder="2FA Code"
                        className="form-control m-0"
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                        disabled={inProgress}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary m-0"
                        type="submit"
                        disabled={inProgress || !allowSave}
                      >
                        {inProgress ? "Sending Request..." : "Send Code"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {loginStatus.is2faRequired &&
            !loginStatus.mustDoSomething?.need2faAuthentication && (
              <div className=" mb-2">
                <div className="">
                  <span>
                    <em className="fa fa-shield me-2"></em>Enter Password to
                    Disable 2FA
                  </span>
                </div>
                <div className="py-3" style={{ minHeight: "230px" }}>
                  <form
                    className="pt-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (allowSave) {
                        setInProgress(true);
                        SysServices.http.myAccount
                          .turnOffMyTwoFactor({
                            password: pwData.currentPassword,
                          })
                          .then((data: any) => {
                            SysServices.http.fetcher.storeToken(data);
                            setInProgress(false);
                            setCode("");
                            setPwData({ currentPassword: "" });
                            setData2fa(undefined);
                            toastStore.showToast(
                              "Two-Factor Authentication has been Disabled",
                              "success"
                            );
                          })
                          .catch((err: any) => {
                            toastStore.showError("Failed to Disable 2FA", err);
                            setInProgress(false);
                          });
                      }
                    }}
                  >
                    <div className="flex flex-row gap-10">
                      <div className="flex-1">
                        <input
                          id="2fa_password"
                          type="password"
                          name="current-password"
                          autoComplete="new-password"
                          placeholder="Current Password"
                          className="form-control m-0"
                          value={pwData.currentPassword}
                          onChange={(e) => {
                            setPwData((p) => {
                              return {
                                ...p,
                                currentPassword: e.target.value,
                              };
                            });
                          }}
                          disabled={inProgress}
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-danger m-0"
                          type="submit"
                          disabled={inProgress || !allowSave}
                        >
                          {inProgress ? "Sending Request..." : "Disable 2FA"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

          {loginStatus.is2faRequired &&
            loginStatus.mustDoSomething?.need2faAuthentication && (
              <div className=" mb-2">
                {/* <div className="">
                  <span>
                    <em className="fa fa-shield me-2"></em>Enter Code from
                    Authenticator App
                  </span>
                </div> */}
                <div className="pb-3">
                  <form
                    className="pt-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (allowSave) {
                        setInProgress(true);
                        SysServices.http.myAccount
                          .loginTwoFactor({
                            code: code,
                            userName:
                              SysServices.http.fetcher.getUsernameToken(),
                            rememberMe: rememberMe,
                            sessionId:
                              SysServices.http.fetcher.getToken()?.sessionId ||
                              "",
                          })
                          .then((data: any) => {
                            SysServices.http.fetcher.storeToken(data);
                            setInProgress(false);
                            navigate("/");
                          })
                          .catch((err: any) => {
                            toastStore.showError("Failed Validating Code", err);
                            setInProgress(false);
                          });
                      }
                    }}
                  >
                    <div className="flex flex-row gap-10">
                      <div className="flex-1">
                        <input
                          id="2fa_code"
                          type="text"
                          name="current-password"
                          autoComplete="new-password"
                          placeholder="2FA Code"
                          className="form-control m-0"
                          value={code}
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                          disabled={inProgress}
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-primary m-0"
                          type="submit"
                          disabled={inProgress || !allowSave}
                        >
                          {inProgress ? "Sending Request..." : "Send Code"}
                        </button>
                      </div>
                    </div>

                    <div className="pt-2 flex flex-row flex-center">
                      <div className="flex-1">
                        <input
                          id="rememberMe"
                          type="checkbox"
                          className="me-2"
                          checked={rememberMe}
                          onChange={(e) => {
                            setRememberMe(e.target.checked);
                          }}
                        />
                        <label htmlFor="rememberMe" className="pointer">
                          Remember Me
                        </label>
                      </div>
                      <div>
                        <a
                          className="text-danger"
                          style={{ textDecoration: "none" }}
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setInProgress(true);
                            SysServices.http.myAccount
                              .logout(
                                SysServices.http.fetcher.getToken().sessionId
                              )
                              .then((data) => {
                                //do nothing...
                              })
                              .catch((err) => {
                                toastStore.showError("Logout Error", err);
                              })
                              .finally(() => {
                                SysServices.http.fetcher.clearToken();
                                systemStore.clearAuthData();
                                setInProgress(false);
                                navigate("/", { replace: true });
                              });
                          }}
                        >
                          <span className="me-2">Logout</span>
                          <i className="fa fa-sign-out"></i>
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4"></div>
        {!props.must && <div className="col-sm-12 col-md-6 col-lg-4"></div>}
      </div>
    </>
  );
}

export default TwoFactorAuth;
