import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../components/FormModal";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useErrorDialog } from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";
import FileUpload from "../components/FileUpload";
import FileSaver from "file-saver";

interface EntityFileInputDto {
  userFileName: string;
  description: string;
  entityId: string;
}

function FileDialog(props: {
  cardId: string;
  forEdit?: SysModels.CardFileOutputDto;
  close: (data?: EntityFileInputDto) => void;
}) {
  const errDialog = useErrorDialog();
  const [files, setFiles] = useState<File[]>([]);
  const [model, setModel] = useState<EntityFileInputDto>({} as any);

  const maxSize = useFetchHelper(
    SysServices.http.cardFile.getMaxFileSize,
    "Max File Size"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    if (files.length === 0) {
      formData.append("file", null as any);
    }

    const data = {
      CardId: props.cardId,
      UserFileName: model.userFileName,
      Description: model.description,
    };
    formData.append("jsonData", JSON.stringify(data));

    setSaving(true);
    await (props.forEdit?.id
      ? SysServices.http.cardFile.update(props.forEdit.id, formData)
      : SysServices.http.cardFile.upload(formData)
    )
      .then((data) => {
        toastStore.showToast("File Saved", "success");
        props.close(data);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving File", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const allowSubmit = useMemo(() => {
    if (commonService.isNullOrWhitespace(model.userFileName || "")) {
      return false;
    }
    return !!props.cardId || files.length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (props.forEdit && props.forEdit?.id) {
      setModel({
        userFileName: props.forEdit.userFileName || "",
        description: props.forEdit.description || "",
        entityId: props.cardId,
      });
      commonService.focusInput("textFilename", 500);
    } else {
      commonService.focusInput("textFilename", 500);
    }

    maxSize.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [downloading, setDownloading] = useState(false);
  const startDownload = async (fileId: string) => {
    setDownloading(true);
    await SysServices.http.cardFile
      .download(fileId)
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents);
        FileSaver.saveAs(
          file,
          commonService.getFileName(
            data.fileDownloadName,
            props.forEdit?.fileName || ""
          )
        );
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  return (
    <FormModal
      title={`${props.forEdit?.id ? "Edit" : "Add"} File`}
      size="md"
      isOpen={true}
      close={() => {
        props.close();
      }}
      submit={() => {
        save();
      }}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      disableSubmit={!allowSubmit || saving}
      moveBehind={errDialog.show}
    >
      <div>
        {maxSize.status === FetchStatus.InProgress && (
          <CommonSpinner overlay={true} message="Loading..."></CommonSpinner>
        )}
        <div style={{ width: "100%" }}>
          <div className="pt-2">
            <div className="mb-2">
              <div className="mb-1">
                <label className="required-label">File Name</label>
              </div>
              <input
                id="textFilename"
                placeholder="File Name"
                className="form-control"
                value={model.userFileName || ""}
                maxLength={30}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      userFileName: e.target.value || "",
                    };
                  });
                }}
              ></input>
            </div>
            <div className="mb-2">
              <div className="mb-1">
                <label>File Description</label>
              </div>
              <textarea
                className="form-control"
                placeholder="Description"
                value={model.description}
                rows={3}
                maxLength={250}
                onChange={(e) => {
                  setModel((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  });
                }}
              />
            </div>

            <div>
              {maxSize.status === FetchStatus.Complete && (
                <>
                  <FileUpload
                    label="Upload File"
                    disabled={saving}
                    onChange={(fileList) => {
                      if (fileList.length > 1) {
                        toastStore.showToast(
                          "Please select one file only.",
                          "warning"
                        );
                        return;
                      }
                      if (fileList[0].size > (maxSize.data || 0)) {
                        toastStore.showToast(
                          `File size must not exceed ${commonService.toBytesDisplay(
                            maxSize.data || 0
                          )}.`,
                          "warning"
                        );
                        return;
                      }
                      if (fileList.length > 0) {
                        const list = [];
                        let cnt = 0;
                        while (cnt < fileList.length) {
                          const file = fileList[cnt];
                          list.push(file);
                          cnt++;
                        }
                        setFiles(list);
                        if (list[0]) {
                          setModel({
                            ...model,
                            userFileName: list[0].name
                              .split(".")
                              .slice(0, -1)
                              .join("."),
                          });
                        }
                      }
                    }}
                  />
                </>
              )}
              <div className="pt-2">
                {!!props.forEdit && !files.length && (
                  <div
                    className="flex flex-row alert alert-light m-0 p-1 px-2 flex flex-center"
                    style={{
                      borderRadius: 0,
                    }}
                  >
                    <span className="me-2 flex-1">
                      <i className="fa fa-file text-success me-2"></i>
                      {/* {props.forEdit?.userFileName || ""} */}
                      File
                    </span>
                    <span className="mx-2">
                      {downloading ? (
                        <i className="fa fa-spin fa-spinner"></i>
                      ) : (
                        <i
                          className="fa fa-download text-success pointer"
                          title="Download"
                          onClick={(e) => {
                            props.forEdit?.id &&
                              startDownload(props.forEdit?.id);
                          }}
                        ></i>
                      )}
                    </span>
                    {/* <i className="fa fa-eye pointer"></i> */}
                  </div>
                )}
                <div className="pt-2">
                  {files.map((file, i) => (
                    <div key={i} className="alert alert-success p-1 px-2">
                      <i className="fa fa-file me-2"></i>
                      {file.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default FileDialog;
