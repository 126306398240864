import React, { useState } from "react";
import FormModal from "../components/FormModal";
import Card from "./Card";
import { useCompanySettings, useErrorDialog } from "../stores/SystemStore";

function FullCardDialog(props: {
  close: (changed?: boolean) => void;
  cardId?: string;
  templateId?: string;
}) {
  const companySettings = useCompanySettings();
  const [moveBehind, setMoveBehind] = useState(false);
  const errDialog = useErrorDialog();
  const [saved, setSaved] = useState(false);

  return (
    <FormModal
      title={`${props.cardId ? "Edit" : "Add"} ${
        companySettings?.entityLabelSingular
      }`}
      size="xl"
      isOpen={true}
      close={() => {
        props.close(saved);
      }}
      submit={() => {}}
      customBodyFooter={true}
      moveBehind={errDialog.show || moveBehind}
    >
      <div className="p-4 pt-2">
        <Card
          fullTemplate={true}
          idForDialog={props.cardId}
          templateIdForDialog={props.templateId}
          closeDialog={(changed?: boolean) => props.close(changed || saved)}
          modalOpened={(open) => {
            setMoveBehind(open);
          }}
          onSomethingSaved={() => setSaved(true)}
        ></Card>
      </div>
    </FormModal>
  );
}

export default FullCardDialog;
