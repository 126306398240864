import { ChartData, ChartOptions } from "chart.js";
import React, { useEffect, useState } from "react";

import { Bar, Pie } from "react-chartjs-2";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { useCompanySettings } from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";

export const options: ChartOptions<any> = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

function CardChartsWidget(props: any) {
  const [data, setData] = useState<ChartData<any>>({
    labels: [],
    datasets: [
      {
        label: "#",
        data: [1, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const templates = useFetchHelper(
    () =>
      SysServices.http.cardTemplate.typeAheadForFullTemplate({
        search: "*",
      }),
    "Templates"
  );

  useEffect(() => {
    templates.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates.status === FetchStatus.Complete) {
      setData((prev) => {
        return {
          ...prev,
          labels: [...(templates.data || []).map((x) => x.label)],
          datasets: [
            {
              ...prev.datasets[0],
              label: `# of ${companySettings?.entityLabelPlural}`,
              data: [
                ...(templates.data || []).map(
                  (x) => Math.floor(Math.random() * 50) + 10
                ),
              ],
            },
          ],
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates.status]);

  const companySettings = useCompanySettings();
  const [type, setType] = useState<"pie" | "bar">("bar");
  return (
    <div className="widget-container" style={{ minWidth: "400px" }}>
      <div className="pb-2 flex flex-row">
        <h5 className="flex-1">
          {companySettings?.entityLabelPlural} Statistics
        </h5>
        <div>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              disabled={type === "bar"}
              onClick={(e) => {
                setType("bar");
              }}
            >
              <i className="fa fa-bar-chart"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              disabled={type === "pie"}
              onClick={(e) => {
                setType("pie");
              }}
            >
              <i className="fa fa-pie-chart"></i>
            </button>
          </div>
        </div>
      </div>

      {templates.status === FetchStatus.Complete ? (
        <>
          {type === "bar" && (
            <div className="mx-auto">
              <Bar data={data} options={options} />
            </div>
          )}
          {type === "pie" && (
            <div className="mx-auto" style={{ maxWidth: "80%" }}>
              <Pie data={data} />
            </div>
          )}
        </>
      ) : (
        <>
          <CommonSpinner></CommonSpinner>
        </>
      )}
    </div>
  );
}

export default CardChartsWidget;
