import fetcher from "../Fetcher";

class SystemService {
  getFrontEndVersion = async () => {
    const url = `/System/FrontEndVersion`;
    return fetcher.get<any>(url);
  };
}

const system = new SystemService();
export default system;
