import React, { useEffect, useState } from "react";
import SysModels from "../models";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import Pagination, { usePaging } from "./Pagination";
import commonService from "../services/CommonService";
import CellOneLineWordBreak from "./CellOneLineWordBreak";
import CommonSpinner from "./CommonSpinner";
import { Dropdown } from "react-bootstrap";
import { useLastPageFilters } from "../stores/SystemStore";

function ActivityLogs(props: {
  type?: SysModels.LogObjectTypeEnum;
  stringId?: string;
  numberId?: number;
  forCompanyAdmin?: boolean;
  forTab?: boolean;
  recentOnly?: boolean;
}) {
  const [filter, setFilter] = useState<SysModels.LogSearchDto>({
    objectIdGuid: props.stringId,
    objectIdLong: props.numberId,
    orderBy: SysModels.OrderByEnum.Descending,
  });
  const [paging, setPaging] = usePaging(1, props.type === undefined ? 15 : 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const list = useFetchHelper(async () => {
    let action = SysServices.http.logService.logsEntity;
    if (props.type === SysModels.LogObjectTypeEnum.Card) {
      //do nothing--
    } else if (props.type === SysModels.LogObjectTypeEnum.Company) {
      if (props.forCompanyAdmin) {
        action = SysServices.http.logService.companyInfo;
      } else {
        action = SysServices.http.logService.logsCompany;
      }
    } else if (props.type === SysModels.LogObjectTypeEnum.User) {
      action = SysServices.http.logService.logsUsers;
    } else if (props.type === SysModels.LogObjectTypeEnum.CardField) {
      action = SysServices.http.logService.logsEntityField;
    } else if (props.type === SysModels.LogObjectTypeEnum.CardTemplate) {
      action = SysServices.http.logService.logsEntityTemplate;
    } else if (props.type === SysModels.LogObjectTypeEnum.TemplateCategory) {
      action = SysServices.http.logService.logsTemplateCategory;
    } else if (props.type === SysModels.LogObjectTypeEnum.UserType) {
      action = SysServices.http.logService.logsGroups;
    } else {
      return SysServices.http.logService.logs(
        paging.page,
        props.recentOnly ? 5 : paging.pageSize,
        filter
      );
    }
    return action(paging.page, props.recentOnly ? 5 : paging.pageSize, filter);
  }, "");

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: props.type === undefined ? 15 : 10,
      search: "",
      others: {
        order: filter.orderBy,
      },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setFilter((prev) => {
          return {
            ...prev,
            orderBy: filters.others?.order,
          };
        });
      }
    },
    `ActivityLogs-${props.type || 0}-${props.recentOnly ? "Recent" : "All"}`
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: "",
          others: {
            order: filter.orderBy,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, pageFilters.ready]);

  const [expand, setExpand] = useState<any[]>([]);
  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("LogObjectTypeEnum"),
    "Types"
  );

  useEffect(() => {
    if (props.type === undefined) {
      types.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const isJSON = (changes?: string) => {
    const rtn = commonService.tryJsonParse(changes);
    if (
      rtn === undefined ||
      rtn === null ||
      typeof rtn === "string" ||
      typeof rtn === "number"
    ) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`w-100 mb-2 ${
        props.type === undefined ? "" : "overflow-auto"
      }`}
    >
      {!props.recentOnly && (
        <>
          <div className="pb-1 flex flex-row flex-center">
            {props.type === undefined || props.forTab ? (
              <>
                {props.type === undefined && (
                  <div className="flex-0 pb-2 pe-2">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        disabled={list.status === FetchStatus.InProgress}
                        className="no-wrap"
                      >
                        <span className="me-2">
                          Type:{" "}
                          {filter.changeType === undefined
                            ? "All"
                            : types.data?.find(
                                (r) => r.value === filter.changeType
                              )?.label}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Dropdown.Item
                          active={filter.changeType === undefined}
                          onClick={() => {
                            pageChange(1, paging.pageSize);
                            setFilter((prev) => {
                              return {
                                ...prev,
                                changeType: undefined,
                              };
                            });
                          }}
                        >
                          All
                        </Dropdown.Item>
                        {types.data?.map((opt) => (
                          <Dropdown.Item
                            key={opt.value}
                            active={filter.changeType === opt.value}
                            onClick={(e) => {
                              pageChange(1, paging.pageSize);
                              setFilter((prev) => {
                                return {
                                  ...prev,
                                  changeType: opt.value,
                                };
                              });
                            }}
                          >
                            {opt.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                <div className="flex-0 pb-2 pe-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      disabled={list.status === FetchStatus.InProgress}
                      className="no-wrap"
                    >
                      <span className="me-2">
                        {filter.orderBy === SysModels.OrderByEnum.Descending
                          ? "Descending"
                          : "Ascending"}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        active={
                          filter.orderBy === SysModels.OrderByEnum.Ascending
                        }
                        onClick={() => {
                          setFilter((prev) => {
                            return {
                              ...prev,
                              orderBy: SysModels.OrderByEnum.Ascending,
                            };
                          });
                          pageChange(1, paging.pageSize);
                        }}
                      >
                        Ascending
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={
                          filter.orderBy === SysModels.OrderByEnum.Descending
                        }
                        onClick={() => {
                          setFilter((prev) => {
                            return {
                              ...prev,
                              orderBy: SysModels.OrderByEnum.Descending,
                            };
                          });
                          pageChange(1, paging.pageSize);
                        }}
                      >
                        Descending
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="flex-1 pb-2 text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    title="Refresh"
                    onClick={(e) => {
                      if (list.status !== FetchStatus.InProgress) {
                        pageChange(1, paging.pageSize);
                      }
                    }}
                  >
                    <i className="fa fa-refresh pointer"></i>
                  </button>
                </div>
              </>
            ) : (
              <>
                <span className="me-2">Activity Logs</span>
                <span
                  className="text-primary pointer"
                  onClick={(e) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        orderBy:
                          prev.orderBy === SysModels.OrderByEnum.Descending
                            ? SysModels.OrderByEnum.Ascending
                            : SysModels.OrderByEnum.Descending,
                      };
                    });
                  }}
                >
                  <i className="fa fa-calendar"></i>{" "}
                  {filter.orderBy === SysModels.OrderByEnum.Descending
                    ? "Latest"
                    : "Oldest"}
                </span>
                <span className="flex-1 text-right">
                  <i
                    className="fa fa-refresh pointer text-primary"
                    title="Refresh"
                    onClick={(e) => {
                      if (list.status !== FetchStatus.InProgress) {
                        pageChange(1, paging.pageSize);
                      }
                    }}
                  ></i>
                </span>
              </>
            )}
          </div>
        </>
      )}
      {list.status === FetchStatus.InProgress && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {list.status === FetchStatus.Complete && (
        <>
          <table className="table table-sm table-bordered table-striped table-hover m-0">
            <tbody>
              {!list.data?.logOutputDtos?.length && (
                <tr>
                  <td className="p-2 px-3">No Logs Found</td>
                </tr>
              )}
              {list.data?.logOutputDtos?.map((row) => (
                <tr
                  key={row.id}
                  className="pointer"
                  onClick={(e) => {
                    if (expand.includes(row.id)) {
                      setExpand((prev) => prev.filter((p) => p !== row.id));
                    } else {
                      setExpand((prev) => [...prev, row.id]);
                    }
                  }}
                >
                  <td className="py-2">
                    <div className="pb-2 flex flex-row">
                      <div className="flex-1">
                        <span className="no-wrap">
                          <i className="fa fa-user mx-2"></i> {row.userName}{" "}
                        </span>
                        <span className="no-wrap">
                          <i className="fa fa-calendar mx-2"></i>{" "}
                          <small>
                            {commonService.toLocalDate(row.eventTime, "full")}
                          </small>
                        </span>
                      </div>
                      <div className="px-1">
                        <LogType type={row.changeType} />
                      </div>
                    </div>
                    {!expand.includes(row.id) && (
                      <div className="pb-1 flex">
                        {!isJSON(row.changes) ? (
                          <>
                            <div>
                              <i className="fa fa-history mx-2"></i>
                            </div>
                            <span>{row.changes}</span>
                          </>
                        ) : (
                          <>
                            <div>
                              <i className="fa fa-list mx-2"></i>
                            </div>
                            <CellOneLineWordBreak
                              key={row.id}
                              text={JsonToPlaintext(
                                JSON.parse(row.changes || "{}")
                              )}
                            ></CellOneLineWordBreak>
                          </>
                        )}
                      </div>
                    )}
                    {expand.includes(row.id) && (
                      <>
                        {!isJSON(row.changes) ? (
                          <div
                            className="px-2 pb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <table className="table table-sm table-bordered mb-0">
                              <tbody>
                                <tr>
                                  <td>{row.changes}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div
                            className="px-2 pb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <JsonViewer
                              key={row.id}
                              obj={JSON.parse(row.changes || "{}")}
                            ></JsonViewer>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!props.recentOnly && (
            <div className="hide-on-print">
              <Pagination
                length={list.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                sizes={[10, 15, 25, 50, 100]}
              ></Pagination>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const LogType = (props: { type?: SysModels.LogChangeTypeEnum }) => {
  if (props.type === SysModels.LogChangeTypeEnum.Delete) {
    return <span className="chip-red">Delete</span>;
  }
  if (props.type === SysModels.LogChangeTypeEnum.Update) {
    return <span className="chip-orange">Update</span>;
  }
  return <span className="chip-green">Create</span>;
};

const JsonToPlaintext: (obj: any) => string = (obj: any) => {
  if (obj === undefined || obj === null) {
    return "";
  }
  return Object.getOwnPropertyNames(obj)
    .map((nm) => {
      if (Object.prototype.toString.call(obj[`${nm}`]) === "[object Object]") {
        return `${nm}: ${JsonToPlaintext(obj[`${nm}`])}`;
      }
      if (Array.isArray(obj[`${nm}`])) {
        const arr: any[] = obj[`${nm}`];
        return `${nm}: [${arr.map((x) => `${JsonToPlaintext(x)}`).join(", ")}]`;
      }
      return `${nm}: ${String(obj[`${nm}`])}`;
    })
    .join(" ");
};

const JsonViewer = (props: { obj: any }) => {
  if (props.obj === undefined || props.obj === null) {
    return <></>;
  }
  return (
    <>
      <table className="table table-sm table-bordered mb-0">
        <tbody>
          {Object.getOwnPropertyNames(props.obj).map((nm) => (
            <React.Fragment key={nm}>
              {Object.prototype.toString.call(props.obj[`${nm}`]) ===
              "[object Object]" ? (
                <tr>
                  <td>{nm}</td>
                  <td>
                    <div className="p-1">
                      {<JsonViewer obj={props.obj[`${nm}`]}></JsonViewer>}
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {Array.isArray(props.obj[`${nm}`]) ? (
                    <>
                      {(props.obj[`${nm}`] as any[]).map((obj, idx) => (
                        <tr key={idx}>
                          <td>{idx === 0 && nm}</td>
                          <td>{<JsonViewer obj={obj}></JsonViewer>}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>{nm}</td>
                        <td>
                          {props.obj[`${nm}`] === null
                            ? ""
                            : String(props.obj[`${nm}`])}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ActivityLogs;
