import SysModels from "../../models";
import fetcher from "../Fetcher";

class SignalRPersistenceService {
  list = async (page: number, pageSize: number) => {
    const url = `/SignalRPersistence/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.SignalRNotificationGridDto>(url);
  };
}

const signalRPersistence = new SignalRPersistenceService();
export default signalRPersistence;
