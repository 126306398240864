import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import ActivityLogs from "../components/ActivityLogs";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TyeaheadItem from "../components/TyeaheadItem";
import Pagination, { usePaging } from "../components/Pagination";
import {
  useActiveRole,
  useLoadedTab,
  useLoginStatus,
} from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";
import CategoryReminderEntry from "./CategoryReminderEntry";

function Category(props: any) {
  const loginStatus = useLoginStatus();
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.TemplateCategoryOutputDto>({
    name: "",
    description: "",
  });
  const [templates, setTemplates] = useState<
    {
      id: string;
      name: string;
      model?: SysModels.TemplateCategorySearchOutputDto;
    }[]
  >([]);

  const current = useFetchHelper(
    async () => SysServices.http.templateCategory.get(id || ""),
    "Category"
  );

  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const currentTemplates = useFetchHelper(
    async () =>
      SysServices.http.cardTemplate.list(paging.page, paging.pageSize, {
        templateCategoryId: id,
      }),
    "Templates"
  );

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && id !== "new") {
      await SysServices.http.templateCategory
        .update(id, {
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Category Saved", "success");
          current.setDataAndComplete(data);
          if (templates.length === 0) {
            //do nothing...
          } else {
            saveTemplates();
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Category", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.templateCategory
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Category Saved", "success");
          current.setDataAndComplete(data);
          nav(`/categories/${data.id}`, { replace: true });
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Category", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const saveTemplates = async () => {
    setSaving(true);
    await SysServices.http.cardTemplate
      .addMultipleTemplatesToCategory({
        categoryId: model.id,
        templateIds: templates.map((t) => t.id),
      })
      .then((data) => {
        toastStore.showToast("Templates Added", "success");
        currentTemplates.getData();
        setTemplates([]);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Adding Templates", err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!current.data?.id && id && id !== "new") {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && id !== "new") {
      currentTemplates.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/categories");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [entTemps, setEntTemps] = useState([] as any[]);
  const [isEntTempsLoading, setEntTempsLoading] = useState(false);
  const refEntTemp = React.createRef<any>();
  const handleSearchCategs = async (query: string) => {
    if ((query || "").trim().length < 3) {
      setEntTemps([]);
      setEntTempsLoading(false);
      return;
    }
    setEntTempsLoading(true);
    await SysServices.http.cardTemplate
      .searchTemplatesForCategory({
        search: query,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.name}`,
          description: i.existingCategoryName
            ? `Category: ${i.existingCategoryName}`
            : "",
          model: { ...i },
        }));
        setEntTemps(options);
        setEntTempsLoading(false);
      })
      .catch((err) => {
        setEntTemps([]);
        setEntTempsLoading(false);
      });
  };

  const activeRole = useActiveRole();
  const tabs = useLoadedTab(
    activeRole === "ReminderManager" ? ["Reminders"] : ["Detail"]
  );

  return (
    <div className="tabbed-page">
      <ConfirmDialog
        show={showDel}
        title="Delete Category"
        message="Do you really want to delete this Category?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.templateCategory
              .delete(id || "")
              .then((data) => {
                toastStore.showToast("Category Deleted", "success");
                if (activeRole === "ReminderManager") {
                  nav("/category-reminders");
                } else {
                  nav("/categories");
                }
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Category", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {loginStatus.hasRole("TemplateManager") ? (
        <h4>{id === "new" ? "Add" : "Edit"} Category</h4>
      ) : (
        <h4>View Category</h4>
      )}

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {current.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}

          {(current.status === FetchStatus.Complete || id === "new") && (
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="pt-2">
                  <div className="mb-2">
                    <div className="mb-1">
                      <label className="required-label">Name</label>
                    </div>
                    <InputWithSimilaritySearch
                      placeholder="Category Name"
                      autoFocus={true}
                      currentId={model.id}
                      value={model.name || ""}
                      onChange={(val) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            name: val,
                          };
                        });
                      }}
                      request={() =>
                        SysServices.http.templateCategory.typeAhead({
                          search: model.name || "",
                        })
                      }
                      readOnly={!loginStatus.hasRole("TemplateManager")}
                    ></InputWithSimilaritySearch>
                  </div>
                  <div className="mb-2">
                    <div className="mb-1">
                      <label>Description</label>
                    </div>
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      value={model.description}
                      rows={3}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          };
                        });
                      }}
                      readOnly={!loginStatus.hasRole("TemplateManager")}
                    />
                  </div>
                </div>

                {id && id !== "new" && (
                  <>
                    {loginStatus.hasRole("TemplateManager") && (
                      <>
                        <div className="mb-1">
                          <label>Add Templates</label>
                        </div>
                        <div className="pb-2">
                          <AsyncTypeahead
                            id="typeahead-search-category"
                            labelKey="name"
                            renderMenuItemChildren={(option, props, index) => (
                              <TyeaheadItem {...entTemps[index]}></TyeaheadItem>
                            )}
                            onSearch={handleSearchCategs}
                            onChange={(data) => {
                              if (data.length > 0) {
                                const item = data[0] as any;
                                if (
                                  templates.find((t) => t.id === item.id) ||
                                  currentTemplates.data?.templates?.find(
                                    (t) => t.id === item.id
                                  )
                                ) {
                                  toastStore.showToast(
                                    `"${item.name}" is already added.`,
                                    "warning"
                                  );
                                } else {
                                  setTemplates((prev) => {
                                    return [
                                      ...prev,
                                      {
                                        id: item.id,
                                        name: item.name,
                                        model: item.model,
                                      },
                                    ];
                                  });
                                }
                                (refEntTemp.current as any)?.clear();
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isEntTempsLoading}
                            options={entTemps}
                            placeholder="Search Templates"
                            minLength={1}
                            delay={500}
                            useCache={false}
                            filterBy={() => true}
                            ref={refEntTemp}
                          />
                        </div>
                      </>
                    )}
                    {!!templates?.length && (
                      <div className="pb-1">
                        <small>Templates To Be Added</small>
                      </div>
                    )}
                    {templates.map((temp) => (
                      <div
                        key={temp.id}
                        title={temp.name}
                        className="alert alert-sm alert-success p-2 flex flex-center mb-1"
                      >
                        <div
                          className="flex-1 pe-2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {temp.name}
                          {temp.model?.existingCategoryName && (
                            <div>
                              <small>
                                <i>
                                  Category: {temp.model?.existingCategoryName}
                                </i>
                              </small>
                            </div>
                          )}
                        </div>
                        <div>
                          <i
                            className="fa fa-times text-danger pointer"
                            onClick={(e) => {
                              setTemplates((prev) => {
                                return [...prev].filter(
                                  (t) => t.id !== temp.id
                                );
                              });
                            }}
                          ></i>
                        </div>
                      </div>
                    ))}
                    {!!currentTemplates.data?.templates?.length && (
                      <div className="py-1">
                        <small>Existing Templates</small>
                      </div>
                    )}
                    {[
                      ...(currentTemplates.data?.templates || []).map((t) => {
                        return {
                          id: t.id,
                          name: t.name,
                          model: undefined,
                        };
                      }),
                    ]?.map((temp) => (
                      <div
                        key={temp.id}
                        title={temp.name}
                        className="alert alert-sm alert-secondary p-2 flex flex-center mb-1"
                      >
                        <div
                          className="flex-1 pe-2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {temp.name}
                        </div>
                        <div>
                          <i
                            className="fa fa-eye text-primary pointer"
                            onClick={(e) => {
                              nav(`/templates/${temp.id}`);
                            }}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {(currentTemplates.data?.totalRecords || 0) > 10 && (
                  <div className="hide-on-print pb-2">
                    <Pagination
                      length={currentTemplates.data?.totalRecords || 0}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      sizes={[10, 15, 25, 50, 100]}
                    ></Pagination>
                  </div>
                )}

                <div
                  className={`pt-4 ${
                    loginStatus.hasRole("TemplateManager") ? "" : "display-none"
                  }`}
                >
                  <button
                    className="btn btn-sm btn-primary float-right"
                    type="button"
                    onClick={(e) => {
                      save();
                    }}
                    disabled={saving || deleting}
                  >
                    {saving ? "Saving..." : "Submit"}
                  </button>
                  <button
                    className="btn btn-sm btn-secondary me-2 float-right"
                    type="button"
                    onClick={(e) => {
                      if (activeRole === "ReminderManager") {
                        nav("/category-reminders");
                      } else {
                        nav("/categories");
                      }
                    }}
                    disabled={saving || deleting}
                  >
                    Cancel
                  </button>

                  {model.id && (
                    <button
                      className="btn btn-sm btn-danger"
                      type="button"
                      onClick={(e) => {
                        setShowDel(true);
                      }}
                      disabled={!model.canBeDeleted || saving || deleting}
                    >
                      {deleting ? "Deleting..." : "Delete"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </Tab>
        {id !== "new" && (
          <Tab eventKey="Reminders" title="Reminders">
            {tabs.loadedTabs.includes("Reminders") && (
              <div>
                <CategoryReminderEntry categoryId={id} />
              </div>
            )}
          </Tab>
        )}
        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            {tabs.loadedTabs.includes("Logs") && (
              <>
                {model.id && (
                  <div>
                    <ActivityLogs
                      type={SysModels.LogObjectTypeEnum.TemplateCategory}
                      stringId={model.id}
                      forTab={true}
                    ></ActivityLogs>
                  </div>
                )}
              </>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Category;
