import SysModels from "../../models";
import fetcher from "../Fetcher";

class FileSystemService {
  list = async (
    page: number,
    pageSize: number,
    model: SysModels.FileSystemSearchInputDto
  ) => {
    const url = `/FileSystem/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.FileSystemGridOutputDto>(url, model);
  };

  createFolder = async (model: SysModels.FolderInputDto) => {
    const url = `/FileSystem`;
    return fetcher.post<any>(url, model);
  };

  updateFolder = async (id: string, model: SysModels.FolderUpdateDto) => {
    const url = `/FileSystem/${id}`;
    return fetcher.put<any>(url, model);
  };

  upload = async (data: FormData) => {
    const url = `/FileSystem/Upload`;
    return fetcher.postFormData<any>(url, data);
  };

  download = async (id: string) => {
    const url = `/FileSystem/Download/${id}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  getMaxFileSize = async () => {
    const url = `/FileSystem/GetMaxFileSize`;
    return fetcher.get<number>(url);
  };

  update = async (id: string, data: FormData) => {
    const url = `/FileSystem/UpdateFile/${id}`;
    return fetcher.postFormData<any>(url, data);
  };

  recycleFile = async (id: string) => {
    const url = `/FileSystem/RecycleFile/${id}`;
    return fetcher.post<any>(url);
  };

  recycleFolder = async (id: string) => {
    const url = `/FileSystem/RecycleFolder/${id}`;
    return fetcher.post<any>(url);
  };
}

const fileSystem = new FileSystemService();
export default fileSystem;
