import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import YesNoChip from "../components/YesNoChip";
import commonService from "../services/CommonService";
import { useLastPageFilters } from "../stores/SystemStore";
import SysModels from "../models";
import { Button } from "react-bootstrap";
import DocEditor from "../components/DocEditor";

function ReleaseNotes(props: { forAppAdmin?: boolean }) {
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.release.list(paging.page, paging.pageSize, {
        search: search.used,
      }),
    "Release Notes"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  const [selectedDoc, setSelectedDoc] = useState<SysModels.ReleaseOutputDto>();

  useEffect(() => {
    if (
      list.status === FetchStatus.Complete &&
      list.data &&
      list.data.releaseOutputDtos &&
      list.data.releaseOutputDtos.length > 0
    ) {
      setSelectedDoc(list.data.releaseOutputDtos[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  const [expand, setExpand] = useState(false);

  return (
    <div className="h-100 flex flex-column">
      <div className="mb-3">
        <h4>Release Notes</h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {pageFilters.ready && (
        <>
          <div className="row flex-1">
            <div
              className={`col-sm-12 col-md-4 pb-2 flex flex-column ${
                expand ? "display-none" : ""
              }`}
            >
              <div className="flex flex-wrap gap-10 mb-3">
                <div className="flex-1" style={{ maxWidth: "100%" }}>
                  <div
                    className="input-group search-box"
                    style={{ width: "100%" }}
                  >
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              list.getData();
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                          pageChange(1, paging.pageSize);
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              list.getData();
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                          pageChange(1, paging.pageSize);
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                          pageChange(1, paging.pageSize);
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                {props.forAppAdmin && (
                  <div className="flex-0">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => {
                        nav("/release-notes-maint/new");
                      }}
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
              {list.status === FetchStatus.InProgress && (
                <CommonSpinner message="Loading..."></CommonSpinner>
              )}
              {list.status !== FetchStatus.InProgress && (
                <>
                  <div className="flex-0">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          {props.forAppAdmin ? (
                            <>
                              <th className="no-wrap">Version</th>
                              <th className="no-wrap">Release Date</th>
                              <th className="text-center no-wrap">Public</th>
                              <th className="text-center">Action</th>
                            </>
                          ) : (
                            <>
                              {/* <th className="no-wrap">Released Versions</th> */}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!list.data?.totalRecords && (
                          <tr>
                            <td colSpan={4}>No Record(s) Found</td>
                          </tr>
                        )}
                        {list?.data?.releaseOutputDtos?.map((note) => (
                          <tr
                            key={note.id}
                            className={`pointer ${
                              note.id === selectedDoc?.id ? "selected" : ""
                            }`}
                            onClick={(e) => {
                              setSelectedDoc(note);
                              var anchor =
                                document.querySelector("#document-preview");
                              anchor?.scrollIntoView();
                            }}
                          >
                            {props.forAppAdmin ? (
                              <>
                                <td>
                                  <div>{note.versionNumber}</div>
                                </td>
                                <td>
                                  <small>
                                    {commonService.toDateString(
                                      note.releaseDate,
                                      "MMMM DD, YYYY"
                                    )}
                                  </small>
                                </td>
                                <td className="text-center">
                                  <YesNoChip yes={note.public} />
                                </td>
                                <td className="text-center">
                                  <i
                                    title="Edit"
                                    className="fa fa-edit text-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      nav(`/release-notes-maint/${note.id}`);
                                    }}
                                  ></i>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <div>{note.versionNumber}</div>
                                  <small>
                                    {commonService.toDateString(
                                      note.releaseDate,
                                      "MMMM DD, YYYY"
                                    )}
                                  </small>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="hide-on-print">
                    <Pagination
                      length={list.data?.totalRecords || 0}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      sizes={[10, 15, 25, 50, 100]}
                      showingOfWhatLabel="Release Notes"
                    ></Pagination>
                  </div>
                </>
              )}
            </div>
            <div
              className={`col-sm-12 ${
                expand ? "col-md-12" : "col-md-8"
              } alert alert-light p-0 m-0`}
            >
              <div
                id="document-preview"
                className={`${!list.data?.totalRecords ? "display-none" : ""}`}
              >
                {!!selectedDoc && (
                  <div>
                    <div className="show-on-print">
                      <strong>{selectedDoc.versionNumber}</strong>
                    </div>
                    <div
                      className="hide-on-print px-4 py-2 flex flex-center"
                      style={{
                        backgroundColor: "var(--bs-light-bg-subtle)",
                        borderBottom:
                          "solid 1px var(--bs-secondary-border-subtle)",
                      }}
                    >
                      <div className="pe-4">
                        <Button
                          type="button"
                          variant={expand ? "danger" : "light"}
                          size="sm"
                          onClick={(e) => {
                            setExpand(!expand);
                          }}
                        >
                          {!expand && (
                            <i className="fa fa-expand text-primary"></i>
                          )}
                          {expand && <i className="fa fa-compress"></i>}
                        </Button>
                      </div>
                      <div className="flex-1">
                        <div>
                          <strong>{selectedDoc.versionNumber}</strong>
                        </div>
                      </div>
                      <div>
                        {/* <Button
                      type="button"
                      variant="default"
                      onClick={(e) => {
                        window.print();
                      }}
                    >
                      <i className="fa fa-print txt-primary"></i>
                    </Button> */}
                      </div>
                    </div>
                    <div className="p-4" key={selectedDoc.id || ""}>
                      <DocEditor
                        readonly={true}
                        content={selectedDoc.description || ""}
                      ></DocEditor>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ReleaseNotes;
