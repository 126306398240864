import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import {
  useCompanySettings,
  useLastPageFilters,
  useLoginStatus,
  useSignalRMessage,
} from "../stores/SystemStore";
import commonService from "../services/CommonService";
import SysModels from "../models";
import PickTemplateDialog from "./PickTemplateDialog";
import FullCardDialog from "./FullCardDialog";
import { Dropdown } from "react-bootstrap";

function FullCards(props: { viewOnly?: boolean }) {
  const loginStatus = useLoginStatus();
  const companySettings = useCompanySettings();
  const nav = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [filter, setFilter] = useState(SysModels.ArchiveStatusEnum.Open);
  const statuses = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("ArchiveStatusEnum"),
    "Archive Statuses"
  );

  const list = useFetchHelper(
    async () =>
      SysServices.http.fullTemplateCard.list(paging.page, paging.pageSize, {
        search: search.used,
        archiveStatus: filter,
      }),
    companySettings?.entityLabelPlural || "Cards"
  );

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
      others: {
        status: filter,
      },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch({
          used: filters.search,
          typed: filters.search,
        });
        if (filters.others?.status) {
          setFilter(filters.others?.status);
        }
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: {
            status: filter,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready &&
          statuses.status === FetchStatus.Complete &&
          list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready, statuses.status]);

  const [showPickTemp, setShowPickTemp] = useState(false);

  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("WF1FieldType"),
    "Field Types"
  );

  useEffect(() => {
    types.getData();
    statuses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDialog, setShowDialog] = useState<{
    id?: string;
    templateId?: string;
  }>();

  const signaRMessage = useSignalRMessage();
  useEffect(() => {
    if (
      signaRMessage?.signalRMessageTypeEnum ===
        SysModels.SignalRMessageTypeEnum.FullTemplateCardUpdated &&
      list.data
    ) {
      list.setDataAndComplete({
        ...list.data,
        fullTemplateCardGridItemOutputDtos: (
          list.data.fullTemplateCardGridItemOutputDtos || []
        ).map((card) => {
          return {
            ...card,
            newChanges: card.newChanges || card.id === signaRMessage.recordId,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signaRMessage]);

  return (
    <div>
      <div className="mb-3">
        <h4>
          {props.viewOnly
            ? "View Reminders"
            : companySettings?.entityLabelPlural}
        </h4>
      </div>
      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}
      {showPickTemp && (
        <PickTemplateDialog
          fullTemplate={true}
          onClose={(templateId?: string) => {
            setShowPickTemp(false);
            if (templateId) {
              setShowDialog({
                templateId: templateId,
                id: undefined,
              });
            }
          }}
        />
      )}
      {showDialog && (
        <FullCardDialog
          cardId={showDialog.id}
          templateId={showDialog.templateId}
          close={(changed) => {
            const cardId = showDialog.id;
            setShowDialog(undefined);
            if (changed) {
              list.getData();
            } else {
              //Mark as read when closed with no changes
              if (cardId && list.data?.fullTemplateCardGridItemOutputDtos) {
                list.setData({
                  ...list.data,
                  fullTemplateCardGridItemOutputDtos: [
                    ...list.data.fullTemplateCardGridItemOutputDtos.map((c) => {
                      if (c.id === cardId) {
                        return {
                          ...c,
                          newChanges: false,
                        };
                      }
                      return c;
                    }),
                  ],
                });
              }
            }
          }}
        />
      )}
      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  disabled={list.status === FetchStatus.InProgress}
                  className="no-wrap"
                >
                  <span className="mx-2">
                    {statuses?.data?.find((item) => item.value === filter)
                      ?.label || "Loading..."}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {statuses.data?.map((item) => (
                    <Dropdown.Item
                      key={item.value}
                      active={filter === item.value}
                      onClick={() => {
                        setFilter(item.value || 0);
                        pageChange(1, paging.pageSize);
                      }}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="flex-1">
              {!props.viewOnly && !loginStatus.hasRole("Guest") && (
                <button
                  className="btn btn-primary no-wrap"
                  type="button"
                  onClick={(e) => {
                    setShowPickTemp(true);
                  }}
                >
                  Add {companySettings?.entityLabelSingular}
                </button>
              )}
            </div>
            <div className="flex-1"></div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <div
                className="row mb-2 alert alert-light"
                style={{
                  //backgroundColor: "rgba(255, 255, 255, 0.1)",
                  padding: "12px 0px",
                  paddingBottom: "0",
                  margin: "0",
                  borderRadius: "5px",
                }}
              >
                {!list?.data?.fullTemplateCardGridItemOutputDtos?.length && (
                  <div className="pb-2 m-2">No Record(s) Found</div>
                )}
                {list?.data?.fullTemplateCardGridItemOutputDtos?.map((card) => (
                  <div
                    key={card.id}
                    className="col-sm-12 col-md-4 col-lg-3 pb-3"
                  >
                    <div
                      className="card pointer h-100"
                      onClick={(e) => {
                        setShowDialog({
                          id: card.id,
                          templateId: undefined,
                        });
                      }}
                      style={
                        card.newChanges
                          ? {
                              border: "solid 1px #2ecd99 ",
                              boxShadow: "0 0 2px 0 #2ecd99",
                            }
                          : {}
                      }
                    >
                      <div className="card-body flex flex-column">
                        {card.newChanges && (
                          <i
                            className="fa fa-circle text-success"
                            title="New Changes"
                            style={{
                              position: "absolute",
                              zIndex: 10,
                              right: "5px",
                              top: "5px",
                            }}
                          ></i>
                        )}
                        <div className="flex-1">
                          <table className="table table-sm table-bordered mb-0">
                            <tbody>
                              {card.fields
                                ?.sort(
                                  commonService.sortByNumericProperty(
                                    "sequence"
                                  )
                                )
                                ?.map((fd) => (
                                  <tr key={fd.sequence}>
                                    <td>
                                      <label>{fd.name}</label>
                                    </td>
                                    <td style={{ wordBreak: "break-all" }}>
                                      {commonService.isNullOrEmpty(
                                        fd.value || ""
                                      ) ? (
                                        <>
                                          <small className="chip-gray">
                                            No Data
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          {fd.fieldType ===
                                          SysModels.WF1FieldType.Date ? (
                                            <>
                                              {fd.value
                                                ? commonService.toDateString(
                                                    fd.value,
                                                    "ddd, MMM DD, YYYY"
                                                  )
                                                : "-"}
                                            </>
                                          ) : (
                                            <>{fd.value}</>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              <tr>
                                <td>Template</td>
                                <td>{card.cardTemplateName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="pt-2 flex flex-row flex-center">
                          <div className="flex-1 text-secondary">
                            Participants: {card.numberOfParticipants}
                          </div>
                          <div>
                            <i
                              className="fa fa-external-link pointer text-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                nav(`/cards/${card.id}`);
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel={
                    companySettings?.entityLabelPlural || "Cards"
                  }
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FullCards;
