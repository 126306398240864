import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";
import SysModels from "../models";
import { useCompanySettings, useLastPageFilters } from "../stores/SystemStore";
import OtherCardDialog from "./OtherCardDialog";
import commonService from "../services/CommonService";
import { useNavigate } from "react-router-dom";

function OtherCardsTab(props: {
  cardId: string;
  access: SysModels.FullTemplateCardAccess;
  dialogOpen: (open: boolean) => void;
  onSaved?: () => void;
}) {
  const nav = useNavigate();
  const companySettings = useCompanySettings();
  const [showDialog, setShowDialog] = useState(false);
  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.associatedCard.list(paging.page, paging.pageSize, {
        cardId: props.cardId || "",
        search: search.used || "",
      }),
    `Other ${companySettings?.entityLabelPlural}`
  );

  const [showDelete, setShowDelete] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [showEdit, setShowEdit] = useState<string>();

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 10,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
      }
    },
    "OtherCardsTab"
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: "",
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && props.cardId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, pageFilters.ready]);

  return (
    <>
      {(showDialog || !!showEdit) && (
        <OtherCardDialog
          cardId={props.cardId}
          id={showEdit}
          close={(data) => {
            setShowDialog(false);
            setShowEdit(undefined);
            props.dialogOpen(false);
            if (data) {
              list.getData();
              props.onSaved?.();
            }
          }}
        ></OtherCardDialog>
      )}
      <ConfirmDialog
        show={!!showDelete}
        title={`Delete Associated ${companySettings?.entityLabelSingular}`}
        message={`Do you really want to delete this associated ${companySettings?.entityLabelSingular}?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes" && showDelete) {
            setDeleting(true);
            setShowDelete(undefined);
            props.dialogOpen(false);
            SysServices.http.associatedCard
              .delete(props.cardId, showDelete)
              .then((data) => {
                toastStore.showToast(
                  `Associated ${companySettings?.entityLabelSingular} Deleted`,
                  "success"
                );
                list.getData();
                props.onSaved?.();
              })
              .catch((err) => {
                toastStore.showError(
                  `Failed Deleting ${companySettings?.entityLabelSingular}`,
                  err
                );
              })
              .finally(() => {
                setDeleting(false);
              });
          } else {
            setShowDelete(undefined);
            props.dialogOpen(false);
          }
        }}
      ></ConfirmDialog>

      <div className="flex flex-wrap gap-10 mb-3">
        <div className="flex-0" style={{ maxWidth: "100%" }}>
          <div className="input-group search-box">
            <input
              autoFocus={true}
              className="form-control"
              type="text"
              placeholder="Search..."
              value={search.typed}
              onChange={(e) => {
                setSearch((data) => {
                  return {
                    ...data,
                    typed: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }
              }}
            ></input>
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    if (data.used === data.typed) {
                      list.getData();
                      return data;
                    }
                    return {
                      ...data,
                      used: data.typed,
                    };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  setSearch((data) => {
                    return { typed: "", used: "" };
                  });
                  pageChange(1, paging.pageSize);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        {(props.access === SysModels.FullTemplateCardAccess.Admin ||
          props.access === SysModels.FullTemplateCardAccess.Contributor) && (
          <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={(e) => {
                setShowDialog(true);
                props.dialogOpen(true);
              }}
            >
              Associate {companySettings?.entityLabelSingular}
            </button>
          </div>
        )}
        <div className="flex-1"></div>
      </div>

      <div className="p-0" style={{ position: "relative" }}>
        {list.status === FetchStatus.InProgress && (
          <CommonSpinner message="Loading..."></CommonSpinner>
        )}
        {deleting && (
          <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
        )}
        {list.status !== FetchStatus.InProgress && (
          <>
            <div
              className="row mb-2 alert alert-light"
              style={{
                padding: "12px 0px",
                paddingBottom: "0",
                margin: "0",
                borderRadius: "5px",
              }}
            >
              {!list?.data?.associatedCardOutputDtos?.length && (
                <div className="pb-2 m-2">No Record(s) Found</div>
              )}
              {list?.data?.associatedCardOutputDtos?.map((card) => (
                <div
                  key={card.associatedCardId}
                  className="col-sm-12 col-md-4 pb-3"
                >
                  <div
                    className="card pointer h-100"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(`/cards/${card.associatedCardId}`, "_blank");
                    }}
                  >
                    <div className="card-body flex flex-column">
                      <label className="mb-2">{card.cardTemplateName}</label>
                      <div className="flex-1">
                        <table className="table table-sm table-bordered mb-0">
                          <tbody>
                            {card.fields
                              ?.sort(
                                commonService.sortByNumericProperty("sequence")
                              )
                              ?.map((fd) => (
                                <tr key={fd.sequence}>
                                  <td>
                                    <label>{fd.name}</label>
                                  </td>
                                  <td style={{ wordBreak: "break-all" }}>
                                    {commonService.isNullOrEmpty(
                                      fd.value || ""
                                    ) ? (
                                      <>
                                        <small className="chip-gray">
                                          No Data
                                        </small>
                                      </>
                                    ) : (
                                      <>
                                        {fd.fieldType ===
                                        SysModels.WF1FieldType.Date ? (
                                          <>
                                            {fd.value
                                              ? commonService.toDateString(
                                                  fd.value,
                                                  "ddd, MMM DD, YYYY"
                                                )
                                              : "-"}
                                          </>
                                        ) : (
                                          <>{fd.value}</>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            {/* <tr>
                                  <td>Participants</td>
                                  <td>{card.numberOfParticipants}</td>
                                </tr> */}
                          </tbody>
                        </table>
                      </div>
                      <div className="pt-2 flex flex-row flex-center">
                        <div className="flex-1 text-secondary">
                          {/* Participants: {card.numberOfParticipants} */}
                        </div>
                        <div className="flex flex-row gap-10 flex-center">
                          {(props.access ===
                            SysModels.FullTemplateCardAccess.Admin ||
                            props.access ===
                              SysModels.FullTemplateCardAccess.Contributor) && (
                            <i
                              className="fa fa-trash pointer text-danger"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowDelete(card.associatedCardId);
                                props.dialogOpen(true);
                              }}
                            ></i>
                          )}
                          <i
                            className="fa fa-external-link mt-1 pointer text-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              nav(`/cards/${card.associatedCardId}`);
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="hide-on-print">
              <Pagination
                length={list.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel={companySettings?.entityLabelPlural}
                sizes={[10, 15, 25, 50, 100]}
              ></Pagination>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default OtherCardsTab;
