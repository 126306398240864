import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import Pagination, { usePaging } from "../components/Pagination";
import CommonSpinner from "../components/CommonSpinner";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import SysModels from "../models";
import CellOneLineWordBreak from "../components/CellOneLineWordBreak";
import FormModal from "../components/FormModal";
import ConfirmDialog from "../components/ConfirmDialog";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import { useLastPageFilters } from "../stores/SystemStore";
import DateTimePicker from "../components/DateTimePicker";

function SystemErrors(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [secLvl, setSecLvl] = useState<SysModels.ErrorMaintenanceSeverityLevel>(
    SysModels.ErrorMaintenanceSeverityLevel.Warning
  );
  const [search, setSearch] = useState({
    typed: "",
    used: "",
    from: dayjs().add(-5, "day").format("YYYY-MM-DDT00:00:00"),
    to: dayjs().format("YYYY-MM-DDT23:59:59"),
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.systemError.list(paging.page, paging.pageSize, {
        severityLevel: secLvl,
        search: search.used,
        fromDate: search.from as any,
        toDate: search.to as any,
      }),
    "System Logs"
  );

  const levels = useFetchHelper(
    () =>
      SysServices.http.genericEnumLookup.get("ErrorMaintenanceSeverityLevel"),
    "Security Levels"
  );

  useEffect(() => {
    levels.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 50,
      search: "",
      others: {
        secLvl: SysModels.ErrorMaintenanceSeverityLevel.Warning,
      },
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
        setSearch((prev) => {
          return {
            ...prev,
            used: filters.search,
            typed: filters.search,
          };
        });
        setSecLvl(filters.others?.secLvl);
      }
    }
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: search.used,
          others: {
            secLvl: secLvl,
          },
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, secLvl, search.used, search.from, search.to, pageFilters.ready]);

  const [selErrors, setSelErrors] = useState<number[]>([]);
  const [showDetail, setShowDetail] =
    useState<SysModels.ErrorMaintenanceDetailOutputDto>();
  const [showDel, setShowDel] = useState<"all" | "selected" | number>();
  const getDeleteTitle = () => {
    if (showDel === "selected") {
      return `Delete Selected System Logs`;
    } else if (showDel === "all") {
      return "Delete All System Logs";
    }
    return "Deleted System Log";
  };
  const getDeleteMessage = () => {
    if (showDel === "selected") {
      return `Do you really want to delete selected (${selErrors.length}) System Logs?`;
    } else if (showDel === "all") {
      return "Do you really want to <strong>DELETE ALL</strong> system logs?";
    }
    return "Do you really want to delete this system log?";
  };
  const [spinMessage, setSpinMessage] = useState<string>();
  const [searchText, setSearchText] = useState("");

  return (
    <div>
      {!!spinMessage && (
        <CommonSpinner overlay={true} message={spinMessage}></CommonSpinner>
      )}

      <ConfirmDialog
        show={!!showDel}
        title={getDeleteTitle()}
        message={getDeleteMessage()}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            if (showDel === "selected" || typeof showDel === "number") {
              setSpinMessage("Deleting Selected System Logs...");
              SysServices.http.systemError
                .delete({
                  ids: typeof showDel === "number" ? [showDel] : selErrors,
                })
                .then((data) => {
                  toastStore.showToast(
                    "Selected System Logs Deleted",
                    "success"
                  );
                  list.getData();
                  setSelErrors([]);
                })
                .catch((err) => {
                  toastStore.showError(
                    "Failed Deleting Selected System Logs",
                    err
                  );
                })
                .finally(() => {
                  setSpinMessage(undefined);
                });
            } else if (showDel === "all") {
              setSpinMessage("Deleting All System Logs...");
              SysServices.http.systemError
                .deleteAll()
                .then((data) => {
                  toastStore.showToast("All System Logs Deleted", "success");
                  list.getData();
                  setSelErrors([]);
                })
                .catch((err) => {
                  toastStore.showError("Failed Deleting All System Logs", err);
                })
                .finally(() => {
                  setSpinMessage(undefined);
                });
            }
          }
          setShowDel(undefined);
        }}
      ></ConfirmDialog>

      {!!showDetail && (
        <FormModal
          isOpen={true}
          title={`Severity Level: ${showDetail?.severityLevel || ""}`}
          close={() => {
            setShowDetail(undefined);
          }}
          submit={(data) => {
            setShowDetail(undefined);
          }}
          primaryButtonOnly={true}
          submitButtonLabel="Done"
          size="xl"
        >
          <div className="mb-2">
            <strong>Date:</strong>{" "}
            {dayjs(showDetail.raiseDate).format("MMM DD, YYYY hh:mm A")}
          </div>
          <strong>Message</strong>
          <textarea
            className="form-control w-100 mb-3 mt-2"
            readOnly={true}
            rows={showDetail.message ? 8 : 3}
            value={showDetail.message}
          ></textarea>

          {!!showDetail.properties && (
            <>
              <strong>Properties</strong>
              <div className="text-select-container mt-2">
                {searchText && (
                  <section
                    className="pointer"
                    title="Search Selected Text"
                    onClick={(e) => {
                      if (!commonService.isNullOrEmpty(searchText)) {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: searchText,
                            used: searchText,
                          };
                        });
                        pageChange(1, paging.pageSize);
                        setSearchText("");
                        setSecLvl(0);
                        setShowDetail(undefined);
                      }
                    }}
                  >
                    Search <i className="fa fa-search"></i>
                  </section>
                )}
                <textarea
                  className="form-control w-100"
                  readOnly={true}
                  rows={12}
                  value={JSON.stringify(showDetail.properties, undefined, 2)}
                  onBlur={(e) => {
                    setTimeout(() => setSearchText(""), 500);
                  }}
                  onSelect={(e) => {
                    const sel = window.getSelection();
                    setSearchText(sel?.toString()?.trim() || "");
                  }}
                ></textarea>
              </div>
            </>
          )}
        </FormModal>
      )}

      <div className="mb-3">
        <h4>System Logs</h4>
      </div>

      {!pageFilters.ready && (
        <CommonSpinner message="Loading..."></CommonSpinner>
      )}

      {pageFilters.ready && (
        <>
          <div className="flex flex-wrap gap-10 mb-3">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div
                className="input-group search-box"
                style={{ maxWidth: "240px" }}
              >
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          list.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { ...data, typed: "", used: "" };
                      });
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="date-filter">
              <DateTimePicker
                dateOnly={true}
                data={search.from}
                onChange={(data) => {
                  setSearch((prev) => {
                    return {
                      ...prev,
                      from: data
                        ? dayjs(data).format("YYYY-MM-DDT00:00:00")
                        : (undefined as any),
                    };
                  });
                }}
              />
            </div>
            <div className="pt-2">to</div>
            <div className="date-filter">
              <DateTimePicker
                dateOnly={true}
                data={search.to}
                onChange={(data) => {
                  setSearch((prev) => {
                    return {
                      ...prev,
                      to: data
                        ? dayjs(data).format("YYYY-MM-DDT23:59:59")
                        : (undefined as any),
                    };
                  });
                }}
              />
            </div>
            <div className="flex-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  disabled={
                    levels.status === FetchStatus.InProgress ||
                    list.status === FetchStatus.InProgress
                  }
                >
                  <span className="me-2">
                    Severity Level -{" "}
                    {levels.data?.find((x) => x.value === secLvl)?.label ||
                      "..."}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {levels.data?.map((opt) => (
                    <Dropdown.Item
                      key={opt.value}
                      active={opt.value === secLvl}
                      onClick={(e) => {
                        setSecLvl(opt.value || 0);
                        pageChange(1, paging.pageSize);
                      }}
                    >
                      {opt.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="flex-1">
              <button
                className="btn btn-danger me-2 no-wrap"
                type="button"
                disabled={
                  selErrors.length === 0 ||
                  levels.status === FetchStatus.InProgress
                }
                onClick={(e) => {
                  setShowDel("selected");
                }}
              >
                Delete Selected ({selErrors.length})
              </button>
              <button
                className="btn btn-danger no-wrap me-2"
                type="button"
                disabled={levels.status === FetchStatus.InProgress}
                onClick={(e) => {
                  setShowDel("all");
                }}
              >
                Delete All
              </button>
            </div>
            <div className="flex-0">
              <button
                className="btn btn-primary no-wrap"
                type="button"
                disabled={levels.status === FetchStatus.InProgress}
                onClick={(e) => {
                  list.getData();
                }}
              >
                <i className="fa fa-refresh"></i>
              </button>
            </div>
          </div>

          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={
                          !!selErrors.length &&
                          selErrors.length === list.data?.errors?.length
                        }
                        onChange={(e) => {
                          if (
                            !!selErrors.length &&
                            selErrors.length === list.data?.errors?.length
                          ) {
                            setSelErrors([]);
                          } else {
                            setSelErrors(
                              (list.data?.errors || []).map((x) => x.id || 0)
                            );
                          }
                        }}
                      />
                    </th>
                    <th className="no-wrap">Severity Level</th>
                    <th style={{ width: "50%" }}>Message</th>
                    <th style={{ width: "50%" }}>Exception</th>
                    <th>Date</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!list.data?.totalRecords && (
                    <tr>
                      <td colSpan={6}>No Record(s) Found</td>
                    </tr>
                  )}
                  {list?.data?.errors?.map((item) => (
                    <tr
                      key={item.id}
                      className="pointer"
                      onClick={(e) => {
                        if (
                          !(e.target as HTMLElement).classList.contains(
                            "prevent-row-click"
                          )
                        ) {
                          setShowDetail(item);
                        }
                      }}
                    >
                      <td className="prevent-row-click">
                        <input
                          type="checkbox"
                          className="prevent-row-click me-2"
                          checked={!!selErrors.find((x) => x === item.id)}
                          onChange={(e) => {
                            if (!selErrors.find((x) => x === item.id)) {
                              setSelErrors((prev) => {
                                return [...prev, item.id || 0];
                              });
                            } else {
                              setSelErrors((prev) => {
                                return prev.filter((x) => x !== item.id);
                              });
                            }
                          }}
                        />
                      </td>
                      <td>
                        <span
                          className={`text-${item.severityLevel?.toLowerCase()}`}
                        >
                          {item.severityLevel}
                        </span>
                      </td>
                      <td>
                        <CellOneLineWordBreak text={item.message} />
                      </td>
                      <td>
                        <CellOneLineWordBreak text={item.exception} />
                      </td>
                      <td className="no-wrap">
                        {commonService.toLocalDate(item.raiseDate, "full")}
                      </td>
                      <td className="text-center prevent-row-click">
                        <i
                          className="fa fa-trash text-danger pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowDel(item.id);
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="System Logs"
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SystemErrors;
