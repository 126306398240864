import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import SwitchButton from "../components/SwitchButton";
import commonService from "../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import ActivityLogs from "../components/ActivityLogs";
import FileDragNDrop from "../components/FileDragNDrop";
import systemStore, { useLoadedTab } from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";

function Company(props: { admin?: boolean }) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<
    SysModels.CompanyCreateDto &
      SysModels.CompanyUpdateDto &
      SysModels.CompanyOutputDto &
      SysModels.CompanyAdminOutputDto
  >({
    companyName: "",
    companyAddress: {},
    active: true,
  });

  const timezones = useFetchHelper(SysServices.http.timeZone.get, "Timezones");
  const current = useFetchHelper(
    async () =>
      props.admin
        ? SysServices.http.company.getForCompanyAdmin()
        : SysServices.http.company.get(id || ""),
    "Company"
  );

  const validateForm = () => {
    const err = commonService.getFormErrors("companyForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (!validateForm()) return;

    setSaving(true);

    if (props.admin) {
      await SysServices.http.company
        .updateForAdmin({
          companyAddress: model.companyAddress,
        })
        .then((data) => {
          toastStore.showToast("Company Info Saved", "success");

          if (deleteFile) {
            SysServices.http.company
              .deleteCompanyLogo()
              .then((data) => {
                toastStore.showToast("Company Logo Deleted", "success");
                systemStore.setCompanyLogoUpdated();
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Company Logo", err);
              })
              .finally(() => {
                setSaving(false);
              });
          } else if (files.length) {
            SysServices.http.company
              .saveCompanyLogo(SysServices.http.fetcher.getFormData(files, {}))
              .then((data) => {
                toastStore.showToast("Company Logo Saved", "success");
                systemStore.setCompanyLogoUpdated();
              })
              .catch((err) => {
                toastStore.showError("Failed Saving Company Logo", err);
              })
              .finally(() => {
                setSaving(false);
              });
          } else {
            setSaving(false);
          }
        })
        .catch((err) => {
          setSaving(false);
          toastStore.showError("Failed Saving Company Info", err);
        });
      return;
    }

    if (id && id !== "new") {
      await SysServices.http.company
        .update(id, {
          ...model,
          allowFullTemplates:
            model.companySettingsDto?.allowFullTemplates || false,
          allowFileSystem: model.companySettingsDto?.allowFileSystem || false,
          fileSystemSize: model.companySettingsDto?.fileSystemSize,
        })
        .then((data) => {
          toastStore.showToast("Company Saved", "success");
          nav("/companies");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Company", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.company
        .create({
          ...model,
        })
        .then((data) => {
          toastStore.showToast("Company Saved", "success");
          nav("/companies");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Company", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (!props.admin) {
      timezones.getData();
    }
    if (props.admin || (id && id !== "new")) {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data as any);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/companies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showLoadData, setShowLoadData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const refTz = React.createRef<any>();

  const [deleteFile, setDeleteFile] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const tabs = useLoadedTab(["Detail"]);
  const [savingSettings, setSavingSetting] = useState(false);

  const [confirmDisableFullTemplates, setConfirmDisableFullTemplates] =
    useState(false);

  return (
    <div className="tabbed-page">
      <ConfirmDialog
        show={showDel}
        title="Delete Company"
        message="Do you really want to delete this Company?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.company
              .delete(id || "")
              .then((data) => {
                toastStore.showToast("Company Deleted", "success");
                nav("/companies");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Company", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={showLoadData}
        title="Load Data"
        message="Do you really want to load data to this Company?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setLoadingData(true);
            SysServices.http.company
              .loadData(id as any)
              .then((data) => {
                toastStore.showToast("Loading Data Stared", "success");
              })
              .catch((err) => {
                toastStore.showError("Failed Loading Data", err);
              })
              .finally(() => {
                setLoadingData(false);
              });
          }
          setShowLoadData(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={confirmDisableFullTemplates}
        title="Turn Off Full Templates"
        message={`Do you really want to turn off Full Templates for this company? <br/>
          Turning Off this feature will remove access from this company to Full Templates.`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "no") {
            setModel((prev) => {
              return {
                ...prev,
                allowFullTemplates: true,
                companySettingsDto: {
                  ...prev.companySettingsDto,
                  allowFullTemplates: true,
                },
              };
            });
          }
          setConfirmDisableFullTemplates(false);
        }}
      ></ConfirmDialog>

      <h4>
        {props.admin ? (
          <>Company Info</>
        ) : (
          <>{id === "new" ? "Add" : "Edit"} Company</>
        )}
      </h4>

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {current.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {loadingData && (
            <CommonSpinner
              message="Loading Data..."
              overlay={true}
            ></CommonSpinner>
          )}
          {(current.status === FetchStatus.Complete || id === "new") && (
            <div id="companyForm">
              <div className="row">
                <div className="pt-2 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                  <div className="row">
                    <div className="pt-2 col-sm-12 col-md-6">
                      <div className="mb-2">
                        <div className="mb-1">
                          <label className="required-label">Company Name</label>
                        </div>
                        <input
                          autoFocus={!props.admin}
                          autoComplete="new-password"
                          className="form-control required"
                          placeholder="Company Name"
                          value={model.companyName}
                          readOnly={props.admin}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyName: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label>Address 1</label>
                        </div>
                        <input
                          autoFocus={props.admin}
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="Address 1"
                          value={model.companyAddress?.address1}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  address1: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label>Address 2</label>
                        </div>
                        <input
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="Address 2"
                          value={model.companyAddress?.address2}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  address2: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label className="required-label">City</label>
                        </div>
                        <input
                          autoComplete="new-password"
                          className="form-control required"
                          placeholder="City"
                          value={model.companyAddress?.city}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  city: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label>State</label>
                        </div>
                        <input
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="State"
                          value={model.companyAddress?.state}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  state: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label>Postal Code</label>
                        </div>
                        <input
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="Postal Code"
                          value={model.companyAddress?.postalCode}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  postalCode: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <div className="mb-1">
                          <label>Country</label>
                        </div>
                        <input
                          autoComplete="new-password"
                          className="form-control"
                          placeholder="Country"
                          value={model.companyAddress?.country}
                          onChange={(e) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                companyAddress: {
                                  ...prev.companyAddress,
                                  country: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="mb-1">
                          <label className="required-label">Timezone</label>
                        </div>
                        {!props.admin && (
                          <div className="mb-2">
                            <AsyncTypeahead
                              id="typeahead-search-timezone"
                              labelKey="label"
                              onSearch={() => {}}
                              onChange={(data) => {
                                if (data.length > 0) {
                                  const item = data[0] as any;
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      timeZone: item.label,
                                    };
                                  });
                                  (refTz.current as any)?.clear();
                                }
                              }}
                              searchText={"Searching..."}
                              isLoading={false}
                              options={timezones.data || []}
                              placeholder="Search Timezone"
                              minLength={0}
                              delay={500}
                              useCache={true}
                              //filterBy={() => true}
                              ref={refTz}
                            />
                            <HiddenInputRequiredRef
                              value={model.timeZone}
                              placeholder="Timezone"
                              onFocus={() => refTz.current?.focus()}
                            />
                          </div>
                        )}
                        {model.timeZone && (
                          <div className="alert alert-sm alert-secondary p-2">
                            {model.timeZone}
                          </div>
                        )}
                      </div>

                      {id === "new" && (
                        <>
                          <div className="mb-1">
                            <label>Main Contact's Detail</label>
                          </div>
                          <div className="alert alert-sm alert-light">
                            <div className="mb-2">
                              <div className="mb-1">
                                <label className="required-label">Name</label>
                              </div>
                              <input
                                autoComplete="new-password"
                                className="form-control required"
                                placeholder="Main Contact Name"
                                value={model.companyAddress?.mainContactName}
                                onChange={(e) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      companyAddress: {
                                        ...prev.companyAddress,
                                        mainContactName: e.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                            <div className="mb-2">
                              <div className="mb-1">
                                <label className="required-label">Email</label>
                              </div>
                              <input
                                autoComplete="new-password"
                                className="form-control required"
                                placeholder="Main Contact Email"
                                type="email"
                                value={model.companyAddress?.mainContactEmail}
                                onChange={(e) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      companyAddress: {
                                        ...prev.companyAddress,
                                        mainContactEmail: e.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                            <div className="mb-2">
                              <div className="mb-1">
                                <label>Phone</label>
                              </div>
                              <input
                                autoComplete="new-password"
                                className="form-control"
                                placeholder="Main Contact Phone"
                                value={model.companyAddress?.mainContactPhone}
                                onChange={(e) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      companyAddress: {
                                        ...prev.companyAddress,
                                        mainContactPhone: e.target.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="pt-2 mb-2">
                            <div className="mb-1">
                              <SwitchButton
                                uncheckedLabel="Allow Full Templates"
                                checked={
                                  model.companySettingsDto
                                    ?.allowFullTemplates || false
                                }
                                onChange={(val) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      allowFullTemplates: val || false,
                                      companySettingsDto: {
                                        ...prev.companySettingsDto,
                                        allowFullTemplates: val || false,
                                      },
                                    };
                                  });
                                }}
                              ></SwitchButton>
                            </div>
                          </div>
                          <div className="pt-2 mb-2">
                            <div className="mb-1">
                              <SwitchButton
                                uncheckedLabel="Allow File System"
                                checked={
                                  model.companySettingsDto?.allowFileSystem ||
                                  false
                                }
                                onChange={(val) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      allowFileSystem: val || false,
                                      companySettingsDto: {
                                        ...prev.companySettingsDto,
                                        allowFileSystem: val || false,
                                      },
                                    };
                                  });
                                }}
                              ></SwitchButton>
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <label>File System Size</label>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="File System Size"
                                value={model.companySettingsDto?.fileSystemSize}
                                style={{ width: "50%" }}
                                onChange={(e) => {
                                  const val = commonService.isNullOrWhitespace(
                                    e.target.value
                                  )
                                    ? (null as any)
                                    : Number(e.target.value);
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      fileSystemSize: val,
                                      companySettingsDto: {
                                        ...prev.companySettingsDto,
                                        fileSystemSize: val,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="pt-2 mb-2">
                            <div className="mb-1">
                              <SwitchButton
                                uncheckedLabel="Active"
                                checked={model.active || false}
                                onChange={(val) => {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      active: val || false,
                                    };
                                  });
                                }}
                              ></SwitchButton>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {id !== "new" && (
                      <div className="pt-2 col-sm-12 col-md-6">
                        <div className="mb-1">
                          <label>Main Contact's Detail</label>
                        </div>
                        <div className="alert alert-sm alert-light">
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">Name</label>
                            </div>
                            <input
                              autoComplete="new-password"
                              className="form-control required"
                              placeholder="Main Contact Name"
                              value={model.companyAddress?.mainContactName}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    companyAddress: {
                                      ...prev.companyAddress,
                                      mainContactName: e.target.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">Email</label>
                            </div>
                            <input
                              autoComplete="new-password"
                              className="form-control required"
                              placeholder="Main Contact Email"
                              type="email"
                              value={model.companyAddress?.mainContactEmail}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    companyAddress: {
                                      ...prev.companyAddress,
                                      mainContactEmail: e.target.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label>Phone</label>
                            </div>
                            <input
                              autoComplete="new-password"
                              className="form-control"
                              placeholder="Main Contact Phone"
                              value={model.companyAddress?.mainContactPhone}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    companyAddress: {
                                      ...prev.companyAddress,
                                      mainContactPhone: e.target.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        {props.admin && (
                          <div>
                            <div className="mb-2">
                              <label>Company Logo</label>
                            </div>
                            {!deleteFile && model.companyLogo && (
                              <div className="bio-photo-preview text-center p-2 mb-3">
                                <img
                                  alt="User"
                                  style={{ maxHeight: "150px" }}
                                  src={model.companyLogo}
                                ></img>
                                <i
                                  title="Delete Profile Picture"
                                  className="fa fa-trash"
                                  onClick={(e) => {
                                    setDeleteFile(true);
                                    setFiles([]);
                                  }}
                                ></i>
                              </div>
                            )}
                            <FileDragNDrop
                              label="Add File"
                              disabled={saving}
                              onChange={(fileList) => {
                                if (fileList.length > 1) {
                                  toastStore.showToast(
                                    "Please select one file only.",
                                    "warning"
                                  );
                                  return;
                                }
                                if (fileList.length > 0) {
                                  const list = [];
                                  let cnt = 0;
                                  while (cnt < fileList.length) {
                                    const file = fileList[cnt];

                                    if (
                                      model.maxFileSize &&
                                      file.size > model.maxFileSize
                                    ) {
                                      toastStore.showToast(
                                        `File size must not exceed ${commonService.toNumberWithComma(
                                          model.maxFileSize,
                                          0
                                        )} bytes`,
                                        "warning"
                                      );
                                      return;
                                    }

                                    list.push(file);
                                    cnt++;

                                    var reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = (e) => {
                                      setModel((prev) => {
                                        return {
                                          ...prev,
                                          companyLogo: e.target?.result as any,
                                        };
                                      });
                                    };
                                  }
                                  setFiles(list);
                                  setDeleteFile(false);
                                }
                              }}
                              accepts={{
                                extensions: "JPG, JPEG, PNG, GIF",
                                pattern:
                                  /^.*\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/,
                              }}
                            />
                          </div>
                        )}

                        {!props.admin && (
                          <>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <SwitchButton
                                  uncheckedLabel="Allow Full Templates"
                                  checked={
                                    model.companySettingsDto
                                      ?.allowFullTemplates || false
                                  }
                                  onChange={(val) => {
                                    if (
                                      model.companySettingsDto
                                        ?.allowFullTemplates &&
                                      (model.numberOfFullTemplates || 0) > 0
                                    ) {
                                      setConfirmDisableFullTemplates(true);
                                    }
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        allowFullTemplates: val || false,
                                        companySettingsDto: {
                                          ...prev.companySettingsDto,
                                          allowFullTemplates: val || false,
                                        },
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <SwitchButton
                                  uncheckedLabel="Allow File System"
                                  checked={
                                    model.companySettingsDto?.allowFileSystem ||
                                    false
                                  }
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        allowFileSystem: val || false,
                                        companySettingsDto: {
                                          ...prev.companySettingsDto,
                                          allowFileSystem: val || false,
                                        },
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                              <div className="pt-2 mb-2">
                                <div className="mb-1">
                                  <label>File System Size</label>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="File System Size"
                                  value={
                                    model.companySettingsDto?.fileSystemSize
                                  }
                                  readOnly={
                                    props.admin ||
                                    !model.companySettingsDto?.allowFileSystem
                                  }
                                  style={{ width: "50%" }}
                                  onChange={(e) => {
                                    const val =
                                      commonService.isNullOrWhitespace(
                                        e.target.value
                                      )
                                        ? (null as any)
                                        : Number(e.target.value);
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        fileSystemSize: val,
                                        companySettingsDto: {
                                          ...prev.companySettingsDto,
                                          fileSystemSize: val,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <SwitchButton
                                  uncheckedLabel="Active"
                                  checked={model.active || false}
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        active: val || false,
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <SwitchButton
                                  uncheckedLabel="Archived"
                                  checked={model.archived || false}
                                  onChange={(val) => {
                                    setModel((prev) => {
                                      return {
                                        ...prev,
                                        archived: val || false,
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="mb-1">
                                <hr />
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success"
                                  onClick={(e) => {
                                    setShowLoadData(true);
                                  }}
                                  disabled={loadingData}
                                >
                                  {loadingData
                                    ? "Loading Data..."
                                    : "Load Data"}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    {id === "new" && (
                      <div className="pt-2 col-sm-12 col-md-6">
                        <div className="mb-1">
                          <label>Admin's Detail</label>
                        </div>
                        <div className="alert alert-sm alert-light">
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">
                                First Name
                              </label>
                            </div>
                            <input
                              className="form-control required"
                              placeholder="First Name"
                              value={model.firstName}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    firstName: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">
                                Last Name
                              </label>
                            </div>
                            <input
                              className="form-control required"
                              placeholder="Last Name"
                              value={model.lastName}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    lastName: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">Email</label>
                            </div>
                            <input
                              className="form-control required"
                              placeholder="Email"
                              value={model.email}
                              type="email"
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    email: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">Username</label>
                            </div>
                            <input
                              className="form-control required"
                              placeholder="Username"
                              value={model.userName}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    userName: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="mb-1">
                              <label className="required-label">Password</label>
                            </div>
                            <input
                              className="form-control required"
                              placeholder="Password"
                              type="text"
                              value={model.password}
                              onChange={(e) => {
                                setModel((prev) => {
                                  return {
                                    ...prev,
                                    password: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="pt-4">
                        <button
                          className="btn btn-sm btn-primary float-right"
                          type="button"
                          onClick={(e) => {
                            save();
                          }}
                          disabled={saving || deleting}
                        >
                          {saving ? "Saving..." : "Submit"}
                        </button>

                        {!props.admin && (
                          <button
                            className="btn btn-sm btn-secondary me-2 float-right"
                            type="button"
                            onClick={(e) => {
                              nav("/companies");
                            }}
                            disabled={saving || deleting}
                          >
                            Cancel
                          </button>
                        )}

                        {id !== "new" && !props.admin && (
                          <button
                            className="btn btn-sm btn-danger"
                            type="button"
                            onClick={(e) => {
                              setShowDel(true);
                            }}
                            disabled={saving || deleting}
                          >
                            {deleting ? "Deleting..." : "Delete"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Tab>
        {id !== "new" && (
          <Tab eventKey="Settings" title="Settings">
            <div className="pt-3 col-sm-12">
              {tabs.loadedTabs.includes("Settings") && (
                <div className="row">
                  <div className="pt-2 col col-sm-12 col-md-6 col-lg-4">
                    <div className="mb-1">
                      <label>Entity Label Singular</label>
                    </div>
                    <input
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="Entity Label Singular"
                      value={model.companySettingsDto?.entityLabelSingular}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            companySettingsDto: {
                              ...prev.companySettingsDto,
                              entityLabelSingular: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={!props.admin}
                    />
                    <div className="mb-1 mt-2">
                      <label>Entity Label Plural</label>
                    </div>
                    <input
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="Entity Label Plural"
                      value={model.companySettingsDto?.entityLabelPlural}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            companySettingsDto: {
                              ...prev.companySettingsDto,
                              entityLabelPlural: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={!props.admin}
                    />

                    <hr className="mt-4" />

                    <div className="mb-1 mt-2">
                      <label>User Label Singular</label>
                    </div>
                    <input
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="User Label Singular"
                      value={model.companySettingsDto?.userLabelSingular}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            companySettingsDto: {
                              ...prev.companySettingsDto,
                              userLabelSingular: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={!props.admin}
                    />
                    <div className="mb-1 mt-2">
                      <label>User Label Plural</label>
                    </div>
                    <input
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="User Label Plural"
                      value={model.companySettingsDto?.userLabelPlural}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            companySettingsDto: {
                              ...prev.companySettingsDto,
                              userLabelPlural: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={!props.admin}
                    />

                    <hr className="mt-4" />

                    <div className="pt-2 mb-2">
                      <div className="mb-1">
                        <SwitchButton
                          uncheckedLabel="Allow Comment Edits/Deletes"
                          checked={
                            model.companySettingsDto?.allowCommentEditDeletes ||
                            false
                          }
                          disabled={!props.admin}
                          onChange={(val) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                allowCommentEditDeletes: val || false,
                                companySettingsDto: {
                                  ...prev.companySettingsDto,
                                  allowCommentEditDeletes: val || false,
                                },
                              };
                            });
                          }}
                        ></SwitchButton>
                      </div>
                    </div>
                    <div className="mb-1 mt-3">
                      <label>
                        Time Limit to Edit/Delete Comments (in Minutes)
                      </label>
                    </div>
                    <input
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="User Label Plural"
                      value={model.companySettingsDto?.editTimeLimit}
                      type="number"
                      step={1}
                      max={10}
                      maxLength={2}
                      onChange={(e) => {
                        setModel((prev) => {
                          return {
                            ...prev,
                            companySettingsDto: {
                              ...prev.companySettingsDto,
                              editTimeLimit: commonService.isNullOrEmpty(
                                e.target.value
                              )
                                ? (null as any)
                                : Number(e.target.value),
                            },
                          };
                        });
                      }}
                      disabled={
                        !props.admin ||
                        !model.companySettingsDto?.allowCommentEditDeletes
                      }
                    />

                    {!!props.admin && (
                      <div className="mt-4 text-right">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary px-3"
                          disabled={savingSettings}
                          onClick={(e) => {
                            if (model.companySettingsDto) {
                              if (
                                (model.companySettingsDto.editTimeLimit || 0) >
                                10
                              ) {
                                toastStore.showToast(
                                  "Maximum time limit allowed is 10 minutes",
                                  "warning"
                                );
                                return;
                              }

                              setSavingSetting(true);
                              SysServices.http.company
                                .updateSettingsForCompanyAdmin(
                                  model.companySettingsDto
                                )
                                .then((data) => {
                                  systemStore.setCompanySettings(
                                    data.companySettingsDto
                                  );
                                  systemStore.setCompanyLogoUpdated();
                                  toastStore.showToast(
                                    "Company Settings Saved",
                                    "success"
                                  );
                                })
                                .catch((err) => {
                                  toastStore.showError(
                                    "Failed Saving Company Settings",
                                    err
                                  );
                                })
                                .finally(() => {
                                  setSavingSetting(false);
                                });
                            }
                          }}
                        >
                          {savingSettings ? "Saving..." : "Save"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Tab>
        )}
        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-3 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <ActivityLogs
                  type={SysModels.LogObjectTypeEnum.Company}
                  stringId={id}
                  forCompanyAdmin={props.admin}
                  forTab={true}
                ></ActivityLogs>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Company;
