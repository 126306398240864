import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityLinkService {
  get = async (id: string) => {
    const url = `/CardLink/${id}`;
    return fetcher.get<SysModels.CardLinkOutputDto>(url);
  };

  create = async (model: SysModels.CardLinkInputDto) => {
    const url = `/CardLink`;
    return fetcher.post<SysModels.CardLinkOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.CardLinkUpdateDto) => {
    const url = `/CardLink/${id}`;
    return fetcher.put<SysModels.CardLinkOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/CardLink/${id}`;
    return fetcher.delete<any>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.CardLinkSearchDto
  ) => {
    const url = `/CardLink/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardLinkGridOutputDto>(url, model);
  };
}

const entityLink = new EntityLinkService();
export default entityLink;
