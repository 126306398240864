import SysModels from "../../models";
import fetcher from "../Fetcher";

class EntityService {
  get = async (id: string) => {
    const url = `/Card/${id}`;
    return fetcher.get<SysModels.CardOutputDto>(url);
  };

  getEntityReminderManager = async (id: string) => {
    const url = `/Card/CardReminderManager/${id}`;
    return fetcher.get<SysModels.CardOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    model: SysModels.CardSearchInputDto
  ) => {
    const url = `/Card/List/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardGridOutputDto>(url, model);
  };

  listReminderManager = async (
    page: number,
    pageSize: number,
    model: SysModels.CardSearchInputDto
  ) => {
    const url = `/Card/ListReminderManager/${page}/${pageSize}`;
    return fetcher.post<SysModels.CardGridOutputDto>(url, model);
  };

  create = async (model: SysModels.CardInputDto) => {
    const url = `/Card`;
    return fetcher.post<SysModels.CardOutputDto>(url, model);
  };

  update = async (id: string, model: SysModels.CardUpdateDto) => {
    const url = `/Card/${id}`;
    return fetcher.put<SysModels.CardOutputDto>(url, model);
  };

  delete = async (id: string) => {
    const url = `/Card/${id}`;
    return fetcher.delete<any>(url);
  };
}

const entity = new EntityService();
export default entity;
