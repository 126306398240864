import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import DateTimePicker from "../components/DateTimePicker";
import toastStore from "../stores/ToastStore";
import commonService from "../services/CommonService";
import ConfirmDialog from "../components/ConfirmDialog";
import HiddenInputRequiredRef from "../components/HiddenInputRequiredRef";
import ActivityLogs from "../components/ActivityLogs";
import CommonSpinner from "../components/CommonSpinner";
import { useCompanySettings, useLoadedTab } from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";
import NotificationsTab from "../components/NotificationsTab";
import ParticipantsTab from "../tabs/ParticipantsTab";
import CommentsTab from "../tabs/CommentsTab";
import LinksTab from "../tabs/LinksTab";
import FilesTab from "../tabs/FilesTab";
import OtherCardsTab from "../tabs/OtherCardsTab";

function Card(props: {
  viewOnly?: boolean;
  fullTemplate?: boolean;
  idForDialog?: string;
  templateIdForDialog?: string;
  closeDialog?: (changed?: boolean) => void;
  modalOpened?: (open: boolean) => void;
  onSomethingSaved?: () => void;
}) {
  const nav = useNavigate();
  const params = useParams(); //{ id, template };
  const [id] = useState(props.idForDialog || params.id);
  const [template] = useState(props.templateIdForDialog || params.template);

  const companySettings = useCompanySettings();

  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<
    SysModels.CardInputDto & {
      userFullName?: string;
      participants?: SysModels.FullTemplateCardParticipantDto[];
    }
  >({
    fields: [],
  });

  const entityTemplate = useFetchHelper(
    () =>
      SysServices.http.cardTemplate.getEntityTemplateForEntity(template || ""),
    "Template"
  );

  const accessTypes = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("FullTemplateCardAccess"),
    "Access Types"
  );

  const entity = useFetchHelper(() => {
    let prom: Promise<
      SysModels.FullTemplateCardOutputDto & SysModels.CardOutputDto
    >;
    if (props.fullTemplate) {
      prom = SysServices.http.fullTemplateCard.get(id || "");
    } else {
      prom = (
        props.viewOnly
          ? SysServices.http.card.get
          : SysServices.http.card.getEntityReminderManager
      )(id || "");
    }
    return prom;
  }, companySettings?.entityLabelSingular || "Entity");

  useEffect(() => {
    if (template) {
      entityTemplate.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (props.fullTemplate) {
      accessTypes.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fullTemplate]);

  useEffect(() => {
    if (!entity.data?.id && id && id !== "new") {
      entity.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (entity.status === FetchStatus.Complete && entity.data) {
      setModel({
        ...entity.data,
        userFullName: `${entity.data.lastName || ""}, ${
          entity.data.firstName || ""
        }`,
      });

      entityTemplate.setData({
        ...entity.data,
        fields: entity.data.fields?.map((f) => {
          return {
            ...f,
            id: f.cardFieldId,
            sequence: f.sequence,
          };
        }),
      });
    }
    if (entity.status === FetchStatus.Failed) {
      props.closeDialog?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.status]);

  useEffect(() => {
    if (props.fullTemplate && entityTemplate.data?.fields?.length) {
      const fld = entityTemplate.data?.fields?.sort(
        commonService.sortByNumericProperty("sequence")
      )[0];
      if (fld?.id) {
        setTimeout(() => {
          document
            .getElementById(`field${fld.id}`)
            ?.querySelector("input")
            ?.focus();
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityTemplate.data]);

  const setFieldValue = (fieldId: any, value: any) => {
    setModel((prev) => {
      return {
        ...prev,
        fields: (prev.fields || []).find((f) => f.cardFieldId === fieldId)
          ? [
              ...(prev.fields || []).map((f) => {
                if (f.cardFieldId === fieldId) {
                  return {
                    ...f,
                    value: value,
                  };
                }
                return f;
              }),
            ]
          : [
              ...(prev.fields || []),
              {
                cardFieldId: fieldId,
                value: value,
              },
            ],
      };
    });
  };

  const getFiedlValues = (fieldId: any) => {
    return (model.fields || []).find((f) => f.cardFieldId === fieldId)?.value;
  };

  const getFieldValueList = () => {
    if (entityTemplate.data?.fields?.length) {
      return entityTemplate.data.fields.map((f) => {
        const val = getFiedlValues(f.id);
        return {
          cardFieldId: f.id,
          value: val === undefined ? (null as any) : val,
        };
      });
    }
    return model.fields?.map((f) => {
      const val = getFiedlValues(f.cardFieldId);
      return {
        cardFieldId: f.cardFieldId,
        value: val === undefined ? (null as any) : val,
      };
    });
  };

  const validateForm = () => {
    const err = commonService.getFormErrors("entityForm");
    if (err.length) {
      toastStore.showToast(err[0], "warning");
    }
    return err.length === 0;
  };

  const save = () => {
    if (!validateForm()) return;

    setSaving(true);
    if (entity.data?.id) {
      (props.fullTemplate
        ? SysServices.http.fullTemplateCard.update(entity.data?.id, {
            ...model,
            fields: getFieldValueList(),
          })
        : SysServices.http.card.update(entity.data?.id, {
            ...model,
            fields: getFieldValueList(),
          })
      )
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          entity.setDataAndComplete(data);
          somethingChanged();
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      (props.fullTemplate
        ? SysServices.http.fullTemplateCard.create({
            ...model,
            cardTemplateId: entityTemplate.data?.id,
            fields: getFieldValueList(),
          })
        : SysServices.http.card.create({
            ...model,
            cardTemplateId: entityTemplate.data?.id,
            fields: getFieldValueList(),
          })
      )
        .then((data) => {
          toastStore.showToast(
            `${companySettings?.entityLabelSingular} Saved.`,
            "success"
          );
          somethingChanged();
          if (data.id) {
            entity.setDataAndComplete(data);
            if (props.fullTemplate) {
              if (props.idForDialog || props.templateIdForDialog) {
                props.closeDialog?.(true);
              } else {
                nav(`/cards/${data.id}`, { replace: true });
              }
            } else {
              nav(`/reminder-cards/${data.id}`, { replace: true });
            }
          }
        })
        .catch((err) => {
          toastStore.showError(
            `Failed Saving ${companySettings?.entityLabelSingular}`,
            err
          );
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (props.modalOpened) {
      props.modalOpened(showDel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDel]);

  const tabs = useLoadedTab(["Detail"]);

  const readOnly = useMemo(() => {
    if (
      entity.data &&
      (entity.status === FetchStatus.Complete ||
        entity.status === FetchStatus.InProgress) &&
      (entity.data.currentUserAccess ===
        SysModels.FullTemplateCardAccess.Contributor ||
        entity.data.currentUserAccess ===
          SysModels.FullTemplateCardAccess.Reader ||
        entity.data.currentUserAccess ===
          SysModels.FullTemplateCardAccess.NoAccess)
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.status, entity.data]);

  const [refreshRecentAct, setRefreshRecentAct] = useState(
    commonService.getUniqueId()
  );

  const somethingChanged = () => {
    props.onSomethingSaved?.();
    setRefreshRecentAct(commonService.getUniqueId());
  };

  return (
    <div className="tabbed-page">
      {!props.idForDialog && !props.templateIdForDialog && (
        <h4>
          {entity.data?.id ? (props.viewOnly ? "View" : "Edit") : "Add"}{" "}
          {companySettings?.entityLabelSingular}:{" "}
          {entityTemplate.data?.name || entity.data?.cardTemplateName}
        </h4>
      )}

      <ConfirmDialog
        show={showDel}
        title={`Delete ${companySettings?.entityLabelSingular}`}
        message={`Do you really want to delete this ${companySettings?.entityLabelSingular}?`}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            (props.fullTemplate
              ? SysServices.http.fullTemplateCard.delete(id || "")
              : SysServices.http.card.delete(id || "")
            )
              .then((data) => {
                toastStore.showToast(
                  `${companySettings?.entityLabelSingular} Deleted`,
                  "success"
                );

                if (props.fullTemplate) {
                  nav("/cards");
                  props.closeDialog?.(true);
                } else {
                  nav("/reminder-cards");
                }
              })
              .catch((err) => {
                toastStore.showError(
                  `Failed Deleting ${companySettings?.entityLabelSingular}`,
                  err
                );
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      {deleting && (
        <CommonSpinner message="Deleting..." overlay={true}></CommonSpinner>
      )}
      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          <div id="entityForm">
            {entity.status === FetchStatus.InProgress && (
              <CommonSpinner message="Loading..."></CommonSpinner>
            )}
            <div
              className={`row ${
                entity.status === FetchStatus.InProgress ? "display-none" : ""
              }`}
            >
              <div
                className={
                  props.fullTemplate ? `col-sm-12 col-md-6 col-lg-8` : "row"
                }
                style={{
                  order: props.fullTemplate ? 1 : 0,
                }}
              >
                <div
                  className={`pt-2 mb-2 ${
                    props.fullTemplate ? "" : "col-sm-12 col-md-6 col-lg-4 pe-0"
                  }`}
                >
                  <div className="mb-1">
                    <label>
                      {props.fullTemplate
                        ? entity.data?.id
                          ? ""
                          : "Participants"
                        : "User"}
                    </label>
                  </div>
                  <div>
                    {(!id || (!props.fullTemplate && id)) && (
                      <>
                        <ParticipantsTab
                          key={`${entity.data?.id}-${model.userId}`}
                          fullTemplate={props.fullTemplate || false}
                          isNew={!id}
                          model={{
                            userFullName: model.userFullName,
                            userId: model.userId,
                            participants: model.participants,
                          }}
                          access={SysModels.FullTemplateCardAccess.Admin}
                          onChange={(data) => {
                            setModel((prev) => {
                              return {
                                ...prev,
                                ...data,
                              };
                            });
                          }}
                          onSaved={() => somethingChanged()}
                        ></ParticipantsTab>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`col-sm-12 col-md-6 ${
                  props.idForDialog ? "" : "col-lg-4"
                }`}
                style={{
                  order: 0,
                }}
              >
                <div>
                  {props.idForDialog && (
                    <h5>
                      {entityTemplate.data?.name ||
                        entity.data?.cardTemplateName}
                    </h5>
                  )}
                </div>

                <div className="pt-2">
                  {entityTemplate.data?.fields
                    ?.sort(commonService.sortByNumericProperty("sequence"))
                    ?.map((field) => (
                      <div
                        className="mb-2"
                        key={field.id}
                        id={`field${field.id}`}
                      >
                        <div className="mb-1">
                          <label
                            className={
                              field.isFieldRequired ? "required-label" : ""
                            }
                          >
                            {field.name}
                          </label>
                        </div>
                        <div className="input-with-help-icon">
                          {!commonService.isNullOrEmpty(
                            field.description || ""
                          ) && (
                            <i
                              className="fa fa-info-circle text-primary pointer"
                              title={field.description}
                            ></i>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Date && (
                            <>
                              <div>
                                <DateTimePicker
                                  dateOnly={true}
                                  data={getFiedlValues(field.id)}
                                  onChange={(data) => {
                                    setFieldValue(
                                      field.id,
                                      data || (null as any)
                                    );
                                  }}
                                  disabled={props.viewOnly || readOnly}
                                />
                              </div>
                              {field.isFieldRequired && (
                                <HiddenInputRequiredRef
                                  value={getFiedlValues(field.id)}
                                  placeholder={field.name || ""}
                                  onFocus={() => {
                                    document
                                      .getElementById(`field${field.id}`)
                                      ?.querySelector("input")
                                      ?.focus();
                                  }}
                                />
                              )}
                            </>
                          )}
                          {field.fieldType === SysModels.WF1FieldType.Text && (
                            <input
                              type="text"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly || readOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.TextArea && (
                            <textarea
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              rows={3}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly || readOnly}
                            />
                          )}
                          {field.fieldType ===
                            SysModels.WF1FieldType.Number && (
                            <input
                              type="number"
                              className={`form-control ${
                                field.isFieldRequired ? "required" : ""
                              }`}
                              placeholder={field.name}
                              value={getFiedlValues(field.id)}
                              onChange={(e) => {
                                setFieldValue(field.id, e.target.value);
                              }}
                              readOnly={props.viewOnly || readOnly}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pt-2">
                  {!props.viewOnly && !readOnly && (
                    <>
                      {entity.data?.id && (
                        <button
                          className={`btn btn-sm btn-danger ${
                            props.viewOnly ? "" : "me-2"
                          }`}
                          type="button"
                          onClick={(e) => {
                            setShowDel(true);
                          }}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        className="btn btn-sm btn-primary float-right"
                        type="button"
                        onClick={(e) => {
                          save();
                        }}
                        disabled={saving}
                      >
                        {saving ? "Saving..." : "Submit"}
                      </button>
                    </>
                  )}
                  <button
                    className={`btn btn-sm btn-secondary float-right ${
                      !!(props.viewOnly || readOnly) ? "" : "me-2"
                    }`}
                    type="button"
                    onClick={(e) => {
                      if (props.idForDialog || props.templateIdForDialog) {
                        props.closeDialog?.();
                        return;
                      }
                      if (props.viewOnly) {
                        nav("/view-reminders");
                      } else {
                        if (props.fullTemplate) {
                          nav("/cards");
                        } else {
                          nav("/reminder-cards");
                        }
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              {!!entity.data?.id && props.fullTemplate && (
                <div
                  className={`col-sm-12 col-md-6 ${
                    props.idForDialog ? "" : "col-lg-8"
                  }`}
                >
                  <div className="flex flex-row flex-center mb-3">
                    <h5 className="flex-1 mb-0">Recent Activities</h5>
                    <div>
                      <i
                        className="fa fa-refresh text-primary pointer"
                        onClick={(e) => {
                          setRefreshRecentAct(commonService.getUniqueId());
                        }}
                      ></i>
                    </div>
                  </div>
                  <div key={refreshRecentAct}>
                    <ActivityLogs
                      type={SysModels.LogObjectTypeEnum.Card}
                      stringId={entity.data?.id}
                      forTab={true}
                      recentOnly={true}
                    ></ActivityLogs>
                  </div>
                  <div>
                    <span
                      className="text-primary pointer"
                      onClick={(e) => {
                        tabs.setActiveTab("Logs");
                      }}
                    >
                      <strong>View All</strong>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Tab>
        {!!entity.data?.id && !props.fullTemplate && (
          <Tab eventKey="Notifications" title="Notifications">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Notifications") && (
                <NotificationsTab
                  list={entity.data?.notificationOutputDetailDtos || []}
                ></NotificationsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Comments" title="Comments">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Comments") && (
                <CommentsTab
                  cardId={entity.data.id}
                  access={
                    entity.data?.currentUserAccess ||
                    SysModels.FullTemplateCardAccess.NoAccess
                  }
                  onDialogOpen={(open) => {
                    props.modalOpened?.(open);
                  }}
                  onSaved={() => somethingChanged()}
                ></CommentsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && (
          <Tab eventKey="Participants" title="Participants">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Participants") && (
                <ParticipantsTab
                  fullTemplate={props.fullTemplate || false}
                  isNew={!id}
                  cardId={id}
                  tabMode={true}
                  model={{
                    userFullName: model.userFullName,
                    userId: model.userId,
                    participants: model.participants,
                  }}
                  access={entity.data.currentUserAccess}
                  onChange={(data) => {}}
                  onSaved={() => somethingChanged()}
                ></ParticipantsTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && entity.data.showFiles && (
          <Tab eventKey="Files" title="Files">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Files") && (
                <FilesTab
                  cardId={entity.data?.id}
                  access={
                    entity.data?.currentUserAccess ||
                    SysModels.FullTemplateCardAccess.NoAccess
                  }
                  dialogOpen={(open) => {
                    props?.modalOpened?.(open);
                  }}
                  onSaved={() => somethingChanged()}
                ></FilesTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id && props.fullTemplate && entity.data.showLinks && (
          <Tab eventKey="Links" title="Links">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Links") && (
                <LinksTab
                  cardId={entity.data?.id}
                  access={
                    entity.data?.currentUserAccess ||
                    SysModels.FullTemplateCardAccess.NoAccess
                  }
                  dialogOpen={(open) => {
                    props?.modalOpened?.(open);
                  }}
                  onSaved={() => somethingChanged()}
                ></LinksTab>
              )}
            </div>
          </Tab>
        )}
        {!!entity.data?.id &&
          props.fullTemplate &&
          entity.data.showAssociatedCards && (
            <Tab
              eventKey="OtherCards"
              title={`Other ${companySettings?.entityLabelPlural}`}
            >
              <div className="pt-2 col-sm-12">
                {tabs.loadedTabs.includes("OtherCards") && (
                  <OtherCardsTab
                    cardId={entity.data?.id}
                    access={
                      entity.data?.currentUserAccess ||
                      SysModels.FullTemplateCardAccess.NoAccess
                    }
                    dialogOpen={(open) => {
                      props?.modalOpened?.(open);
                    }}
                    onSaved={() => somethingChanged()}
                  ></OtherCardsTab>
                )}
              </div>
            </Tab>
          )}
        {!!entity.data?.id && (
          <Tab eventKey="Logs" title="Logs">
            <div className="pt-2 col-sm-12">
              {tabs.loadedTabs.includes("Logs") && (
                <div>
                  <ActivityLogs
                    type={SysModels.LogObjectTypeEnum.Card}
                    stringId={entity.data?.id}
                    forTab={true}
                  ></ActivityLogs>
                </div>
              )}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Card;
