import React, { useEffect, useMemo, useState } from "react";
import DocEditor from "../components/DocEditor";
import SysModels from "../models";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import Pagination, { usePaging } from "../components/Pagination";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import commonService from "../services/CommonService";
import {
  useCompanySettings,
  useLastPageFilters,
  useLoginStatus,
} from "../stores/SystemStore";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";

function CommentsTab(props: {
  cardId: string;
  access: SysModels.FullTemplateCardAccess;
  onDialogOpen: (open: boolean) => void;
  onSaved?: () => void;
}) {
  const [key, setKey] = useState(commonService.getUniqueId());
  const loginStatus = useLoginStatus();
  const companySettings = useCompanySettings();

  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const focusToEditor = (scrollIntoView = false) => {
    if (scrollIntoView) {
      document.querySelector(".tabbed-page")?.scrollIntoView();
    }
    setTimeout(() => {
      (
        document
          .getElementById("comment-editor")
          ?.querySelector(`div[contenteditable="true"]`) as HTMLElement
      )?.focus();
    }, 200);
  };

  const [editComment, setEditComment] = useState<string>();
  const [deleteComment, setDeleteComment] = useState<string>();
  const [replyTo, setReplyTo] = useState<{
    commentId: string;
    text: string;
    by: string;
  }>();

  const [viewRepliesFor, setViewRepliesFor] = useState<{
    commentId: string;
    text: string;
    by: string;
  }>();

  const [focusToComment, setFocusToComment] = useState<string>();
  const list = useFetchHelper(async () => {
    if (viewRepliesFor) {
      return SysServices.http.cardComment.listChildren(
        paging.page,
        paging.pageSize,
        {
          cardId: props.cardId,
          parentCommentId: viewRepliesFor.commentId,
        }
      );
    }
    return SysServices.http.cardComment.listParents(
      paging.page,
      paging.pageSize,
      {
        cardId: props.cardId,
      }
    );
  }, "Comments");

  //1. INITIALIZE DEFAULTS
  const pageFilters = useLastPageFilters(
    //DEFINE DEFAULTS
    {
      pageSize: 10,
      search: "",
    },
    (filters) => {
      if (filters) {
        pageChange(1, filters.pageSize);
      }
    },
    "CommentsTab"
  );

  //2. LISTEN WHENEVER THE LIST FINISH FETCHES SOMETHING, THEN SAVE THE FILTERS
  useEffect(() => {
    let tmo: any;
    if (list.status === FetchStatus.Complete) {
      tmo = setTimeout(() => {
        pageFilters.save({
          pageSize: paging.pageSize,
          search: "",
        });
      }, 500);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        pageFilters.ready && props.cardId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, viewRepliesFor, pageFilters.ready]);

  useEffect(() => {
    let tmo: any;
    if (list.data && list.status === FetchStatus.Complete && focusToComment) {
      tmo = setTimeout(() => {
        document.getElementById(focusToComment)?.scrollIntoView();
        setFocusToComment(undefined);
      }, 200);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusToComment, list.data, list.status]);

  const [model, setModel] = useState<SysModels.CardCommentCreateDto>({});
  const [saving, setSaving] = useState(false);
  const save = async () => {
    const el = document.createElement("div");
    el.innerHTML = model.text || "";
    if (
      commonService.isNullOrWhitespace(el.outerText) &&
      el.querySelectorAll("img").length === 0
    ) {
      toastStore.showToast("Comment must not be empty", "warning");
      return;
    }

    setSaving(true);
    await (editComment
      ? SysServices.http.cardComment.update(editComment, {
          ...model,
        })
      : SysServices.http.cardComment.create({
          ...model,
          cardId: props.cardId,
          parentCommentId: replyTo?.commentId || viewRepliesFor?.commentId,
        })
    )
      .then((data) => {
        props.onSaved?.();
        toastStore.showToast("Comment Saved", "success");
        setKey(commonService.getUniqueId());
        setModel((prev) => {
          return {
            ...prev,
            text: "",
          };
        });

        const isReply = !!replyTo;
        setTimeout(() => {
          editComment && document.getElementById(editComment)?.scrollIntoView();
          setEditComment(undefined);
          setReplyTo(undefined);
        }, 200);

        if (data && !isReply && !editComment) {
          SysServices.http.cardComment
            .findSearchResult(paging.pageSize, paging.pageSize, data)
            .then((pageData) => {
              list.setData(undefined);
              if (viewRepliesFor) {
                pageChange(pageData.childPage || 1, paging.pageSize);
              } else {
                pageChange(pageData.parentPage || 1, paging.pageSize);
              }
              setFocusToComment(data);
            })
            .catch((err) => {
              toastStore.showError("Failed Getting Comment Info", err);
            })
            .finally(() => {
              setSaving(false);
            });
        } else {
          list.getData();
          setSaving(false);
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Comment", err);
        setSaving(false);
      });
  };

  const doDeleteComment = async () => {
    if (deleteComment) {
      setShowOverlay(true);
      await SysServices.http.cardComment
        .delete(deleteComment)
        .then((data) => {
          props.onSaved?.();
          toastStore.showToast("Comment Deleted", "success");
          list.getData();
          setKey(commonService.getUniqueId());
        })
        .catch((err) => {
          toastStore.showError("Failed Deleting Comment", err);
        })
        .finally(() => {
          setDeleteComment(undefined);
          setShowOverlay(false);
        });
    }
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [editorDialogOpen, setEditorDialogOpen] = useState(false);
  useEffect(() => {
    props.onDialogOpen(editorDialogOpen || !!deleteComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorDialogOpen, deleteComment]);

  const submitLabel = useMemo(() => {
    if (saving) return "Saving...";
    if (editComment) return "Save Changes";
    if (replyTo || viewRepliesFor) return "Submit Reply";
    return "Submit Comment";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, editComment, replyTo, viewRepliesFor]);

  const cancel = () => {
    setEditComment(undefined);
    setReplyTo(undefined);
    if (viewRepliesFor) {
      pageChange(1, paging.pageSize);
      setViewRepliesFor(undefined);
    }
    setKey(commonService.getUniqueId());
    setModel((prev) => {
      return {
        ...prev,
        text: "",
      };
    });
  };

  const readOnly = useMemo(() => {
    if (
      props.access === SysModels.FullTemplateCardAccess.Reader ||
      props.access === SysModels.FullTemplateCardAccess.NoAccess
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.access]);

  return (
    <>
      {showOverlay && (
        <CommonSpinner
          overlay={true}
          message="Deleting Comment..."
        ></CommonSpinner>
      )}
      <ConfirmDialog
        title="Delete Comment"
        message="Do you really want to delate this comment?"
        show={!!deleteComment}
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            doDeleteComment();
          }
          setDeleteComment(undefined);
        }}
      ></ConfirmDialog>
      <div>
        <div className="mb-3 flex-1">
          <div className="document-body">
            <div id="comment-editor" className="flex flex-column">
              {editComment && <h5>Edit Comment</h5>}
              {replyTo && (
                <>
                  <h5>Reply to Comment</h5>
                  <div>From: {replyTo.by}</div>
                  <div
                    className="alert alert-sm alert-secondary p-3 reply-to-text"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <DocEditor
                      key={replyTo.commentId}
                      content={replyTo.text || ""}
                      onChange={(val) => {}}
                      onSave={() => {}}
                      onDialogOpen={(open) => {}}
                      readonly={true}
                    ></DocEditor>
                  </div>
                </>
              )}
              {viewRepliesFor && (
                <>
                  <h5 className="mb-3">
                    <i
                      className="fa fa-arrow-left me-2 text-primary pointer"
                      title="Back"
                      onClick={(e) => {
                        cancel();
                      }}
                    ></i>{" "}
                    View Replies
                  </h5>
                  <div>From: {viewRepliesFor.by}</div>
                  <div
                    className="alert alert-sm alert-secondary p-3 reply-to-text"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <DocEditor
                      key={viewRepliesFor.commentId}
                      content={viewRepliesFor.text || ""}
                      onChange={(val) => {}}
                      onSave={() => {}}
                      onDialogOpen={(open) => {}}
                      readonly={true}
                    ></DocEditor>
                  </div>
                </>
              )}
              {!readOnly && (
                <div>
                  <DocEditor
                    key={key}
                    content={model.text || ""}
                    onChange={(val) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          text: val,
                        };
                      });
                    }}
                    onSave={() => {
                      save();
                    }}
                    onDialogOpen={(open) => {
                      setEditorDialogOpen(open);
                    }}
                  ></DocEditor>
                </div>
              )}
            </div>
          </div>
          {!readOnly && (
            <div className="pt-2">
              {(editComment || replyTo || viewRepliesFor) && (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary me-2"
                  onClick={(e) => {
                    cancel();
                  }}
                >
                  Cancel
                </button>
              )}

              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={(e) => {
                  save();
                }}
                disabled={
                  saving || commonService.isNullOrWhitespace(model.text || "")
                }
              >
                {submitLabel}
              </button>
            </div>
          )}
        </div>

        <div className={`row ${replyTo ? "display-none" : ""}`}>
          {!viewRepliesFor && !readOnly && (
            <div className="px-3">
              <hr />
            </div>
          )}
          <table className="table table-bordered-x col-sm-12">
            <tbody>
              {list.status === FetchStatus.InProgress && (
                <tr>
                  <td>
                    <div className="py-4">
                      <CommonSpinner message="Loading..."></CommonSpinner>
                    </div>
                  </td>
                </tr>
              )}
              {list.status !== FetchStatus.InProgress &&
                list.data?.cardCommentGridItemOutputDtos?.map((row) => (
                  <tr key={row.commentId} id={row.commentId}>
                    {viewRepliesFor && (
                      <>
                        <td
                          style={{
                            border: "none",
                            width: "30px",
                          }}
                        ></td>
                        <td
                          style={{
                            border: "none",
                            borderLeft: "solid 2px #bbb",
                            width: "0",
                          }}
                        ></td>
                      </>
                    )}
                    <td
                      style={{
                        border: "none",
                      }}
                    >
                      <div className="pb-2 flex flex-row">
                        <div className="flex flex-row flex-1 flex-center">
                          <div className="no-wrap me-2">
                            {row.userId === loginStatus.userId ? (
                              <>
                                <small className="text-secondary">
                                  <strong>
                                    {row.firstName} {row.lastName}
                                  </strong>
                                </small>
                              </>
                            ) : (
                              <>
                                <small className="text-primary">
                                  <strong>
                                    {row.firstName} {row.lastName}
                                  </strong>
                                </small>
                              </>
                            )}
                          </div>
                          <small>
                            -{" "}
                            {commonService.toDateString(
                              row.dateCreated,
                              "full"
                            )}
                          </small>
                        </div>
                      </div>
                      <div>
                        <i
                          className="fa fa-quote-left"
                          style={{ color: "#ccc" }}
                        ></i>
                      </div>
                      <div className="px-3 pe-0">
                        <DocEditor
                          key={row.commentId}
                          content={row.text || ""}
                          onChange={(val) => {}}
                          onSave={() => {}}
                          onDialogOpen={(open) => {}}
                          readonly={true}
                        ></DocEditor>
                      </div>
                      <div className="mt-2 pb-2">
                        <div className="flex flex-row flex-center">
                          <div className="flex-1">
                            <hr />
                          </div>
                          {!readOnly && (
                            <div
                              className="no-wrap flex flex-row gap-20 flex-center"
                              style={{
                                border: "solid 1px #bbb",
                                padding: "2px 12px",
                              }}
                            >
                              {!!(
                                row.userId === loginStatus.userId &&
                                companySettings?.allowCommentEditDeletes
                              ) ? (
                                <span className="no-wrap flex flex-row gap-20 flex-center">
                                  {editComment === row.commentId ? (
                                    <>
                                      <small>Editing</small>
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        className="fa fa-trash pointer text-danger py-1"
                                        title="Delete"
                                        onClick={(e) => {
                                          setDeleteComment(row.commentId);
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-pencil pointer text-primary py-1"
                                        title="Edit"
                                        onClick={(e) => {
                                          setEditComment(row.commentId);
                                          setModel((prev) => {
                                            return {
                                              ...prev,
                                              text: row.text || "",
                                            };
                                          });
                                          setKey(commonService.getUniqueId());
                                          focusToEditor(true);
                                        }}
                                      ></i>
                                    </>
                                  )}
                                </span>
                              ) : (
                                <>
                                  {viewRepliesFor && (
                                    <span>
                                      <i className="fa fa-comment-o py-1"></i>
                                    </span>
                                  )}
                                </>
                              )}
                              {!row.parentCommentId &&
                                !editComment &&
                                !viewRepliesFor && (
                                  <span className="text-primary" title="Reply">
                                    <i
                                      className="fa fa-reply pointer"
                                      onClick={(e) => {
                                        setReplyTo({
                                          commentId: row.commentId || "",
                                          text: row.text || "",
                                          by: `${row.firstName} ${row.lastName}`,
                                        });
                                        focusToEditor();
                                      }}
                                    ></i>
                                  </span>
                                )}
                            </div>
                          )}
                          {row.hasChildren && (
                            <>
                              <div>
                                <hr style={{ width: "30px" }} />
                              </div>
                              <div>
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  type="button"
                                  onClick={(e) => {
                                    setViewRepliesFor({
                                      commentId: row.commentId || "",
                                      text: row.text || "",
                                      by: `${row.firstName} ${row.lastName}`,
                                    });
                                    pageChange(1, paging.pageSize);
                                    focusToEditor();
                                  }}
                                >
                                  View Replies{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {list.status !== FetchStatus.InProgress && (
            <div className="hide-on-print pt-2">
              <Pagination
                length={list.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel="Comments"
                sizes={[10, 15, 25, 50, 100]}
              ></Pagination>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CommentsTab;
