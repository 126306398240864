import React, { useEffect, useState } from "react";
import SysServices from "../services";
import SysModels from "../models";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import { useNavigate, useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import ConfirmDialog from "../components/ConfirmDialog";
import CommonSpinner from "../components/CommonSpinner";
import InputWithSimilaritySearch from "../components/InputWithSimilaritySearch";
import ActivityLogs from "../components/ActivityLogs";
import { useLoadedTab } from "../stores/SystemStore";
import { Tab, Tabs } from "react-bootstrap";

function Field(props: any) {
  const nav = useNavigate();
  const { id } = useParams();
  const [model, setModel] = useState<SysModels.CardFieldOutputDto>({
    fieldType: SysModels.WF1FieldType.Date,
    name: "",
    description: "",
  });

  const current = useFetchHelper(
    async () => SysServices.http.cardField.get(id || ""),
    "Field"
  );

  const types = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("WF1FieldType"),
    "Field Types"
  );

  useEffect(() => {
    types.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    if (id && Number(id)) {
      await SysServices.http.cardField
        .update(Number(id), {
          ...model,
          isFieldRequired: false, //always false for now
        })
        .then((data) => {
          toastStore.showToast("Field Saved", "success");
          current.setDataAndComplete(data);
          //nav("/fields");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Field", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await SysServices.http.cardField
        .create({
          ...model,
          isFieldRequired: false, //always false for now
        })
        .then((data) => {
          toastStore.showToast("Field Saved", "success");
          if (data.id) {
            current.setDataAndComplete(data);
            nav(`/fields/${data.id}`, { replace: true });
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Field", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (id && Number(id)) {
      current.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current.status === FetchStatus.Complete && current.data) {
      setModel(current.data);
    }
    if (current.status === FetchStatus.Failed) {
      nav("/fields");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.status]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const tabs = useLoadedTab(["Detail"]);

  return (
    <div className="tabbed-page">
      <ConfirmDialog
        show={showDel}
        title="Delete Field"
        message="Do you really want to delete this Field?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            setDeleting(true);
            SysServices.http.cardField
              .delete(Number(id))
              .then((data) => {
                toastStore.showToast("Field Deleted", "success");
                nav("/fields");
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Field", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setShowDel(false);
        }}
      ></ConfirmDialog>

      <h4>{id === "new" ? "Add" : "Edit"} Field</h4>

      <Tabs
        activeKey={tabs.activeTab}
        className="maintenance-tabs mt-2"
        onSelect={(e) => {
          if (e) {
            tabs.setActiveTab(e);
          }
        }}
      >
        <Tab eventKey="Detail" title="Detail">
          {current.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}

          {(current.status === FetchStatus.Complete || id === "new") && (
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div>
                  <div className="pt-2">
                    <div className="mb-2">
                      <div className="mb-1">
                        <label>Field Type</label>
                      </div>
                      <select
                        className="form-control"
                        value={model.fieldType}
                        disabled={!!model.id && !model.canBeUpdateOrDeleted}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              fieldType: commonService.isNullOrEmpty(
                                e.target.value
                              )
                                ? 0
                                : Number(e.target.value),
                            };
                          });
                        }}
                      >
                        {types.status === FetchStatus.InProgress && (
                          <option>Loading...</option>
                        )}
                        {types.data?.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label className="required-label">Name</label>
                      </div>
                      <InputWithSimilaritySearch
                        placeholder="Field Name"
                        autoFocus={true}
                        currentId={model.id}
                        value={model.name || ""}
                        onChange={(val) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              name: val,
                            };
                          });
                        }}
                        request={() =>
                          SysServices.http.cardField.typeAhead({
                            search: model.name || "",
                          })
                        }
                      ></InputWithSimilaritySearch>
                    </div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <label>Description</label>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        value={model.description}
                        rows={3}
                        onChange={(e) => {
                          setModel((prev) => {
                            return {
                              ...prev,
                              description: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="pt-2">
                    <button
                      className="btn btn-sm btn-primary float-right"
                      type="button"
                      onClick={(e) => {
                        save();
                      }}
                      disabled={saving || deleting}
                    >
                      {saving ? "Saving..." : "Submit"}
                    </button>
                    <button
                      className="btn btn-sm btn-secondary me-2 float-right"
                      type="button"
                      onClick={(e) => {
                        nav("/fields");
                      }}
                      disabled={saving || deleting}
                    >
                      Cancel
                    </button>
                    {model.id && (
                      <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={(e) => {
                          setShowDel(true);
                        }}
                        disabled={
                          !model.canBeUpdateOrDeleted || saving || deleting
                        }
                      >
                        {deleting ? "Deleting..." : "Delete"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Tab>
        {id !== "new" && (
          <Tab eventKey="Logs" title="Logs">
            {tabs.loadedTabs.includes("Logs") && (
              <div className="pt-2 col-sm-12">
                {model.id && (
                  <div>
                    <ActivityLogs
                      type={SysModels.LogObjectTypeEnum.CardField}
                      numberId={model.id}
                      forTab={true}
                    ></ActivityLogs>
                  </div>
                )}
              </div>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default Field;
