import React, { useEffect, useMemo, useState } from "react";
import {
  useActiveRole,
  useCompanyLogoUpdated,
  useCompanySettings,
  useLastPageFilters,
  useLoginStatus,
} from "../stores/SystemStore";
import DashboardIcons from "../components/DashboardIcons";
import DashboardHowTo from "../components/DashboardHowTo";
import menuActions from "../layout/MenuActions";
import CommonSpinner from "../components/CommonSpinner";
import CardChartsWidget from "../widgets/CardChartsWidget";
import RecentlyUpdatedCardsWidget from "../widgets/RecentlyUpdatedCardsWidget";
import FileSystemWidget from "../widgets/FileSystemWidget";

function Dashboard(props: any) {
  const loginStatus = useLoginStatus();
  const activeRole = useActiveRole();
  const ma = menuActions;
  const [noHowTo, setNoHowTo] = useState(false);
  const pageFilters = useLastPageFilters({
    pageSize: 0,
    search: "",
    others: {
      hideHowTo: false,
    },
  });
  useEffect(() => {
    setNoHowTo(false);
    pageFilters.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole]);

  const companyLogoUpdated = useCompanyLogoUpdated();
  const companySettings = useCompanySettings();
  const dashboardName = useMemo(() => {
    if (activeRole) {
      return (
        ma.actions
          .find((a) => a.dashboard && a.roles.includes(activeRole))
          ?.otherLabel?.() ||
        ma.actions.find((a) => a.dashboard && a.roles.includes(activeRole))
          ?.label
      );
    }
    return "Dashboard";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole, companyLogoUpdated, loginStatus.roles]);

  return (
    <div>
      <h4 key={`${activeRole}-${companyLogoUpdated}`}>{dashboardName}</h4>
      <div className="row pt-4">
        {pageFilters.ready ? (
          <>
            <div className="col-sm-12 col-md-6">
              <DashboardIcons
                showGettingStarted={pageFilters.filters?.others?.hideHowTo}
                onShowGettingStarted={() => {
                  pageFilters.save({
                    pageSize: 0,
                    search: "",
                    others: {
                      hideHowTo: false,
                    },
                  });
                }}
              />
              {companySettings?.allowFullTemplates && (
                <CardChartsWidget></CardChartsWidget>
              )}
            </div>
            <div className="col-sm-12 col-md-6">
              {!noHowTo && !pageFilters.filters?.others?.hideHowTo && (
                <DashboardHowTo
                  onHide={(noHowTo) => {
                    setNoHowTo(noHowTo || false);
                    if (!noHowTo) {
                      pageFilters.save({
                        pageSize: 0,
                        search: "",
                        others: {
                          hideHowTo: true,
                        },
                      });
                    }
                  }}
                />
              )}
              {companySettings?.allowFullTemplates && (
                <RecentlyUpdatedCardsWidget></RecentlyUpdatedCardsWidget>
              )}
              {companySettings?.allowFileSystem && (
                <FileSystemWidget></FileSystemWidget>
              )}
            </div>
          </>
        ) : (
          <CommonSpinner></CommonSpinner>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
