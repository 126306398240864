import React, { useEffect, useState } from "react";
import FormModal from "../components/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { useCompanySettings, useErrorDialog } from "../stores/SystemStore";
import CommonSpinner from "../components/CommonSpinner";
import Pagination, { usePaging } from "../components/Pagination";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";

function OtherCardDialog(props: {
  cardId: string;
  id?: string;
  close: (saved?: boolean) => void;
}) {
  const companySettings = useCompanySettings();
  const errDialog = useErrorDialog();

  const [paging, setPaging] = usePaging(1, 15);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const list = useFetchHelper(
    async () =>
      SysServices.http.fullTemplateCard.list(paging.page, paging.pageSize, {
        search: search.used,
        archiveStatus: SysModels.ArchiveStatusEnum.All,
      }),
    companySettings?.entityLabelPlural || "Cards"
  );

  useEffect(() => {
    const tmo = setTimeout(
      () => {
        props.cardId && list.getData();
      },
      list.status === FetchStatus.Default ? 0 : 200
    );

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  useEffect(() => {
    commonService.focusInput("searchOtherCards", 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saved, setSaved] = useState(false);

  return (
    <FormModal
      title={`Associate ${companySettings?.entityLabelSingular}`}
      size="xl"
      isOpen={true}
      close={() => {
        props.close(saved);
      }}
      submit={() => {
        props.close(saved);
      }}
      submitButtonLabel="Done"
      moveBehind={errDialog.show}
      primaryButtonOnly={true}
    >
      <div>
        <div className="flex flex-wrap gap-10 mb-3">
          <div className="flex-0" style={{ maxWidth: "100%" }}>
            <div className="input-group search-box">
              <input
                autoFocus={true}
                id="searchOtherCards"
                className="form-control"
                type="text"
                placeholder="Search..."
                value={search.typed}
                onChange={(e) => {
                  setSearch((data) => {
                    return {
                      ...data,
                      typed: e.target.value,
                    };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearch((data) => {
                      if (data.used === data.typed) {
                        list.getData();
                        return data;
                      }
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                    pageChange(1, paging.pageSize);
                  }
                }}
              ></input>
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      if (data.used === data.typed) {
                        list.getData();
                        return data;
                      }
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                    pageChange(1, paging.pageSize);
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      return { typed: "", used: "" };
                    });
                    pageChange(1, paging.pageSize);
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="flex-1"></div>
        </div>

        <div className="p-0" style={{ position: "relative" }}>
          {list.status === FetchStatus.InProgress && (
            <CommonSpinner message="Loading..."></CommonSpinner>
          )}
          {list.status !== FetchStatus.InProgress && (
            <>
              <div
                className="row mb-2 alert alert-light"
                style={{
                  padding: "12px 0px",
                  paddingBottom: "0",
                  margin: "0",
                  borderRadius: "5px",
                }}
              >
                {!list?.data?.fullTemplateCardGridItemOutputDtos?.length && (
                  <div className="pb-2 m-2">No Record(s) Found</div>
                )}
                {list?.data?.fullTemplateCardGridItemOutputDtos?.map((card) => (
                  <div key={card.id} className="col-sm-12 col-md-4 pb-3">
                    <div className="card h-100">
                      <div className="card-body flex flex-column">
                        <label className="mb-2">{card.cardTemplateName}</label>
                        <div className="flex-1">
                          <table className="table table-sm table-bordered mb-0">
                            <tbody>
                              {card.fields
                                ?.sort(
                                  commonService.sortByNumericProperty(
                                    "sequence"
                                  )
                                )
                                ?.map((fd) => (
                                  <tr key={fd.sequence}>
                                    <td>
                                      <label>{fd.name}</label>
                                    </td>
                                    <td style={{ wordBreak: "break-all" }}>
                                      {commonService.isNullOrEmpty(
                                        fd.value || ""
                                      ) ? (
                                        <>
                                          <small className="chip-gray">
                                            No Data
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          {fd.fieldType ===
                                          SysModels.WF1FieldType.Date ? (
                                            <>
                                              {fd.value
                                                ? commonService.toDateString(
                                                    fd.value,
                                                    "ddd, MMM DD, YYYY"
                                                  )
                                                : "-"}
                                            </>
                                          ) : (
                                            <>{fd.value}</>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="pt-2 flex flex-row flex-center">
                          <div className="flex-1 text-secondary">
                            Participants: {card.numberOfParticipants}
                          </div>
                          <div>
                            <i
                              className="fa fa-plus pointer text-success"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                SysServices.http.associatedCard
                                  .create({
                                    cardId: props.cardId,
                                    associatedCardId: card.id,
                                  })
                                  .then((data) => {
                                    toastStore.showToast(
                                      `${companySettings?.entityLabelSingular} Associated`,
                                      "success"
                                    );
                                    setSaved(true);
                                  })
                                  .catch((err) => {
                                    toastStore.showError(
                                      `Failed Associating ${companySettings?.entityLabelSingular}`,
                                      err
                                    );
                                  });
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="hide-on-print">
                <Pagination
                  length={list.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel={
                    companySettings?.entityLabelPlural || "Cards"
                  }
                  sizes={[10, 15, 25, 50, 100]}
                ></Pagination>
              </div>
            </>
          )}
        </div>
      </div>
    </FormModal>
  );
}

export default OtherCardDialog;
